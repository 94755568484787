import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import RenderField from '../types/RenderField';

class FilterSidebar extends Component {
  
  constructor(props) {
    super(props);
    this.state = {};
    this.applyFilters = this.applyFilters.bind(this);
  }
  
  applyFilters() {
    this.props.applyFilters(Object.assign({}, this.state));
    this.props.openSidebar();
  }
  
  render() {
    let that = this;
    const filterTypes = this.props.filters;
    return (
      <Row className="no-gutters">
        <a onClick={ this.props.openSidebar } className="filter-close" ><i className="fa fa-times icon-close-sidebar"/></a>
        <Col>
          <h4 className="text-center my-3">Filters</h4>
          <Row className="justify-content-center">
          { filterTypes && filterTypes.map(function(item) {
              return <Col md={6} key={item.id}><Button block className="mx-2 btn-filters-new pointer" outline={ that.state.filterType !== item.id} color="info" onClick={ () => that.setState({filterType: item.id }) } key={item.id}>{item.title}</Button></Col>
              })
            }
          </Row>
          { filterTypes && filterTypes.map(function(item) {
            return <React.Fragment key={item.id}>
                    { that.state.filterType === item.id && <Row>
                      { item.filters.map(function(field, key) {
                        return <Col md={12} key={key}>
                                <RenderField data={field} value={that.state} onChange={ (value) => that.setState({ [field.name] : value }) } />
                               </Col>
                        })
                      }
                      <Col xs={12}>
                        <Button block color="info" onClick={ that.applyFilters} >Apply Filters</Button>
                      </Col>
                    </Row>}
                   </React.Fragment>
          
          })}
        </Col>
      </Row>
      
    )
  }

}

export default FilterSidebar;
