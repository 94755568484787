import React, { Component } from 'react';
import TableService from '../services/TableService';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFixedColumns,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';

const columns = [
    { name: 'sector',  columnName: 'sector', title: 'Sector', width: 200 },
    { name: 'gla', columnName: 'gla', title: 'GLA', width: 100 },
    { name: 'tenant', columnName: 'tenant', title: 'Tenant', width: 200 },
    { name: 'salesDay', columnName: 'salesDay', title: 'Sales Day', width: 100 },
    { name: 'salesComparableDay', columnName: 'salesComparableDay', title: 'Sales Comparable Day', width: 100 },
    { name: 'deltaSD', columnName: 'deltaSD', title: 'Δ', width: 100 },
    { name: 'salesM2', columnName: 'salesM2', title: 'Sales/M2', width: 100 },
    { name: 'salesM2ComparableDay', columnName: 'salesM2ComparableDay', title: 'Sales/M2 Comparable Day', width: 100 },
    { name: 'inflowM2', columnName: 'inflowM2', title: 'Inflow/M2', width: 100 },
    { name: 'inflowM2ComparableDay', columnName: 'inflowM2ComparableDay', title: 'Inflow/M2 Comparable Day', width: 100 },
    { name: 'deltaIM2', columnName: 'deltaIM2', title: 'Δ', width: 100 },
    { name: 'conversionRate', columnName: 'conversionRate', title: 'Conversion Rate', width: 100 },
    { name: 'conversionRateComparableDay', columnName: 'conversionRateComparableDay', title: 'Inflow/M2 Comparable Day', width: 100 },
    { name: 'deltaCR', columnName: 'deltaCR', title: 'Δ', width: 100 },
    { name: 'averageTicket', columnName: 'averageTicket', title: 'Average Ticket', width: 100 },
    { name: 'averageTicketComparableDay', columnName: 'averageTicketComparableDay', title: 'Average Ticket Comparable Day', width: 100 },
    { name: 'deltaAT', columnName: 'deltaAT', title: 'Δ', width: 100 },
    { name: 'averagePrice', columnName: 'averagePrice', title: 'Average Price', width: 100 },
    { name: 'averagePriceComparableDay', columnName: 'averagePriceComparableDay', title: 'Average Price Comparable Day', width: 100 },
    { name: 'deltaAP', columnName: 'deltaAP', title: 'Δ', width: 100 },
    { name: 'upt', columnName: 'upt', title: 'UPT', width: 100 },
    { name: 'uptComparable', columnName: 'uptComparable', title: 'UPT Comparable Day', width: 100 },
    { name: 'deltaUPT', columnName: 'deltaUPT', title: 'Δ', width: 100 },
  ];

const dummyData = { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Tables\\CenterInflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Category",
                    "Current",
                    "Compare",
                  ],
                "orderBy": "Date"
              }
            };

class CenterGeneralPerformanceTable extends Component {

  constructor(props) {
    super(props);
    this.TableService = new TableService();
    this.state = { data: [] };
    this.toggle = this.toggle.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  
  toggle(tab) {
    this.setState({activeTab:tab});
  }
  
  componentDidUpdate(prevProps) {
    for(let k in this.props) {
      if(prevProps[k] !== this.props[k]) {
        this.loadData();
      }
    }
  }
  
  prepareData() {
    let data = this.props.filters; //{ startDate: "2019-01-10", endDate: "2019-01-17" };
    let toSend = Object.assign({}, dummyData.fetchPrototype);
    for(let k in toSend.filters) {
      if(data.hasOwnProperty(toSend.filters[k].filterName))
        toSend.filters[k].value = data[toSend.filters[k].filterName];
    }
    return toSend;
  }
  
  loadData() {
    this.setState({ loaded: false });
    let toSend = this.prepareData();
    this.TableService.getCenterPerformanceTable(toSend).then((res) => {
      if(!res.data || res.data.length === 0) {
        this.setState({ data: [], loaded: true });
      } else {
        this.setState({ data: res.data, loaded: true });
      }
    })
    .catch((err) => {
      this.setState({ data: [], loaded: true });
    });
  }

  componentDidMount() {
    this.loadData();
  }
  
  render() {
    return (
      <>
        <Grid
          rows={this.state.data}
          columns={columns}
        >
          <Table />
          <TableColumnResizing defaultColumnWidths={columns}/>
          <TableHeaderRow />
          <TableFixedColumns
            leftColumns={['sector', 'gla', 'tenant']}
          />
        </Grid>
      </>
    )
  }

}

export default CenterGeneralPerformanceTable;
