import React, { Component } from 'react';
import WeekField from './WeekField'
import MonthField from './MonthField'
import ShopField from './ShopField';
import MonthCumField from './MonthCumField';
import WeekCumField from './WeekCumField'
import DailyField from './DailyField';

class RenderField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
  }

  componentDidMount() {

  }

  render() {
    let i = 0;
    if(this.props.data.type === "date")
      return <DailyField {...this.props} />
    else if(this.props.data.type === "week")
      return <WeekField {...this.props}/>
    else if(this.props.data.type === "month")
      return <MonthField {...this.props}/>
    else if(this.props.data.type === "weekcum")
      return <WeekCumField {...this.props}/>
    else if(this.props.data.type === "monthcum")
      return <MonthCumField {...this.props}/>
    else if(this.props.data.type === "shop")
      return <ShopField {...this.props}/>
    else
      return <React.Fragment key={ i } />;

    
  }
}

export default RenderField;
