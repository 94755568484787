import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select'
import GraphService from '../services/GraphService';

class ShopField extends Component {

  constructor(props) {
    super(props);
    this.service = new GraphService();
    this.state = {
      options: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    console.log(value);
    this.props.onChange(value);
  }

  componentDidMount() {
    this.service.fetchData({ class: "Retail", filters: [] }).then((res) => {
      let array = [];
      for(let i in res.data) {
        array.push({value: res.data[i].id, label: res.data[i].Name});
      }
      this.setState({options: array});
    })
    .catch((err) => {
      this.setState({ options: [] });
    });
  }

  render() {
    
    return <>
            <FormGroup>
              <Label>{ this.props.data.title }</Label><br/>
              <Select options={this.state.options} onChange={ (value) => this.handleChange(value)  } />
            </FormGroup>
          </>
  }
}

export default ShopField;
