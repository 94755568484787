import React, { Component } from 'react';
import { Col, Nav, NavLink, Button } from 'reactstrap';
import logo from '../images/logo_white_2x.png';
import logoMobile from '../images/logo_brown_horizontal.png';
import {Link} from 'react-router-dom';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = { navOpen: true };
    this.openNav = this.openNav.bind(this);
  }
  
  openNav() {
    this.setState({ navOpen: !this.state.navOpen });
  }

  render() {
    let that = this;
    return (
      <Col md={1} className="main-sidebar px-0 py-0">
        <img src="https://intranet.sanmarinooutlet.com/app-logo.26033842de7deb959a95.svg" alt="Logo The Market" className="img-responsive my-3 mb-5 d-none d-md-block" />
        <img src={logoMobile} alt="Logo The Market" className="py-3 pl-0 pr-5 logo-mobile img-responsive d-md-none" />
        <Button className="pull-right d-md-none mt-3 mr-3" onClick={this.openNav} outline color="info"><i className="fa fa-bars" /></Button>

        {/* this.state.navOpen && <Nav vertical>
          { this.props.data && this.props.data.map((item, key) => (
            <NavLink tag={Link} key={key} to={"/" + item.slug} className={ that.props.location.pathname.includes(item.slug) ? "active" : "" }><span title={item.title} className="material-icons">{item.icon}</span><label>{item.title}</label></NavLink>
            )) 
          }
        </Nav> */}
        <div className="mt-5 pt-5">
          <UncontrolledDropdown >
            <Link to={"/wifi/tables"}>
              <DropdownToggle tag="span" className={ that.props.location.pathname.includes('tables') ? "active nav-link" : "nav-link" }
            data-toggle="dropdown">
                <span title={"Tables"} className="material-icons">table_view</span><label>Tables</label>
              </DropdownToggle>
            </Link>
          </UncontrolledDropdown>
          <UncontrolledDropdown >
            <Link to={"/wifi/charts"}>
              <DropdownToggle tag="span" className={ that.props.location.pathname.includes('charts') ? "active nav-link" : "nav-link" }
            data-toggle="dropdown">
                <span title={"Charts"} className="material-icons">bar_chart</span><label>Charts</label>
              </DropdownToggle>
            </Link>
          </UncontrolledDropdown>
          <UncontrolledDropdown >
            <Link to={"/wifi/heatmap"}>
              <DropdownToggle tag="span" className={ that.props.location.pathname.includes('heatmap') ? "active nav-link" : "nav-link" }
            data-toggle="dropdown">
                <span title={"Heatmap"} className="material-icons">map</span><label>Heatmap</label>
              </DropdownToggle>
            </Link>
          </UncontrolledDropdown>
          <UncontrolledDropdown >
            <Link to={"/wifi/export"}>
              <DropdownToggle tag="span" className={ that.props.location.pathname.includes('export') ? "active nav-link" : "nav-link" }
            data-toggle="dropdown">
                <span title={"Export"} className="material-icons">file_download</span><label>Export</label>
              </DropdownToggle>
            </Link>
          </UncontrolledDropdown>
        </div>
      </Col>
    )
  }

}

export default Sidebar;
