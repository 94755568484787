
export const menu = {
  "sections" : [
    {
      "title": "General Performance",
      "slug": "general-performance",
      "icon": "receipt",
      "subsections": [
        {
          "title": "Center",
          "slug": "center",
          "generalPerformanceTable": true,
          "filters": [
            { id: 1, title: "Daily", filters: [
                {
                  "name": "current",
                  "type": "date",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "date",
                  "title": "Compare to"
                }
              ]
            },
            { id: 2, title: "Weekly", filters: [
                {
                  "name": "current",
                  "type": "week",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "week",
                  "title": "Compare to"
                }
              ]
            },
            { id: 3, title: "Monthly", filters: [
                {
                  "name": "current",
                  "type": "month",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "month",
                  "title": "Compare to"
                }
              ]
            },
            { id: 4, title: "Weekly Cum.", filters: [
                {
                  "name": "current",
                  "type": "weekcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "weekcum",
                  "title": "Compare To"
                }
              ]
            },
            { id: 5, title: "Monthly Cum.", filters: [
                {
                  "name": "current",
                  "type": "monthcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "monthcum",
                  "title": "Compare to"
                }
              ]
            },
          ],
          "tables" : [
            { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Tables\\CenterInflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Category",
                    "Current",
                    "Compare",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Tables\\TotalPerformance",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "TOTAL",
                    "Current",
                    "Compare",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Tables\\NoFoodPerformance",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "NOFOOD",
                    "Current",
                    "Compare",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Tables\\FoodPerformance",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "FOOD",
                    "Current",
                    "Compare",
                  ],
                "orderBy": "Date"
              }
            },
          ],
          "rankings": [
            { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Tables\\ShopRankingSales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Shop",
                    "Sales",
                    "Sales MQ",
                    "Delta",
                    "Delta MQ",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Tables\\ShopRanking",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Shop",
                    "Inflow",
                    "Inflow MQ",
                    "Delta",
                    "Delta MQ",
                  ],
                "orderBy": "Date"
              }
            },
          ],
          "graphs" : [
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "height": 500,
              "xAxisSlanted": true,
              "legend": "bottom",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Daily\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "filterType"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Daily\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Daily\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Daily\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Weekly\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "filterType"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "vAxisFormat": "€ ",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Weekly\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Weekly\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "ColumnChart",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Weekly\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "AreaChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\WeeklyCum\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "filterType"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\WeeklyCum\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\WeeklyCum\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "AreaChart",
              "height": 500,
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\WeeklyCum\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "xAxisSlanted": true,
              "xAxisFormat": "d",
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Monthly\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "filterType"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "vAxisFormat": "€ ",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "xAxisFormat": "d",
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Monthly\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "legend": "bottom",
              "height": 500,
              "filterType": [ 3 ],
              "xAxisFormat": "d",
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Monthly\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "xAxisFormat": "d", 
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\Monthly\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "AreaChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\MonthlyCum\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "filterType"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\MonthlyCum\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\MonthlyCum\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "AreaChart",
              "height": 500,
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Center\\MonthlyCum\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
          ]
        },
        {
          "title": "Shop",
          "slug": "shop",
          "filters": [
            { id: 1, title: "Daily", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "date",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "date",
                  "title": "Compare to"
                }
              ]
            },
            { id: 2, title: "Weekly", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "week",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "week",
                  "title": "Compare to"
                }
              ]
            },
            { id: 3, title: "Monthly", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "month",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "month",
                  "title": "Compare to"
                }
              ]
            },
            { id: 4, title: "Weekly Cum.", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "weekcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "weekcum",
                  "title": "Compare To"
                }
              ]
            },
            { id: 5, title: "Monthly Cum.", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "monthcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "monthcum",
                  "title": "Compare to"
                }
              ]
            },
          ],
          "tables" : [
            { 
              "filterType": [ 1,2,3,4,5 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Tables\\TotalPerformance",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "fields": [
                    "SHOP",
                    "Current",
                    "Compare",
                    "Delta",
                    "Section",
                    "Delta Section",
                  ],
                "orderBy": "Date"
              }
            },
          ],
          "graphs" : [
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "height": 500,
              "xAxisSlanted": true,
              "legend": "bottom",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Daily\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Daily\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Daily\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Daily\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Weekly\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "vAxisFormat": "€ ",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Weekly\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Weekly\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "ColumnChart",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Weekly\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "AreaChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\WeeklyCum\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\WeeklyCum\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\WeeklyCum\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "AreaChart",
              "height": 500,
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\WeeklyCum\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "xAxisSlanted": true,
              "xAxisFormat": "d",
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Monthly\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "vAxisFormat": "€ ",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "xAxisFormat": "d",
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Monthly\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "legend": "bottom",
              "height": 500,
              "filterType": [ 3 ],
              "xAxisFormat": "d",
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Monthly\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "xAxisFormat": "d", 
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\Monthly\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow",
              "type" : "AreaChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\MonthlyCum\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "TrafficDate",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Sales",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\MonthlyCum\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "AreaChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\MonthlyCum\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Conversion Rate",
              "type" : "AreaChart",
              "height": 500,
              "filterType": [ 5 ],
              "xAxisFormat": "d",
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "GeneralPerformance\\Shop\\MonthlyCum\\ConversionRate",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "AVG",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "TrafficDate"
              }
            },
          ]
        }
      ]
    },
    {
      "title": "Inflow",
      "slug": "inflow",
      "icon": "people",
      "subsections": [
        {
          "title": "Center",
          "slug": "center",
          "hasHeatmap": true,
          "filters": [
            { id: 0, title: "Realtime", filters: [
            
              ],
            },
            { id: 1, title: "Daily", filters: [
                {
                  "name": "current",
                  "type": "date",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "date",
                  "title": "Compare to"
                }
              ]
            },
            { id: 2, title: "Weekly", filters: [
                {
                  "name": "current",
                  "type": "week",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "week",
                  "title": "Compare to"
                }
              ]
            },
            { id: 3, title: "Monthly", filters: [
                {
                  "name": "current",
                  "type": "month",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "month",
                  "title": "Compare to"
                }
              ]
            },
            { id: 4, title: "Weekly Cum.", filters: [
                {
                  "name": "current",
                  "type": "weekcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "weekcum",
                  "title": "Compare To"
                }
              ]
            },
            { id: 5, title: "Monthly Cum.", filters: [
                {
                  "name": "current",
                  "type": "monthcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "monthcum",
                  "title": "Compare to"
                }
              ]
            },
          ],
          "tables": [
            { 
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Tables\\CenterInflowRealtime",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Data",
                    "Current",
                    "Compare",
                    "Delta",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "filterType": [ 1, 2, 3, 4, 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Tables\\CenterInflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Data",
                    "Current",
                    "Compare",
                    "Delta",
                  ],
                "orderBy": "Date"
              }
            },
          ],
          "rankings": [
            { 
              "filterType": [ 0, 1, 2, 3, 4, 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Tables\\ShopRankingInflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Position",
                    "Inflow",
                    "Inflow Mq",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "filterType": [ 0, 1, 2, 3, 4, 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Tables\\ShopRankingInflowPresent",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "fields": [
                    "Position",
                    "Present",
                    "Present Mq",
                  ],
                "orderBy": "Date"
              }
            },
          ],
          "graphs": [
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 0 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\RealTime\\InflowIn",
                "filters": [
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "title": "Inflow Out",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 0 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\RealTime\\InflowOut",
                "filters": [
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "Date"
              }
            },
            { 
              "title": "Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\RealTime\\InflowPresent",
                "filters": [
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Entrance Split",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\RealTime\\EntranceSplit",
                "filters": [
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Data",
                    "A/B",
                    "C",
                    "Plaza",
                    "B",
                    "D"
                  ],
                "orderBy": "TrafficDate"
              }
            },
           
           { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "height": 500,
              "xAxisSlanted": true,
              "legend": "bottom",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Daily\\InflowIn",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Daily\\InflowPresent",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Entrance Split",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "xAxisSlanted": true,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Daily\\EntranceSplit",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Data",
                    "A/B",
                    "C",
                    "Plaza",
                    "B",
                    "D"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Weekly\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Weekly\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow Present Mon-Fri",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Weekly\\InflowPresentWorking",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow Present Sat-Sun",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Weekly\\InflowPresentWeekend",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Entrance Mon - Fri",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Weekly\\EntranceSplitWorking",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "A/B",
                    "B",
                    "C",
                    "Plaza",
                    "D"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Entrance Sat - Sun",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Weekly\\EntranceSplitWeekend",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "A/B",
                    "B",
                    "C",
                    "Plaza",
                    "D"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In",
              "type" : "AreaChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\WeeklyCum\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\WeeklyCum\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow Present Mon-Fri",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 4 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\WeeklyCum\\InflowPresentWorking",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow Present Sat-Sun",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 4 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\WeeklyCum\\InflowPresentWeekend",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Entrance Mon - Fri",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 4 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\WeeklyCum\\EntranceSplitWorking",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "A/B",
                    "B",
                    "C",
                    "Plaza",
                    "D"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Entrance Sat - Sun",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 4 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\WeeklyCum\\EntranceSplitWeekend",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "A/B",
                    "B",
                    "C",
                    "Plaza",
                    "D"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Monthly\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 3 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\Monthly\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In",
              "type" : "LineChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\Monthly\\InflowInCumulate",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
           
           { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\MonthlyCum\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 5 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Center\\MonthlyCum\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In",
              "type" : "LineChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Center\\MonthlyCum\\InflowInCumulate",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
           
          ],
        },
        {
          "title": "Single Shop",
          "slug": "single-shop",
          "filters": [
            { id: 0, title: "Real Time", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                }
              ]
            },
            { id: 1, title: "Daily", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "date",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "date",
                  "title": "Compare to"
                }
              ]
            },
            { id: 2, title: "Weekly", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "week",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "week",
                  "title": "Compare to"
                }
              ]
            },
            { id: 3, title: "Monthly", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "month",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "month",
                  "title": "Compare to"
                }
              ]
            },
            { id: 4, title: "Weekly Cum.", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "weekcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "weekcum",
                  "title": "Compare To"
                }
              ]
            },
            { id: 5, title: "Monthly Cum.", filters: [
                {
                  "name": "shop",
                  "type": "shop",
                  "title": "Select a shop"
                },
                {
                  "name": "current",
                  "type": "monthcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "monthcum",
                  "title": "Compare to"
                }
              ]
            },
          ],
          "tables": [
            { 
              "filterType": [ 1, 2, 3, 4, 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Tables\\ShopInflowTable",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "fields": [
                    "Data",
                    "CURRENT",
                    "PAST",
                    "DELTA",
                  ],
                "orderBy": "Date"
              }
            },
            
          ],
          "graphs": [
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\RealTime\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\RealTime\\InflowM2",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\RealTime\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Daily\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Daily\\InflowM2",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Daily\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Weekly\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Weekly\\InflowM2",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Weekly\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Weekly\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\WeeklyCum\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\WeeklyCum\\InflowM2",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\WeeklyCum\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\WeeklyCum\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Monthly\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Monthly\\InflowM2",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Monthly\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\Monthly\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\MonthlyCum\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\MonthlyCum\\InflowM2",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\MonthlyCum\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shop\\MonthlyCum\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
          ],
        },
        
        
        {
          "title": "Shops",
          "slug": "shops",
          "filters": [
            { id: 0, title: "Real Time", filters: [
              ]
            },
            { id: 1, title: "Daily", filters: [
                {
                  "name": "current",
                  "type": "date",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "date",
                  "title": "Compare to"
                }
              ]
            },
            { id: 2, title: "Weekly", filters: [
                {
                  "name": "current",
                  "type": "week",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "week",
                  "title": "Compare to"
                }
              ]
            },
            { id: 3, title: "Monthly", filters: [
                {
                  "name": "current",
                  "type": "month",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "month",
                  "title": "Compare to"
                }
              ]
            },
            { id: 4, title: "Weekly Cum.", filters: [
                {
                  "name": "current",
                  "type": "weekcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "weekcum",
                  "title": "Compare To"
                }
              ]
            },
            { id: 5, title: "Monthly Cum.", filters: [
                {
                  "name": "current",
                  "type": "monthcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "monthcum",
                  "title": "Compare to"
                }
              ]
            },
          ],
          "tables": [
            { 
              "filterType": [ 1, 2, 3, 4, 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Tables\\ShopsInflowTable",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    {
	                    "name": "Shop",
	                    "operator": "=",
	                    "filterName": "shop"
                    },
                  ],
                "fields": [
                    "Data",
                    "CURRENT",
                    "PAST",
                    "DELTA",
                  ],
                "orderBy": "Date"
              }
            },
            
          ],
          "graphs": [
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\RealTime\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\RealTime\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow",
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow/Mq per Sector",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 0 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Shops\\RealTime\\InflowMqPerSector",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Man fashion",
                    "Woman fashion",
                    "Accessories",
                    "Mixed fashion",
                    "Food",
                    "Cosmetics",
                    "Sportswear",
                    "Kids Fashion",
                    "Footwear",
                    "Home",
                    "Jewelry",
                    "Eyewear",
                    "Denim",
                    "Undewear"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Food vs Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 0 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\RealTime\\CaptureFoodInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Inflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
          
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Daily\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Daily\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow/Mq per Sector",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 1 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Daily\\InflowMqPerSector",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Man fashion",
                    "Woman fashion",
                    "Accessories",
                    "Mixed fashion",
                    "Food",
                    "Cosmetics",
                    "Sportwear"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Food vs Inflow Present",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Daily\\CaptureFoodInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Weekly\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Weekly\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow/Mq per Sector",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 2 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Weekly\\InflowMqPerSector",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Man fashion",
                    "Woman fashion",
                    "Accessories",
                    "Mixed fashion",
                    "Food",
                    "Cosmetics",
                    "Sportwear"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Food vs Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Weekly\\CaptureFoodInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Weekly\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\WeeklyCum\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\WeeklyCum\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow/Mq per Sector",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 4 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Shops\\WeeklyCum\\InflowMqPerSector",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Man fashion",
                    "Woman fashion",
                    "Accessories",
                    "Mixed fashion",
                    "Food",
                    "Cosmetics",
                    "Sportwear"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Food vs Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\WeeklyCum\\CaptureFoodInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\WeeklyCum\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Monthly\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Monthly\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow/Mq per Sector",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 3 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Monthly\\InflowMqPerSector",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Man fashion",
                    "Woman fashion",
                    "Accessories",
                    "Mixed fashion",
                    "Food",
                    "Cosmetics",
                    "Sportwear"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\Monthly\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            
            { 
              "title": "Inflow In / Mq",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\MonthlyCum\\InflowIn",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Average Time Visit",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\MonthlyCum\\AverageTimeVisit",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "Current",
                    "Past"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Inflow/Mq per Sector",
              "type" : "PieChart",
              "legend": "bottom",
              "height": 500,
              "filterType": [ 5 ],
              "xAxisSlanted": true,
              "fetchPrototype": {
                "class": "Inflow\\Shops\\MonthlyCum\\InflowMqPerSector",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "FilterType",
	                    "operator": "=",  
	                    "filterName": "compareTo"
                    }
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Man fashion",
                    "Woman fashion",
                    "Accessories",
                    "Mixed fashion",
                    "Food",
                    "Cosmetics",
                    "Sportwear"
                  ],
                "orderBy": "TrafficDate"
              }
            },
            { 
              "title": "Capture Rate Inflow Present",
              "type" : "ColumnChart",
              "xAxisSlanted": true,
              "height": 500,
              "legend": "bottom",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Inflow\\Shops\\MonthlyCum\\CaptureRateInflowPresent",
                "filters": [
                    {
	                    "name": "StartDate",
	                    "operator": "=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "EndDate",
	                    "operator": "=",
	                    "filterName": "compareTo"
                    },
                    
                  ],
                "groupBy" : [
                    "TrafficDate"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "Date",
                    "CurrentInflow",
                    "PastInflow"
                  ],
                "orderBy": "TrafficDate"
              }
            },
          ],
        },
        
        
      ],
    },
    {
      "title": "Loyalties",
      "slug": "loyalties",
      "icon": "loyalty",
      "subsections": [
        {
          "title": "Smac Card",
          "slug": "smac-card",
          "filters": [
            
            { id: 1, title: "Daily", filters: [
                {
                  "name": "current",
                  "type": "date",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "date",
                  "title": "Compare to"
                }
              ]
            },
            { id: 2, title: "Weekly", filters: [
                {
                  "name": "current",
                  "type": "week",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "week",
                  "title": "Compare to"
                }
              ]
            },
            { id: 3, title: "Monthly", filters: [
                {
                  "name": "current",
                  "type": "month",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "month",
                  "title": "Compare to"
                }
              ]
            },
            { id: 4, title: "Weekly Cum.", filters: [
                {
                  "name": "current",
                  "type": "weekcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "weekcum",
                  "title": "Compare To"
                }
              ]
            },
            { id: 5, title: "Monthly Cum.", filters: [
                {
                  "name": "current",
                  "type": "monthcum",
                  "title": "Current"
                },
                {
                  "name": "compareTo",
                  "type": "monthcum",
                  "title": "Compare to"
                }
              ]
            },
          ],
          "graphs": [
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Loyalties\\Daily\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Loyalties\\Daily\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 1 ],
              "fetchPrototype": {
                "class": "Loyalties\\Daily\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Loyalties\\Weekly\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Inflow Incidence",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,

              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Loyalties\\Weekly\\InflowIncidence",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Loyalties\\Weekly\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales Incidence",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Loyalties\\Weekly\\SalesIncidence",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 2 ],
              "fetchPrototype": {
                "class": "Loyalties\\Weekly\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Loyalties\\Monthly\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Inflow Incidence",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Loyalties\\Monthly\\InflowIncidence",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Loyalties\\Monthly\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales Incidence",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Loyalties\\Monthly\\SalesIncidence",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 3 ],
              "fetchPrototype": {
                "class": "Loyalties\\Monthly\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Inflow",
              "type" : "AreaChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Loyalties\\WeeklyCum\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales",
              "type" : "AreaChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Loyalties\\WeeklyCum\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "AreaChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 4 ],
              "fetchPrototype": {
                "class": "Loyalties\\WeeklyCum\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            
            
            { 
              "title": "Inflow",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Loyalties\\MonthlyCum\\Inflow",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Inflow Incidence",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Loyalties\\MonthlyCum\\InflowIncidence",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Loyalties\\MonthlyCum\\Sales",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Sales Incidence",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Loyalties\\MonthlyCum\\SalesIncidence",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
            { 
              "title": "Average Ticket",
              "type" : "ColumnChart",
              "legend": "bottom",
              "xAxisSlanted": true,
              "height": 500,
              "vAxisFormat": "€ ",
              "filterType": [ 5 ],
              "fetchPrototype": {
                "class": "Loyalties\\MonthlyCum\\AverageTicket",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current"
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo"
                    },
                  ],
                "groupBy" : [
                    "DateEntry"
                  ],
                "groupByConditions": "SUM",
                "fields": [
                    "DateEntry",
                    "Current",
                    "Compare"
                  ],
                "orderBy": "DateEntry"
              }
            },
          ]
        }
      ]
    },
    {
      "title": "Export",
      "slug": "export",
      "icon": "file_download",
      "subsections": [
        {
          "title": "Entrance",
          "slug": "entrance",
          "filters": [],
        },
        {
          "title": "Shops",
          "slug": "shops",
          "filters": [],
        },
        {
          "title": "WiFi",
          "slug": "wifi",
          "filters": [],
        }
      ]
    }
  ]
}
