import axios from 'axios';
import moment from 'moment';
import AuthService from './AuthService';

const Auth = new AuthService();

export default class GraphService {

    constructor() {
        this.fetchData = this.fetchData.bind(this);
        this.convertGraphData = this.convertGraphData.bind(this);
        //this.exportExcel = this.exportExcel.bind(this);
    }
    
    convertGraphData(res, data) {
      let dataArray = [];
      if(data.chartType === "PieChart") {
        dataArray.push(["Section", "Value"]);
        for(let i in res) {
          Object.keys(res[i]).forEach((item) => {
            dataArray.push([ item, res[i][item] ]);
          });
        }
        return dataArray;
      }
      
      if(data.chartType === "AreaChart") {
        dataArray.push(data['fields']);
        for(let i in res) {
          let array = []
          for(let j = 0; j < data['fields'].length; j++) {
            if(j === 0) {
              array.push(moment(res[i][data['fields'][j]], "YYYY-MM-DD", true).isValid() ? moment(res[i][data['fields'][j]]).format('YYYY-MM-DD') : res[i][data['fields'][j]].toString());
            } else
              array.push(res[i][data['fields'][j]]);
          }
          
          dataArray.push(array);
        }
        return dataArray;
      }
      
      dataArray.push(data['fields']);
      for(let i in res) {
        let array = []
        for(let j = 0; j < data['fields'].length; j++) {
          if(j === 0) {
            array.push(res[i][data['fields'][j]].toString());
          } else
            array.push(res[i][data['fields'][j]]);
        }
        
        dataArray.push(array);
      }
      console.log(dataArray);
      return dataArray;
    }

    fetchData(data) {
      return axios.post(Auth.domain + "/api/fetch", data);      
    }
        
    exportExcel(data) {
      return axios.post(Auth.domain + "/api/excel", data);
    }
}
