import React, { Component } from 'react';
import { Row, Col, Table, Card, CardBody,CardHeader, CardFooter, Button } from 'reactstrap';
import TableService from '../services/TableService';
import Loader from 'react-loader';
import moment from 'moment';
import { FormGroup, Label } from 'reactstrap';
import DatePicker from "react-datepicker";

class WifiTables extends Component {

  constructor(props) {
    super(props);
    this.TableService = new TableService();
    this.state = { data: [], filtersOpened: true, loaded: true, currentEndDate: moment().toDate(),
        currentStartDate: moment().subtract(1, 'days').toDate(),
        prevEndDate: moment().subtract(2, 'days').toDate(),
        prevStartDate: moment().subtract(3, 'days').toDate() };
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  loadData() {
    this.setState({ loaded: false });
    let toSend = {
        currentEndDate: moment(this.state.currentEndDate).format('YYYY-MM-DD HH:mm'),
        currentStartDate: moment(this.state.currentStartDate).format('YYYY-MM-DD HH:mm'),
        prevEndDate: moment(this.state.prevEndDate).format('YYYY-MM-DD HH:mm'),
        prevStartDate: moment(this.state.prevStartDate).format('YYYY-MM-DD HH:mm')
    }
    
    this.TableService.fetchWifiData(toSend).then((res) => {
      if(!res.data || res.data.length === 0) {
        this.setState({ data: [], loaded: true });
      } else {
        this.setState({ data: res.data, loaded: true });
      }
    })
    .catch((err) => {
      this.setState({ data: [], loaded: true });
    });
  }
  
  render() {
    return (
        <Col md={10} lg={8}>
          <Card className="mb-3">
            <CardHeader className="d-flex justify-content-between"><span>Filters</span> <span onClick={() => this.setState({ filtersOpened: !this.state.filtersOpened })} className="material-icons">{ this.state.filtersOpened ? "expand_less" : "expand_more" }</span></CardHeader>
            { this.state.filtersOpened && <><CardBody>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Current Start Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ currentStartDate: date })  } selected={this.state.currentStartDate ?? undefined} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Current End Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ currentEndDate: date })  } selected={this.state.currentEndDate ?? undefined} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Previous Start Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ prevStartDate: date })  } selected={this.state.prevStartDate ?? undefined} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Previous End Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ prevEndDate: date })  } selected={this.state.prevEndDate ?? undefined} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button className="btn btn-success" onClick={ () => { this.setState({filtersOpened: false}); this.loadData(); }}>Apply Filters</Button>
            </CardFooter></>}
          </Card>
          <Card className="mb-3">
            <Loader loaded={this.state.loaded} />
            { (this.state.data && this.state.data.current_count) && <CardBody>
              <Table className="table-sm">
                <thead>
                  <tr>
                    <th>Metric</th>
                    <th>Current Value</th>
                    <th>Previous Value</th>
                    <th>Difference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Different Mac Addresses in range</td>
                    <td>{this.state.data.current_count.toLocaleString('it-IT')}</td>
                    <td>{this.state.data.prev_count.toLocaleString('it-IT')}</td>
                    <td>{(this.state.data.current_count-this.state.data.prev_count).toLocaleString('it-IT') } </td>
                  </tr>
                  <tr>
                    <td>Average time (hh:mm:ss)</td>
                    <td>{moment(this.state.data.current_average, "hh:mm:ss.S").format('hh:mm:ss')}</td>
                    <td>{moment(this.state.data.prev_average, "hh:mm:ss.S").format('hh:mm:ss')}</td>
                    <td>{Math.round((moment(this.state.data.current_average, "hh:mm:ss.S").unix() - moment(this.state.data.prev_average, "hh:mm:ss.S").unix())/60)} minutes</td>
                  </tr>
                  <tr>
                    <td>Recurring Mac Address</td>
                    <td>{this.state.data.recurrent}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </CardBody> }
            { (!this.state.data || !this.state.data.current_count) && <CardBody>
            <h4>No data available with this settings.</h4>
            </CardBody>}
          </Card>
        </Col>
    )
  }

}

export default WifiTables;
