import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select'

const optionsYears = [
  { value: 2019, label: 2019 },
  { value: 2020, label: 2020 },
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
];

const getWeeks = function() {
  let weeks = [];
  for(let i = 1; i<=52; i++) {
    weeks.push({ value: i, label: i });
  }
  return weeks;
}

function getFirstDayOfFirstWeekByYear( year ) {
  // Create moment object for the first day of the given year
  let func = moment({year:year})
  // Check if 1st of January is in the first week of the year
  if( func.week() !== 1 ){
    // If not, add a week to the first day of the current week
    func.startOf('week').add(1, 'week');
  }
  // Return result using english locale
  return func;
}


class WeekCumField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
    moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
      doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
    }});

    moment.locale('en');
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(type, value) {
    let data = {};
    if(this.props.value[this.props.data.name]) {
      data = Object.assign({}, this.props.value[this.props.data.name]);
    }
  
    if(type === "year") {
      data["initial"] = getFirstDayOfFirstWeekByYear(value.value).toDate();
      data["end"] = getFirstDayOfFirstWeekByYear(value.value).toDate();
    } 
    if(type === "initialweek") {
      let d = getFirstDayOfFirstWeekByYear(moment(this.props.value[this.props.data.name] ? this.props.value[this.props.data.name].initial : undefined).format('YYYY'));
      data['initial'] = d.week(value.value).toDate();
    }
    if(type === "endweek") {
      let d = getFirstDayOfFirstWeekByYear(moment(this.props.value[this.props.data.name] ? this.props.value[this.props.data.name].end : undefined).format('YYYY'));
      data['end'] = d.day("Monday").week(value.value).toDate();
    }
    this.props.onChange(data);
  
  }

  render() {
    
    return <>
            <h5>{ this.props.data.title}</h5>
            <FormGroup>
              <Label>Year</Label><br/>
              <Select options={optionsYears} onChange={ (value) => this.handleChange("year", value)  } />
            </FormGroup>
            <FormGroup>
              <Label>Inital Week</Label><br/>
              <Select options={getWeeks()} onChange={ (value) => this.handleChange("initialweek", value)  } /> 
            </FormGroup>
            <FormGroup>
              <Label>End Week</Label><br/>
              <Select options={getWeeks()} onChange={ (value) => this.handleChange("endweek", value)  } /> 
            </FormGroup>
          </>
  }
}

export default WeekCumField;
