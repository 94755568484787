import React, { Component } from 'react';
import { Row, Col, Table, Card, CardBody,CardHeader, CardFooter, Button, Input } from 'reactstrap';
import GraphService from '../services/GraphService';
import Loader from 'react-loader';
import moment from 'moment';
import { FormGroup, Label } from 'reactstrap';
import DatePicker from "react-datepicker";

class WifiExport extends Component {

  constructor(props) {
    super(props);
    this.GraphService = new GraphService();
    this.state = { data: [], filtersOpened: true, loaded: true, year: moment().format('Y'), month: moment().format('M') };
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount() {
  }
  
  loadData() {
    this.setState({loaded:false});
    this.GraphService.exportExcel({ year: this.state.year, month: this.state.month }).then((res) => {
      this.setState({loaded:true});
      window.open("/storage/" + res.data.filename, "_blank");
    });
  }
  
  render() {
    return (
        <Col md={10} lg={8}>
          <Card className="mb-3">
            <CardHeader className="d-flex justify-content-between"><span>Filters</span> </CardHeader>
            { this.state.filtersOpened && <><CardBody>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Month</Label><br/>
                    <Input value={this.state.month} onChange={ (value) => this.setState({month: value}) } />
                  </FormGroup>
                  <FormGroup>
                    <Label>Year</Label><br/>
                    <Input value={this.state.year} onChange={ (value) => this.setState({year: value}) } />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button className="btn btn-success" onClick={ () => { this.loadData(); }}>Download</Button>
            </CardFooter></>}
          </Card>
          
        </Col>
    )
  }

}

export default WifiExport;
