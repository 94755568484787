import React, { Component } from 'react';
import { Row, Col, Table, Card, CardBody,CardHeader, CardFooter, Button } from 'reactstrap';
import TableService from '../services/TableService';
import Loader from 'react-loader';
import moment from 'moment';
import { FormGroup, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import { Chart } from "react-google-charts";

class WifiCharts extends Component {

  constructor(props) {
    super(props);
    this.TableService = new TableService();
    this.state = { data: [], dataAvg: [], filtersOpened: true, loaded: true, currentEndDate: moment().toDate(),
        currentStartDate: moment().subtract(7, 'days').toDate(),
        prevEndDate: moment().subtract(8, 'days').toDate(),
        prevStartDate: moment().subtract(14, 'days').toDate() };
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  loadData() {
    this.setState({ loaded: false });
    let toSend = {
        currentEndDate: moment(this.state.currentEndDate).format('YYYY-MM-DD HH:mm'),
        currentStartDate: moment(this.state.currentStartDate).format('YYYY-MM-DD HH:mm'),
        prevEndDate: moment(this.state.prevEndDate).format('YYYY-MM-DD HH:mm'),
        prevStartDate: moment(this.state.prevStartDate).format('YYYY-MM-DD HH:mm')
    }
    
    let promises = [];
    promises.push(this.TableService.fetchWifiInflowData(toSend));
    promises.push(this.TableService.fetchWifiIntervalData(toSend));
    Promise.all(promises).then((ress) => {
      let res = ress[0];
      if(!res.data || res.data.length === 0) {
        this.setState({ data: [], loaded: true, dataAvg:[] });
      } else {
        this.setState({ data: res.data, dataAvg: ress[1].data, loaded: true });
      }
    })
    .catch((err) => {
      this.setState({ data: [], loaded: true });
    });
  }
  
  render() {
    return (
      <>
        <Col md={10} lg={8}>
          <Card className="mb-3">
            <CardHeader className="d-flex justify-content-between"><span>Filters</span> <span onClick={() => this.setState({ filtersOpened: !this.state.filtersOpened })} className="material-icons">{ this.state.filtersOpened ? "expand_less" : "expand_more" }</span></CardHeader>
            { this.state.filtersOpened && <><CardBody>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Current Start Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ currentStartDate: date })  } selected={this.state.currentStartDate ?? undefined}  showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
                  </FormGroup>
                  {/*<FormGroup>
                    <Label>Current End Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ currentEndDate: date })  } selected={this.state.currentEndDate ?? undefined} showTimeSelect dateFormat="dd/MM/yyyy HH:mm"/>
                  </FormGroup> */}
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Current End Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ currentEndDate: date })  } selected={this.state.currentEndDate ?? undefined} showTimeSelect dateFormat="dd/MM/yyyy HH:mm"/>
                  </FormGroup>
                  {/* <FormGroup>
                    <Label>Previous Start Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ prevStartDate: date })  } selected={this.state.prevStartDate ?? undefined}  showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Previous End Date</Label><br/>
                    <DatePicker onChange={ (date) => this.setState({ prevEndDate: date })  } selected={this.state.prevEndDate ?? undefined}  showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
                  </FormGroup> */}
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button className="btn btn-success" onClick={ () => { this.setState({filtersOpened: false}); this.loadData(); }}>Apply Filters</Button>
            </CardFooter></>}
          </Card>
        </Col>
        <Col md={12}>
          <Card className="mb-3">
            <Loader loaded={this.state.loaded} />
            { (this.state.data && this.state.data.length > 0) && <CardBody>
              <Row>
                <Col md={6}>
                  <Chart
                    height={"500px"}
                    chartType={ "ColumnChart" }
                    loader={<div>Loading Chart</div>}
                    data={ this.state.data }
                    options={{
                      title: "Wifi Inflow" ,
                      titleTextStyle: {
                          textAlign: 'center',
                          color: '#000000',    // any HTML string color ('red', '#cc00cc')
                          fontName: 'Lato', // i.e. 'Times New Roman'
                          fontSize: 18, // 12, 18 whatever you want (don't specify px)
                          bold: true,    // true or false
                          italic: false   // true of false
                      },
                      chartArea: { width: '80%', bottom: 120, top: 50 },
                      colors: ['#dc3912', '#3366cc', '#3A3335', '#FDF0D5', '#C6D8D3', '#4B3B47', '#E0D8DE', '#FFC759', '#607196' ],
                      is3D: true,
                      hAxis: {
                        titlePosition: 'none',
                        format: 'd/M/yy',
                        slantedText: true, 
                        slantedTextAngle: 45,
                        maxTextLines: 2,
                      },
                      vAxis: {
                        titlePosition: 'none',
                        minValue: 0,
                        format: 'decimal'
                      },
                      legend: "none",
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Table className="table-sm">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Inflow</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data && this.state.data.length > 1 && this.state.data.map((row, rowKey) => (
                        <>{ rowKey !== 0 ? (
                          <tr key={rowKey}>
                            <td>{row[0]}</td>
                            <td>{row[1].toLocaleString('it-IT')}</td>
                          </tr>
                         ) : ( <React.Fragment /> ) }</>
                        ))
                      }
                    </tbody>
                  </Table>
                </Col>
                <Col md={6}>
                  <Chart
                    height={"500px"}
                    chartType={ "ColumnChart" }
                    loader={<div>Loading Chart</div>}
                    data={ this.state.dataAvg }
                    options={{
                      title: "Wifi Average Time" ,
                      titleTextStyle: {
                          textAlign: 'center',
                          color: '#000000',    // any HTML string color ('red', '#cc00cc')
                          fontName: 'Lato', // i.e. 'Times New Roman'
                          fontSize: 18, // 12, 18 whatever you want (don't specify px)
                          bold: true,    // true or false
                          italic: false   // true of false
                      },
                      chartArea: { width: '80%', bottom: 120, top: 50 },
                      colors: ['#dc3912', '#3366cc', '#3A3335', '#FDF0D5', '#C6D8D3', '#4B3B47', '#E0D8DE', '#FFC759', '#607196' ],
                      is3D: true,
                      hAxis: {
                        titlePosition: 'none',
                        format: 'd/M/yy',
                        slantedText: true, 
                        slantedTextAngle: 45,
                        maxTextLines: 2,
                      },
                      vAxis: {
                        titlePosition: 'none',
                        minValue: 0,
                        format: 'decimal'
                      },
                      legend: "none",
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Table className="table-sm">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Inflow</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataAvg && this.state.dataAvg.length > 1 && this.state.dataAvg.map((row, rowKey) => (
                        <>{ rowKey !== 0 ? (
                          <tr key={rowKey}>
                            <td>{row[0]}</td>
                            <td>{row[1].toLocaleString('it-IT')}</td>
                          </tr>
                         ) : ( <React.Fragment /> ) }</>
                        ))
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody> }
            { (!this.state.data || this.state.data.length === 0) && <CardBody>
            <h4>No data available with this settings.</h4>
            </CardBody>}
          </Card>
        </Col>
      </>
    )
  }

}

export default WifiCharts;
