import axios from 'axios';
import AuthService from './AuthService';

const Auth = new AuthService();

export default class TableService {

    constructor() {
        this.fetchData = this.fetchData.bind(this);
        this.getCenterPerformanceTable = this.getCenterPerformanceTable.bind(this);
        //this.exportExcel = this.exportExcel.bind(this);
    }
    
    getCenterPerformanceTable(data) {
        return axios.post(Auth.domain + "/api/fetchCenterPerformanceTable", data);      
    }
    
    fetchData(data) {
      return axios.post(Auth.domain + "/api/fetch", data);      
    }
    
    fetchWifiData(data) {
      return axios.post(Auth.domain + "/api/wifiTables", data);      
    }
    
    fetchWifiInflowData(data) {
      return axios.post(Auth.domain + "/api/wifiInflowChart", data);      
    }
    
    fetchWifiIntervalData(data) {
      return axios.post(Auth.domain + "/api/wifiIntervalChart", data);      
    }
        
    exportExcel(data) {
      return axios.post(Auth.domain + "/api/excel", data);
    }
}
