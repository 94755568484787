import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Heatmap from './Heatmap';
import TableComponent from './TableComponent';
import GraphComponent from './GraphComponent';
import CenterGeneralPerformanceTable from './CenterGeneralPerformanceTable';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class DataComponent extends Component {

  constructor(props) {
    super(props);
    this.state = { activeTab : '1' };
    this.toggle = this.toggle.bind(this);
  }
  
  toggle(tab) {
    this.setState({activeTab:tab});
  }

  componentDidMount() {
    this.props.setSection(this.props.section);
  }
  
  render() {
    let that = this;
    return (
      <>
        <Nav tabs className="tabs-table-graphs">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Tables
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Graphs
            </NavLink>
          </NavItem>
          { this.props.section.generalPerformanceTable && <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              General Performance Table
            </NavLink>
          </NavItem> }
          { this.props.section.rankings && this.props.section.rankings.length > 0 && <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Rankings
            </NavLink>
          </NavItem> }
          { this.props.section.hasHeatmap && <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '5' })}
              onClick={() => { this.toggle('5'); }}
            >
              Heatmap
            </NavLink>
          </NavItem> }
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            { this.props.section.tables && this.props.section.tables.filter((e) => e.filterType.includes(this.props.filters.filterType)).map((item, key) => (<Row key={key} className="mt-1"><TableComponent data={item} filters={that.props.filters} /></Row>)) }
          </TabPane>
          <TabPane tabId="2">
            <Row className="mt-1">
                { this.props.section.graphs && this.props.section.graphs.filter((e) => e.filterType.includes(this.props.filters.filterType)).map((item, key) => (<GraphComponent data={item} key={key} filters={that.props.filters} />)) }
            </Row>
          </TabPane>
          { this.props.section.generalPerformanceTable && <TabPane tabId="3">
            <Row className="mt-1">
              <Col>
                <CenterGeneralPerformanceTable filters={that.props.filters} />
              </Col>
            </Row>
          </TabPane> }
          { this.props.section.rankings && this.props.section.rankings.length > 0 && <TabPane tabId="4">
            { this.props.section.rankings.filter((e) => e.filterType.includes(this.props.filters.filterType)).map((item, key) => (<Row key={key} className="mt-1"><TableComponent data={item} filters={that.props.filters} /></Row>)) }
          </TabPane> }
          { this.props.section.hasHeatmap && <TabPane tabId="5">
            <Row className="mt-1"><Heatmap filters={that.props.filters} /></Row>
          </TabPane> }
        </TabContent>
      </>
    )
  }

}

export default DataComponent;
