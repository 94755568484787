import {menu} from './config';

export default class LayoutService {
    
    constructor() {
        this.getStructure = this.getStructure.bind(this);
    }
    
    getStructure() {
      return new Promise((resolve, reject) => {
        resolve(menu);
      });
    }
}
