import React, { Component } from 'react';
import moment from 'moment';

class RenderFilters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
  }

  componentDidMount() {

  }

  render() {
    let i = 0;
    if(this.props.data.type === "date")
      return <>&nbsp;<strong>{ this.props.data.title }:</strong> From {moment(this.props.value[this.props.data.name].initial).format('DD/MM/Y')} To {moment(this.props.value[this.props.data.name].end).format('DD/MM/Y')}</>
    else if(this.props.data.type === "week")
      return <>&nbsp;<strong>{ this.props.data.title }:</strong> Year {moment(this.props.value[this.props.data.name].initial).format('Y')} - Week {moment(this.props.value[this.props.data.name].initial).format('W')}</>
    
    else if(this.props.data.type === "month")
      return <>&nbsp;<strong>{ this.props.data.title }:</strong> Year {moment(this.props.value[this.props.data.name].initial).format('Y')} - Month {moment(this.props.value[this.props.data.name].initial).format('MM')}</>
    else if(this.props.data.type === "weekcum")
      return <>&nbsp;<strong>{ this.props.data.title }:</strong> {moment(this.props.value[this.props.data.name].initial).format('Y')} - <strong>Week from</strong> {moment(this.props.value[this.props.data.name].initial).format('W')} <strong>to</strong> {moment(this.props.value[this.props.data.name].end).format('W')}</>
    else if(this.props.data.type === "monthcum")
      return <>&nbsp;<strong>{ this.props.data.title }:</strong> {moment(this.props.value[this.props.data.name].initial).format('Y')} - <strong>Month from</strong> {moment(this.props.value[this.props.data.name].initial).format('MM')} <strong>to</strong> {moment(this.props.value[this.props.data.name].end).format('MM')}</>
    else if(this.props.data.type === "shop")
      return <>&nbsp;<strong>Shop:</strong> { this.props.value[this.props.data.name].label }</>; // <ShopField {...this.props}/>
    else
      return <React.Fragment key={ i } />;

    
  }
}

export default RenderFilters;
