import React, { Component } from 'react';
import { Col, Table, Card, CardBody } from 'reactstrap';
import TableService from '../services/TableService';
import Loader from 'react-loader';

class TableComponent extends Component {

  constructor(props) {
    super(props);
    this.TableService = new TableService();
    this.state = { data: [], loaded: true };
    this.prepareData = this.prepareData.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  loadData() {
    this.setState({ loaded: false });
    let toSend = this.prepareData();
    this.TableService.fetchData(toSend).then((res) => {
      if(!res.data || res.data.length === 0) {
        this.setState({ data: [], loaded: true });
      } else {
        this.setState({ data: res.data, loaded: true });
      }
    })
    .catch((err) => {
      this.setState({ data: [], loaded: true });
    });
  }
  
  componentDidUpdate(prevProps) {
    for(let k in this.props) {
      if(prevProps[k] !== this.props[k]) {
        this.loadData();
      }
    }
  }
  
  prepareData() {
    let data = this.props.filters; //{ startDate: "2019-01-10", endDate: "2019-01-17" };
    let toSend = Object.assign({}, this.props.data.fetchPrototype);
    for(let k in toSend.filters) {
      if(data.hasOwnProperty(toSend.filters[k].filterName))
        if(toSend.filters[k].filterName === "shop") {
          toSend.filters[k].value = data[toSend.filters[k].filterName].value;
        } else {
          toSend.filters[k].value = data[toSend.filters[k].filterName];
        }
    }
    return toSend;
  }

  render() {
    return (
        <Col md={10} lg={8}>
          <Card className="mb-3">
            <Loader loaded={this.state.loaded} />
            { this.state.data && this.state.data.length > 0 && <CardBody>
              <Table className="table-sm">
                <thead>
                  <tr>
                    { this.props.data && this.props.data.fetchPrototype.fields && this.props.data.fetchPrototype.fields.map((item, key) => (
                        <th key={key} className={ key===0 ? "text-left" : "text-right" }>{ item }</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {this.state.data && this.state.data.length > 0 && this.state.data.map((row, rowKey) => (
                    <tr key={rowKey}>
                      { this.props.data && this.props.data.fetchPrototype.fields && this.props.data.fetchPrototype.fields.map((item, key) => (
                          <td key={key} className={ key===0 ? "text-left" : "text-right" }>{ row[item] }</td>
                        ))
                      }
                    </tr>
                    ))
                  }
                </tbody>
              </Table>
            </CardBody> }
            { (!this.state.data || this.state.data.length === 0) && <CardBody>
            <h4>No data available with this settings.</h4>
            </CardBody>}
          </Card>
        </Col>
    )
  }

}

export default TableComponent;
