import React, { Component } from 'react';
import { Row, Col, Nav, NavItem, NavLink, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import DataComponent from './DataComponent';
import FilterSidebar from './FilterSidebar';
import Sidebar from "react-sidebar";
import WifiTables from './WifiTables';
import WifiCharts from './WifiCharts';
import WifiExport from './WifiExport';
import Heatmap from './Heatmap';
import {Link} from 'react-router-dom';
import RenderFilters from '../types/RenderFilters';
import moment from 'moment';
import Select from 'react-select';
import { FormGroup, Label, CardFooter } from 'reactstrap';

const optionsYears = [
  { value: 2019, label: 2019 },
  { value: 2020, label: 2020 },
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
];

const optionsMonths = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]


class MainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = { sidebarOpen: false, filters: {}, activeSection: undefined };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.setActiveSection = this.setActiveSection.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.doExport = this.doExport.bind(this);
  }
  
  componentDidMount() {
    let filters = { filterType: 1, startDate: moment().add(-7, 'days').toDate(), endDate: moment().toDate() };
    this.applyFilters(filters);
  }
  
  setActiveSection(activeSection) {
    this.setState({ activeSection })
  }
  
  applyFilters(filters) {
    this.setState({filters});
  }
  
  handleChange(field, value) {
    this.setState({ [field]: value });
  }
  
  doExport() {
   
  }
  
  onSetSidebarOpen() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  render() {
  //<DataComponent section={subitem} setSection={ this.setActiveSection } filters={ that.state.filters } />
    let that = this;
    return (
      <>
        <Sidebar
          sidebar={<FilterSidebar filters={ this.state.activeSection && this.state.activeSection.filters } openSidebar={this.onSetSidebarOpen} applyFilters={this.applyFilters} />}
          open={this.state.sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
          styles={{ sidebar: { background: "white" } }}
          pullRight={true}
          sidebarClassName="right-sidebar"
        >
        <Row className="no-gutters">
          <Col className="main-content">
            <div className="container-top-bar px-3">
              <div className="d-flex">
                <img src="/TheMarket-Logo-Desktop.77280d5c7c75684afba1.svg" className="img-logo-bis" />
                <h1 className="label-title">Wifi Dashboard </h1>
              </div>
              { this.state.activeSection && this.state.activeSection.filters && <Button className="btn-filters btn-filters-absolute-mobile pointer" color="info" onClick={ this.onSetSidebarOpen }><i className="fa fa-search"></i></Button> } 
            </div>
            <div className="px-5 py-3 inner-content">
             
              { /* that.props.section.slug !== "export" && this.state.activeSection && this.state.filters && <Card className="my-2"><CardBody className="p-1"><h5 className="mt-2">
                <strong>Filtering By: </strong>
                { this.state.activeSection.filters.filter((e) => e.id === this.state.filters.filterType).map(function(item, key) {
                    return item.filters.map((i, j) => {
                      return that.state.filters[i.name] ? ( <RenderFilters key={j} data={i} value={that.state.filters} /> ) : (<React.Fragment/>)
                    })
                  })
                
                }
              </h5></CardBody></Card> */}
              <Switch>
                <Route path={"/wifi/tables" }><WifiTables /></Route> 
                <Route path={"/wifi/charts" }><WifiCharts/></Route> 
                <Route path={"/wifi/heatmap" }><Heatmap/></Route> 
                <Route path={"/wifi/export" }><WifiExport/></Route> 
              </Switch>

            </div>
          </Col>
        </Row>
        </Sidebar>
      </>
    )
  }

}

export default MainContainer;
