import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select'

const optionsYears = [
  { value: 2019, label: 2019 },
  { value: 2020, label: 2020 },
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
];

const optionsMonths = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]


class MonthField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
    moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
      doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
    }});

    moment.locale('en');
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(type, value) {
    let data = {}
    if(this.props.value[this.props.data.name]) {
      data = Object.assign({}, this.props.value[this.props.data.name]);
    }
  
    if(type === "year") {
      data["initial"] = moment(value.value + "-1-1").toDate();
      data["end"] = moment(value.value + "-1-1").toDate();
    } else {
      data["initial"] = moment(moment(data["initial"]).format("YYYY") + "-" + value.value + "-1").toDate();
      data["end"] = moment(moment(data["end"]).format("YYYY") + "-" + value.value + "-1").add(30, 'd').toDate();
    }
    this.props.onChange(data);
  }

  render() {

    return <>
            <h5>{ this.props.data.title}</h5>
            <FormGroup>
              <Label>Year</Label><br/>
              <Select options={optionsYears} onChange={ (value) => this.handleChange("year", value)  } />
            </FormGroup>
            <FormGroup>
              <Label>Month</Label><br/>
              <Select options={optionsMonths} onChange={ (value) => this.handleChange("month", value)  } /> 
            </FormGroup>
          </>
  }
}

export default MonthField;
