import axios from 'axios';

export default class AuthService {
    // Initializing important variables
    constructor() {
        let baseUrl = window.location;
        if(baseUrl.hostname === "127.0.0.1" || baseUrl.hostname === "localhost") {
          this.domain = 'https://wifidashboard.sanmarinooutlet.com' // API server domain
        } else {
          this.domain = "https://wifidashboard.sanmarinooutlet.com";
        }
        
        this.checkToken = this.checkToken.bind(this);
        this.logout = this.logout.bind(this);
        this.setToken = this.setToken.bind(this);
        this.getToken = this.getToken.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
        this.buildMultipartHeaders = this.buildMultipartHeaders.bind(this);
        this.buildHeaders = this.buildHeaders.bind(this);
    }

    checkToken(token) {
        // Get a token from api server using the fetch api
        localStorage.clear();
        return axios.get(this.domain + "/api/verifyToken", {
          headers: {
            "Authorization": "Bearer " + token
          }
        });
    }
    
    logout() {
      localStorage.removeItem('token');
      localStorage.clear();
    }

    setToken(res) {
        let date = new Date();
        let token = {
            accessToken: res.access_token,
            expirationDate: date.setSeconds(date.getSeconds() + res.expires_in),
        }
        localStorage.setItem('token', JSON.stringify(token))
    }

    getToken() {
        // Retrieves the user token from localStorage
        return JSON.parse(localStorage.getItem('token'))
    }

    isTokenExpired() {
        let token = this.getToken();
        return !token.hasOwnProperty('expirationDate') || Date.now() > token.expirationDate;
    }

    isLoggedIn() {
        const token = this.getToken()
        console.log(token)
        return token && token.hasOwnProperty('accessToken') && !this.isTokenExpired(token)
    }

    buildHeaders() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken().accessToken
        }
        return headers;
    }
    
    buildMultipartHeaders() {
        const headers = {
            //'Content-Type': 'multipart/form-data',
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken().accessToken
        }
        return headers;
    }
}
