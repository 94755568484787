import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Sidebar from './layouts/Sidebar';
import MainContainer from './layouts/MainContainer';
import LayoutService from './services/LayoutService';
import AuthService from './services/AuthService';
import WithAuth from './services/WithAuth';
import './scss/App.scss';
import { Route, Switch } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import Loader from 'react-loader';
import logo from './images/logo_brown_2x.png';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

const DummyComponent = () => (<React.Fragment />);
const SidebarComponent = withRouter(props => <Sidebar {...props}/>);
const MainComponent = withRouter(props => <MainContainer {...props}/>);

class App extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <Switch>
        <Route exact path="/login" component={LoginComponent} />
        <Route path="/" component={MainApp} />
      </Switch>
    )
  }
}

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();
    let params = queryString.parse(this.props.location.search)
    this.state = { loading: false, queryString: params };
    
  }
  
  componentDidMount() {
    if(!this.state.queryString || !this.state.queryString.token)
      return;
    this.AuthService.checkToken(this.state.queryString.token).then((res) => {
      this.setState({ loading: true });
      this.AuthService.setToken({ access_token: this.state.queryString.token, expires_in: this.state.queryString.expires_in });
      this.props.history.push('/');
      
    })
    .catch((err) => {
      console.log("Error while checking token");
    });
  }
  
  render() {
    return (
      <div className="App-login">
        <Container fluid={true} className="main-container">
          <Row className="flex-grow-1 justify-content-center">
            <Col md={6} lg={4} className="align-items-center d-flex">
              <Card className="full-width">
                <CardBody>
                  <img src={logo} alt="Logo The Market" className="py-5 px-3 img-responsive" />
                  <Loader loaded={this.state.loading} options={ {position: 'relative' } }/>
                  <h4 className="text-center d-block pt-5">Please wait</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

class MainApp extends Component {
  
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();
    this.state = { mainStructure: undefined }
    this.LService = new LayoutService();
  }

  componentDidMount() {
    if(this.AuthService.isLoggedIn()) {
      console.log("islogged")
      this.LService.getStructure().then((res) => {
        this.setState({ mainStructure: res }, function() {
          this.props.history.push('/wifi/tables');
        });
        
      });
    } else {
      console.log("not islogged")
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="App">
        <Container fluid={true} className="main-container">
          <Row className="flex-grow-1">
            <SidebarComponent  />
            <Col md={11} className="main-content">
              <MainComponent />
              
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(App);
