import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import DatePicker from "react-datepicker";

class DailyField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(type, value) {
    let data = {}
    if(this.props.value[this.props.data.name]) {
      data = Object.assign({}, this.props.value[this.props.data.name]);
    }
    data[type] = value;
    this.props.onChange(data);
  }

  render() {

    return <>
            <h5>{ this.props.data.title}</h5>
            <FormGroup>
              <Label>Initial Date</Label><br/>
              <DatePicker onChange={ (date) => this.handleChange("initial", date)  } selected={this.props.value[this.props.data.name] ? this.props.value[this.props.data.name].initial : undefined} dateFormat="dd/MM/yyyy" />
            </FormGroup>
            <FormGroup>
              <Label>End Date</Label><br/>
              <DatePicker onChange={ (date) => this.handleChange("end", date)  } selected={this.props.value[this.props.data.name] ? this.props.value[this.props.data.name].end : undefined} dateFormat="dd/MM/yyyy" />
            </FormGroup>
          </>
  }
}

export default DailyField;
