import React, { Component } from 'react';
import { Col, Card, Button, CardBody, Table, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader, CardFooter, Row, Label, FormGroup } from 'reactstrap';
import { Chart } from "react-google-charts";
import GraphService from '../services/GraphService';
import Loader from 'react-loader';
import moment from 'moment';
import classnames from 'classnames';
import DatePicker from "react-datepicker";

const heatmapPlaces = {
  "circle1": "Welcome Plaza",
  "circle2": "Entrance A",
  "circle3": "Between A-B",
  "circle4": "Entrance B",
  "circle5": "Event Plaza",
  "circle6": "Entrance C",
  "circle7": "Between C-Plaza",
  "circle8": "Garden Plaza",
}

const heatmapConfig = [
  { "color": "#grad1", "realColor": "#dadada", "min": 0, "max": 0.5 },
  { "color": "#grad2", "realColor": "#e8a4e8", "min": 0.5, "max": 1 },
  { "color": "#grad3", "realColor": "#de7cde", "min": 1, "max": 1.5 },
  { "color": "#grad4", "realColor": "#cc6ad8", "min": 1.5, "max": 2 },

  { "color": "#grad5", "realColor": "#ae73d8", "min": 2, "max": 2.25 },
  { "color": "#grad6", "realColor": "#917dd8", "min": 2.25, "max": 2.5 },
  { "color": "#grad7", "realColor": "#6773e0", "min": 2.5, "max": 2.75 },
  { "color": "#grad8", "realColor": "#3a64ea", "min": 2.75, "max": 3 },

  { "color": "#grad9", "realColor": "#2567f1", "min": 3, "max": 3.25 },
  { "color": "#grad10", "realColor": "#57a3ec", "min": 3.25, "max": 3.5 },
  { "color": "#grad11", "realColor": "#88dee7", "min": 3.5, "max": 3.75 },
  { "color": "#grad12", "realColor": "#81e5c7", "min": 3.75, "max": 4 },

  { "color": "#grad13", "realColor": "#5fd19b", "min": 4, "max": 4.25 },
  { "color": "#grad14", "realColor": "#3ec070", "min": 4.25, "max": 4.5 },
  { "color": "#grad15", "realColor": "#2fca52", "min": 4.5, "max": 4.75 },
  { "color": "#grad16", "realColor": "#20d535", "min": 4.75, "max": 5 },

  { "color": "#grad17", "realColor": "#2fda32", "min": 5, "max": 5.25 },
  { "color": "#grad18", "realColor": "#5cd94b", "min": 5.25, "max": 5.5 },
  { "color": "#grad19", "realColor": "#88d864", "min": 5.5, "max": 5.75 },
  { "color": "#grad20", "realColor": "#b0e141", "min": 5.75, "max": 6 },

  { "color": "#grad21", "realColor": "#d7eb1a", "min": 6, "max": 6.25 },
  { "color": "#grad22", "realColor": "#f3e801", "min": 6.25, "max": 6.5 },
  { "color": "#grad23", "realColor": "#f8ca06", "min": 6.5, "max": 6.75 },
  { "color": "#grad24", "realColor": "#fdac0b", "min": 6.75, "max": 7 },

  { "color": "#grad25", "realColor": "#f79709", "min": 7, "max": 7.25 },
  { "color": "#grad26", "realColor": "#ed8304", "min": 7.25, "max": 7.5 },
  { "color": "#grad27", "realColor": "#e76c03", "min": 7.5, "max": 7.75 },
  { "color": "#grad28", "realColor": "#f14417", "min": 7.75, "max": 8 },

  { "color": "#grad29", "realColor": "#fa1c2a", "min": 8, "max": 8.5 },
  { "color": "#grad30", "realColor": "#ff3a4c", "min": 8.5, "max": 9 },
  { "color": "#grad31", "realColor": "#ff8979", "min": 9, "max": 10 },
  { "color": "#grad32", "realColor": "#ffd8a5", "min": 10, "max": 100 },

];

class Heatmap extends Component {

  constructor(props) {
    super(props);
    this.GraphService = new GraphService();
    this.state = { data: [], expanded: false, loaded: true, activeTab: '1', currentEndDate: moment().toDate(),
        currentStartDate: moment().subtract(1, 'days').toDate(),
        prevEndDate: moment().subtract(8, 'days').toDate(),
        prevStartDate: moment().subtract(14, 'days').toDate() };
    this.loadData = this.loadData.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.prepareDataWifi = this.prepareDataWifi.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  
  loadData() {
    this.setState({ loaded: false });
    
    let toSend1 = this.prepareDataWifi();
    this.GraphService.fetchData(toSend1).then((res) => {
      if(!res.data || res.data.length === 0) {
        this.setState({ data: [], loaded: true });
      } else {
        for(var i in res.data) {
          let val = heatmapConfig.filter((e) => e['min']*50 <= res.data[i].conteggio && e['max']*50 > res.data[i].conteggio );
          if(val.length === 0)
           continue;
           res.data[i].filler = val[0].realColor;
           res.data[i].norm_val = val[0].min;
          var p = document.getElementById(res.data[i].circle_name)
          if(!p) {
            continue;
          }
          p.style.fill = "url("+val[0].color+")";
          
        }
        this.setState({ data: res.data, loaded: true });
      }
    })
    .catch((err) => {
      this.setState({ data: [], loaded: true });
    });
  }
  
  componentDidUpdate(prevProps) {
    for(let k in this.props) {
      if(prevProps[k] !== this.props[k]) {
        this.loadData();
        this.setState({ expandChart: false });
      }
    }
  }
  
  prepareData() {
    //let data = this.props.filters; //{ startDate: "2019-01-10", endDate: "2019-01-17" };
    let toSend = {
                "class": "Inflow\\Center\\RealTime\\Heatmap",
                "filters": [
                    {
	                    "name": "TrafficDate",
	                    "operator": ">=",
	                    "filterName": "current",
	                    "value" : moment(this.state.currentStartDate).format('YYYY-MM-DD HH:mm')
                    },
                    {
	                    "name": "TrafficDate",
	                    "operator": "<=",
	                    "filterName": "compareTo",
	                    "value" : moment(this.state.currentEndDate).format('YYYY-MM-DD HH:mm')
                    },
                  ],
                "fields": [
                    "Data",
                    "Current",
                    "Compare",
                    "Delta",
                  ],
                "orderBy": "Date"
              };
    
    return toSend;
  }
  
  prepareDataWifi() {
    let data = this.props.filters; //{ startDate: "2019-01-10", endDate: "2019-01-17" };
    let toSend = {
                "class": "Inflow\\Center\\RealTime\\HeatmapWifi",
                "filters": {
	                    "start" : moment(this.state.currentStartDate).format('YYYY-MM-DD HH:mm'),
	                    "end" : moment(this.state.currentEndDate).format('YYYY-MM-DD HH:mm')
                  },
                "fields": [
                    "Data",
                    "Current",
                    "Compare",
                    "Delta",
                  ],
                "orderBy": "Date"
              };
    
    return toSend;
  }

  render() {
    return (
      <Col md="12">
        <Card className="mb-3">
          <CardHeader className="d-flex justify-content-between"><span>Filters</span> <span onClick={() => this.setState({ filtersOpened: !this.state.filtersOpened })} className="material-icons">{ this.state.filtersOpened ? "expand_less" : "expand_more" }</span></CardHeader>
          { this.state.filtersOpened && <><CardBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Current Start Date</Label><br/>
                  <DatePicker showTimeSelect onChange={ (date) => this.setState({ currentStartDate: date })  } selected={this.state.currentStartDate ?? undefined} dateFormat="dd/MM/yyyy HH:mm" />
                </FormGroup>
                {/*<FormGroup>
                  <Label>Current End Date</Label><br/>
                  <DatePicker showTimeSelect onChange={ (date) => this.setState({ currentEndDate: date })  } selected={this.state.currentEndDate ?? undefined} dateFormat="dd/MM/yyyy HH:mm" />
                </FormGroup> */}
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Current End Date</Label><br/>
                  <DatePicker showTimeSelect onChange={ (date) => this.setState({ currentEndDate: date })  } selected={this.state.currentEndDate ?? undefined} dateFormat="dd/MM/yyyy HH:mm" />
                </FormGroup>
                {/* <FormGroup>
                  <Label>Previous Start Date</Label><br/>
                  <DatePicker showTimeSelect onChange={ (date) => this.setState({ prevStartDate: date })  } selected={this.state.prevStartDate ?? undefined} dateFormat="dd/MM/yyyy HH:mm" />
                </FormGroup>
                <FormGroup>
                  <Label>Previous End Date</Label><br/>
                  <DatePicker showTimeSelect onChange={ (date) => this.setState({ prevEndDate: date })  } selected={this.state.prevEndDate ?? undefined} dateFormat="dd/MM/yyyy HH:mm" />
                </FormGroup> */}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button className="btn btn-success" onClick={ () => { this.setState({filtersOpened: false}); this.loadData(); }}>Apply Filters</Button>
          </CardFooter></>}
        </Card>
        <Card className="mb-3">
          <Loader loaded={this.state.loaded} />          
          <CardBody className="text-center">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  Heatmap
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  Data
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
            
                <Table className="table-sm">
                  <tbody>
                    <tr>
                      { heatmapConfig.map((e,i) => <td key={i} style={{backgroundColor: e.realColor}}>&nbsp;</td>) }
                    </tr>
                    <tr>
                      { heatmapConfig.map((e,i) => <td key={i}><strong>{e.min}</strong></td>) }
                    </tr>
                  </tbody>
                </Table>
                <svg style={{ marginTop: "-50px" }} version="1.1" id="layer_1" x="0px" y="0px" viewBox="0 0 1168.8 841.9" className="tst1" >
                <defs>
                                <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#dadada", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad2" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#e8a4e8", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad3" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#de7cde", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad4" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#cc6ad8", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  

                  <radialGradient id="grad5" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#ae73d8", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad6" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#917dd8", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad7" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#6773e0", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad8" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#3a64ea", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>


                  <radialGradient id="grad9" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#2567f1", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad10" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#57a3ec", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad11" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#88dee7", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad12" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#81e5c7", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>


                  <radialGradient id="grad13" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#5fd19b", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad14" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#3ec070", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad15" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#2fca52", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad16" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#20d535", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>


                  <radialGradient id="grad17" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#2fda32", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad18" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#5cd94b", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad19" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#88d864", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad20" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#b0e141", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>


                  <radialGradient id="grad21" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#d7eb1a", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad22" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#f3e801", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad23" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#f8ca06", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad24" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#fdac0b", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  
                  
                  <radialGradient id="grad25" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#f79709", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad26" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#ed8304", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad27" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#e76c03", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad28" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#f14417", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  

                  <radialGradient id="grad29" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#fa1c2a", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad30" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#ff3a4c", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad31" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#ff8979", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>
                  <radialGradient id="grad32" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" style={{stopColor:"#ffd8a5", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "rgb(255,255,255)", stopOpacity: 0 }} />
                  </radialGradient>

                </defs>
                <circle stroke="transparent" id="circle1" stroke-width="2" r="100" cy="250" cx="440"></circle>
                <circle stroke="transparent" id="circle2" stroke-width="2" r="100" cx="700" cy="600"></circle>
                <circle stroke="transparent" id="circle3" stroke-width="2" r="100" cx="900" cy="520"></circle>
                <circle stroke="transparent" id="circle4" stroke-width="2" r="100" cy="350" cx="445"></circle>
                <circle stroke="transparent" id="circle5" stroke-width="2" r="100" cy="500" cx="620"></circle>
                <circle stroke="transparent" id="circle6" stroke-width="2" r="100" cx="800" cy="480"></circle>
                <circle stroke="transparent" id="circle7" stroke-width="2" r="100" cy="300" cx="530"></circle>
                <circle stroke="transparent" id="circle8" stroke-width="2" r="100" cx="650" cy="400"></circle>
                <g id="landmark-map">
	                <g id="_x30_1" className="mapplic-clickable tst2" >
		                <polygon className="st0 tst2" points="925.7,399.8 925.7,435.9 920.9,475.6 920.9,439.5 		" ></polygon>
		                <polygon className="st0 tst2" points="910.2,459.9 920.9,439.5 920.9,475.6 910.2,495.9 		" ></polygon>
		                <polygon className="st1 tst2" points="854.5,444.1 910.2,459.9 910.2,495.9 854.5,480.1 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="854.9,443.9 872,392.1 925.4,400 920.7,439.5 910.1,459.6 			"></polygon>
			                <path className="st3" d="M872.2,392.4l52.9,7.8l-4.7,39.2l-10.5,19.9l-54.8-15.5L872.2,392.4 M871.9,391.9l-17.3,52.2l55.7,15.8
				                l10.7-20.3l4.8-39.8L871.9,391.9L871.9,391.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 887.5892 428.0572)" className="st4 st5 st6 tst2">1</text>
		                <g className="st7 tst2">
			                <g className="st8">
				                <g>
					                <path className="st4" d="M902.1,404.2l-0.3,0.7l-5.9-2.5l0.2-0.4l7.2-0.9l-0.3,0.7l-2,0.2l-0.6,1.5L902.1,404.2z M897.6,402.3
						                l2.1,0.8l0.4-1.1L897.6,402.3"></path>
				                </g>
				                <g className="st9">
					                <g>
						                <polygon className="st4" points="901.8,405 901.6,405.5 895.7,407.7 900.5,408.3 900.3,408.9 893.7,408 893.9,407.6 899.9,405.4 
							                895,404.8 895.2,404.2 						"></polygon>
					                </g>
					                <g>
						                <path className="st4" d="M899,412.3l-0.3,0.7l-6-2.5l0.2-0.4l7.3-0.9l-0.3,0.7l-2.1,0.2l-0.6,1.6L899,412.3z M894.4,410.4l2.1,0.8
							                l0.4-1.1L894.4,410.4"></path>
					                </g>
					                <g>
						                <path className="st4" d="M897.9,415.1l-0.2,0.5l-6.6-0.8l0.1-0.3l0,0l0,0l0,0c0-0.1,0.1-0.2,0.1-0.3s0.1-0.2,0.1-0.3
							                c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.3,0.3-0.4s0.3-0.2,0.5-0.3s0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.7,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.1,0,0.3,0.1,0.4,0.1s0.2,0.1,0.3,0.1s0.2,0.1,0.2,0.2
							                c0.1,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.4-0.3s0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0
							                c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.4
							                c0,0.2,0,0.3-0.1,0.5l-0.4,0.9L897.9,415.1z M894.9,414.6l2.3,0.3l0.3-0.7l-0.1,0.1c0-0.1,0.1-0.2,0.1-0.3s0-0.2,0-0.3
							                c0-0.1-0.1-0.2-0.2-0.3s-0.3-0.1-0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.5,0-0.7,0
							                s-0.3,0.1-0.4,0.2s-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.3l0,0l0,0.1L894.9,414.6 M892.1,414.2l2,0.3l0.2-0.5
							                c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.4-0.1c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.3,0.1s-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2l0,0L892.1,414.2"></path>
					                </g>
					                <g>
						                <path className="st4" d="M896.6,418.2l-0.2,0.5l-6.6-0.8l0.1-0.3l0,0l0,0l0,0c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3
							                c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.3,0.3-0.4s0.3-0.2,0.5-0.3s0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.7,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.3,0.1,0.4,0.1s0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2
							                c0.1,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0,0.5,0.1,0.7,0.2s0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.4
							                s0,0.3-0.1,0.5l-0.4,0.9L896.6,418.2z M893.6,417.7l2.3,0.3l0.3-0.7l0,0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
							                c0-0.1-0.1-0.2-0.2-0.3s-0.3-0.1-0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.5,0-0.7,0
							                c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.3l0,0l0,0.1L893.6,417.7 M890.8,417.3l2,0.3
							                l0.2-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.4-0.1c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0s0,0,0,0c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0.1s-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2l0,0L890.8,417.3"></path>
					                </g>
					                <g>
						                <path className="st4" d="M895.1,422.2l-0.3,0.7l-6-2.6l0.2-0.4l7.3-0.9l-0.3,0.7l-2.1,0.2l-0.6,1.6L895.1,422.2z M890.5,420.2
							                l2.1,0.9l0.4-1.1L890.5,420.2"></path>
					                </g>
					                <g>
						                <path className="st4" d="M889.9,426.5C889.9,426.5,889.9,426.5,889.9,426.5c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
							                c-0.4-0.1-0.9-0.2-1.2-0.4c-0.3-0.2-0.6-0.4-0.9-0.6c-0.2-0.2-0.4-0.5-0.4-0.8c-0.1-0.3,0-0.6,0.1-0.9l0,0l0,0
							                c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.3-0.3,0.6-0.5c0.2-0.1,0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3l0.2,0.6
							                c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.2,0.3-0.3,0.4
							                c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0.2,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.5c0.3,0.1,0.6,0.2,0.9,0.3c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0.4,0,0.7,0,1.1,0c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.4-0.4,0.5-0.6
							                c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.2-0.5s-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.2-0.8-0.2c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
							                s0,0,0,0l-0.5,1.3l-0.8-0.1l0.5-1.3l0.3-0.7l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0
							                c0.5,0.1,0.9,0.2,1.2,0.3c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.3,0,0.6-0.1,0.9c-0.1,0.4-0.4,0.7-0.7,0.9
							                c-0.3,0.3-0.6,0.5-1,0.6c-0.4,0.2-0.8,0.3-1.3,0.3C890.9,426.6,890.4,426.6,889.9,426.5z"></path>
					                </g>
					                <g>
						                <path className="st4" d="M889.2,428.6L889.2,428.6L889.2,428.6c0-0.1,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1s0.1,0,0.1-0.1
							                c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.2,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1
							                c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1l1-0.3l-0.2-0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0l0.3-0.1l0.2,0.1
							                l0.2,0.1l0,0l0,0l0.8-0.2l-0.1,0.3l-0.5,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
							                c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0.1l0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1
							                c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1s0-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.1,0l0,0
							                l0,0l0,0l0,0l0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s0,0-0.1-0.1s0,0-0.1-0.1c0,0,0,0,0-0.1s0,0,0-0.1
							                C889.1,428.7,889.1,428.7,889.2,428.6C889.1,428.6,889.1,428.6,889.2,428.6L889.2,428.6L889.2,428.6z M891.9,428.5l-1.2,0.4
							                l0,0l0,0l0,0l0,0l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0l0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0
							                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
							                c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1C892,428.6,892,428.6,891.9,428.5C892,428.6,891.9,428.6,891.9,428.5
							                L891.9,428.5L891.9,428.5 M889.5,428.6L889.5,428.6l0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0l0,0l0,0l0,0
							                l0.2,0l0.2-0.1l0.1,0l0.1,0l0,0l0,0l-0.1-0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0l0,0l0,0l0,0l0,0
							                c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0C889.6,428.5,889.5,428.5,889.5,428.6
							                C889.5,428.5,889.5,428.6,889.5,428.6L889.5,428.6L889.5,428.6L889.5,428.6"></path>
					                </g>
					                <g>
						                <polygon className="st4" points="891.7,430.8 891.1,432.3 890.9,433 890.1,432.9 887.8,432.6 887,432.5 885,432.2 884.2,432.1 
							                884.5,431.5 885.1,430 885.8,430.1 885.2,431.6 887.2,431.8 887.8,430.3 888.6,430.4 888,431.9 890.4,432.2 890.9,430.7 
													                "></polygon>
					                </g>
					                <g>
						                <path className="st4" d="M886.1,436.4C886,436.4,886,436.4,886.1,436.4c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0
							                c-0.5-0.1-0.9-0.2-1.2-0.4s-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.4-0.8c-0.1-0.3,0-0.6,0.1-0.9c0-0.1,0.1-0.2,0.2-0.4
							                s0.2-0.2,0.3-0.3s0.2-0.2,0.3-0.3s0.2-0.2,0.4-0.3l1.2,0.2c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3
							                c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0.2,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.5
							                c0.3,0.1,0.6,0.2,0.9,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.4,0,0.7,0,1.1,0c0.4,0,0.7-0.1,1-0.3
							                c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.1-0.3,0.1-0.4s0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3
							                s-0.2-0.2-0.3-0.3l1.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2-0.1,0.4
							                c-0.1,0.3-0.3,0.6-0.6,0.9c-0.3,0.3-0.6,0.5-1.1,0.6c-0.4,0.2-0.9,0.3-1.3,0.4C887,436.5,886.5,436.5,886.1,436.4z"></path>
					                </g>
					                <g>
						                <polygon className="st4" points="889.5,436.5 889.1,437.7 888.9,438 888.8,438.4 882.1,437.5 882.4,436.9 888.3,437.6 
							                888.7,436.4 						"></polygon>
					                </g>
					                <g>
						                <path className="st4" d="M883.3,442.7c-0.4-0.1-0.8-0.2-1.2-0.4c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.4-0.8
							                c-0.1-0.3,0-0.6,0.1-0.9c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.3,0.6-0.5,1-0.6c0.4-0.2,0.8-0.3,1.3-0.4c0.5-0.1,1-0.1,1.4,0
							                c0,0,0,0,0.1,0s0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.4,0.1,0.9,0.2,1.2,0.4c0.3,0.2,0.6,0.4,0.8,0.6
							                c0.2,0.2,0.4,0.5,0.4,0.8c0.1,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.3,0.6-0.6,0.9c-0.3,0.3-0.6,0.5-1,0.6c-0.4,0.2-0.8,0.3-1.3,0.4
							                c-0.5,0.1-1,0.1-1.4,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0C883.4,442.8,883.4,442.8,883.3,442.7C883.4,442.8,883.3,442.8,883.3,442.7
							                z M885.1,438.7c-0.4,0-0.7,0-1.1,0c-0.4,0-0.7,0.1-1,0.3s-0.6,0.3-0.8,0.5c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.1,0.4-0.1,0.7
							                c0,0.2,0.2,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.5c0.3,0.1,0.6,0.2,0.9,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0.4,0,0.7,0,1.1,0c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.2,0.1-0.4,0.1-0.7
							                c0-0.2-0.2-0.4-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.5c-0.3-0.1-0.6-0.2-0.9-0.3c0,0,0,0,0,0C885.3,438.7,885.2,438.7,885.1,438.7
							                C885.2,438.7,885.2,438.7,885.1,438.7C885.2,438.7,885.2,438.7,885.1,438.7"></path>
					                </g>
					                <g>
						                <path className="st4" d="M885.7,446.4l-0.2,0.6l-2.7-0.4l-4-0.5l0.2-0.6l0,0l0.3-0.9c0.1-0.3,0.3-0.5,0.6-0.7
							                c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.2-0.3c0.4,0,0.9,0,1.4,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0s0,0,0.1,0s0,0,0.1,0
							                c0.4,0.1,0.8,0.2,1.1,0.4c0.3,0.2,0.6,0.3,0.8,0.6c0.2,0.2,0.4,0.4,0.4,0.7c0.1,0.2,0.1,0.5,0,0.8L885.7,446.4L885.7,446.4z
							                 M879.8,445.5l5.1,0.7l0.3-0.7c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.1-0.4-0.3-0.6-0.4
							                c-0.2-0.1-0.5-0.2-0.8-0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.3,0-0.6-0.1-0.9,0
							                c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.3-0.5,0.5L879.8,445.5"></path>
					                </g>
				                </g>
			                </g>
		                </g>
	                </g>
	                <g id="_x30_2">
		                <polygon className="st1" points="854.5,444.1 827.3,436.4 827.3,472.5 854.5,480.1 		"></polygon>
		                <g>
			                <polygon className="st2" points="827.7,436.2 843.6,394.3 869.1,399.5 854.4,443.8 			"></polygon>
			                <g>
				                <path className="st3" d="M843.7,394.6l25,5.1l-14.5,43.8L828,436L843.7,394.6 M843.4,394l-16.1,42.4l27.2,7.7l14.8-44.8L843.4,394
					                L843.4,394z"></path>
			                </g>
		                </g>
		                <text transform="matrix(1 0 0 1 845.4577 422.1255)" className="st4 st5 st6">2</text>
	                </g>
	                <g id="_x30_3">
		                <polygon className="st1" points="801,428.9 827.3,436.4 827.3,472.5 801,464.9 		"></polygon>
		                <g>
			                <polygon className="st2" points="801.4,428.7 817.6,389 843.1,394.2 827.2,436.1 			"></polygon>
			                <path className="st3" d="M817.7,389.3l25,5.1L827,435.8l-25.3-7.2L817.7,389.3 M817.4,388.7L801,428.9l26.3,7.5l16.1-42.4
				                L817.4,388.7L817.4,388.7z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 819.3145 415.5483)" className="st4 st5 st6">3</text>
	                </g>
	                <g id="_x30_4">
		                <polygon className="st1" points="775.9,421.7 801,428.9 801,464.9 775.9,457.8 		"></polygon>
		                <g>
			                <polygon className="st2" points="776.3,421.5 791,383.6 817.1,388.9 800.9,428.6 			"></polygon>
			                <path className="st3" d="M791.2,383.8l25.6,5.2l-16,39.2l-24.1-6.9L791.2,383.8 M790.9,383.3l-14.9,38.4l25.1,7.2l16.4-40.2
				                L790.9,383.3L790.9,383.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 793.7793 407.5072)" className="st4 st5 st6">4</text>
	                </g>
	                <g id="_x30_5" className="mapplic-clickable tst2" >
		                <polygon className="st1 tst2" points="750.6,414.5 775.9,421.7 775.9,457.8 750.6,450.6 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="750.9,414.4 764.6,378.1 790.5,383.5 775.8,421.4 			"></polygon>
			                <path className="st3" d="M764.7,378.4l25.5,5.2l-14.5,37.5l-24.4-6.9L764.7,378.4 M764.4,377.9l-13.8,36.7l25.4,7.2l14.9-38.4
				                L764.4,377.9L764.4,377.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 767.8031 401.4671)" className="st4 st5 st6 tst2">5</text>
	                </g>
	                <g id="_x30_6">
		                <polygon className="st1" points="721.7,406.3 750.6,414.5 750.6,450.6 721.7,442.3 		"></polygon>
		                <polygon className="st0" points="721.7,406.3 713.9,401.5 713.9,434.8 721.7,442.3 		"></polygon>
		                <g>
			                <polygon className="st2" points="721.8,406 714.2,401.4 735,371.8 764.1,378 750.4,414.2 			"></polygon>
			                <path className="st3" d="M735.1,372.1l28.6,6.1l-13.5,35.7l-28.3-8.1l-7.4-4.5L735.1,372.1 M734.9,371.6l-21,29.9l7.9,4.8l28.8,8.3
				                l13.8-36.7L734.9,371.6L734.9,371.6z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 738.1998 394.2377)" className="st4 st5 st6">6</text>
	                </g>
	                <g id="_x30_7" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="692.1,387.5 713.9,401.5 713.9,434.8 692.1,419.2 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="692.5,387.9 721.8,363.3 734.5,371.6 713.8,401.1 			"></polygon>
			                <path className="st3" d="M721.8,363.6l12.4,8.1l-20.4,29.1l-20.8-12.9L721.8,363.6 M721.7,363l-29.7,24.9l21.8,13.6l21-29.9
				                L721.7,363L721.7,363z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 712.957 383.267)" className="st4 st5 st6 tst2">7</text>
	                </g>
	                <g id="_x30_8">
		                <polygon className="st0" points="673,376 673,406.1 692.1,419.2 692.1,387.9 		"></polygon>
		                <g>
			                <polygon className="st2" points="673.4,376 707.9,354.2 721.3,363 692.1,387.6 			"></polygon>
			                <path className="st3" d="M707.9,354.5l13,8.5L692,387.3L673.9,376L707.9,354.5 M707.9,353.9L673,376l19.1,11.9l29.7-24.9L707.9,353.9
				                L707.9,353.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 694.4549 373.1508)" className="st4 st5 st6">8</text>
	                </g>
	                <g id="_x30_9" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="655.9,365.5 655.9,393.3 673,406.1 673,376 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="656.4,365.5 694.3,345.3 707.4,353.9 673,375.7 			"></polygon>
			                <path className="st3" d="M694.2,345.6l12.7,8.3l-34,21.5l-16-9.9L694.2,345.6 M694.3,345l-38.3,20.4l17,10.6l34.9-22.1L694.3,345
				                L694.3,345z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 680.6372 363.3236)" className="st4 st5 st6 tst2">9</text>
	                </g>
	                <g id="_x31_0" className="mapplic-clickable tst2">
		                <path className="st0 tst2" d="M639,355.1c0,0,0,26,0,26.1c0,0.1,16.9,12.1,16.9,12.1v-27.8L639,355.1z"></path>
		                <g className="tst2">
			                <polygon className="st2" points="639.6,355.2 679.9,336.1 693.8,345 656,365.2 			"></polygon>
			                <path className="st3" d="M679.9,336.4l13.4,8.6L656,364.9l-15.9-9.7L679.9,336.4 M680,335.8L639,355.1l16.9,10.4l38.3-20.4L680,335.8
				                L680,335.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 659.7762 352.7035)" className="st4 st5 st6 tst2">10</text>
	                </g>
	                <g id="_x31_1">
		                <polygon className="st0" points="621.9,344.3 621.9,369.5 639,381.3 639,355.1 		"></polygon>
		                <g>
			                <polygon className="st2" points="622.1,344.2 663.5,325.3 679.4,335.8 639,354.8 			"></polygon>
			                <path className="st3" d="M663.4,325.5l15.5,10.2l-39.9,18.8l-16.4-10.3L663.4,325.5 M663.5,325l-41.9,19.2l17.4,10.9l40.9-19.3
				                L663.5,325L663.5,325z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 644.9737 342.1007)" className="st4 st5 st6">11</text>
	                </g>
	                <g id="_x31_2">
		                <polygon className="st0" points="602,332.1 602,355.6 621.9,369.5 621.9,344.3 		"></polygon>
		                <g>
			                <polygon className="st2" points="602.5,332.1 646.4,314.5 663,324.9 621.6,343.9 			"></polygon>
			                <path className="st3" d="M646.4,314.8l16.1,10.1l-40.8,18.7l-18.5-11.5L646.4,314.8 M646.4,314.2L602,332.1l19.6,12.1l41.9-19.2
				                L646.4,314.2L646.4,314.2z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 627.3184 332.0669)" className="st4 st5 st6">12</text>
	                </g>
	                <g id="_x31_3" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="580.8,319.1 580.8,340.3 602,355.6 602,332.1 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="581.4,319.1 629.8,303.2 645.9,314.2 602,331.8 			"></polygon>
			                <path className="st3" d="M629.7,303.5l15.6,10.6L602,331.5l-20-12.3L629.7,303.5 M629.8,302.9l-49,16.1l21.2,13l44.4-17.8
				                L629.8,302.9L629.8,302.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 610.7854 320.2426)" className="st4 st5 st6 tst2">13</text>
	                </g>
	                <g id="_x31_4">
		                <path className="st0" d="M570.6,312.9c0.3,0.1,10.2,6.2,10.2,6.2v21.2l-10.2-9.3V312.9z"></path>
		                <polygon className="st1" points="570.6,312.9 560.5,300.3 560.5,318.4 570.6,330.9 		"></polygon>
		                <g>
			                <polygon className="st2" points="570.7,312.7 560.9,300.5 621.3,289.5 629.4,302.8 580.8,318.8 			"></polygon>
			                <path className="st3" d="M621.2,289.8l7.9,12.9l-48.2,15.9l-9.9-6l-9.5-11.8L621.2,289.8 M621.5,289.2l-61,11.1l10.1,12.6l10.2,6.2
				                l49-16.1L621.5,289.2L621.5,289.2z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 591.8151 306.6281)" className="st4 st5 st6">14</text>
	                </g>
	                <g id="_x31_5" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="544,279.2 560.5,300.3 560.5,318.4 544,297.3 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="544.4,279.4 608.4,267.8 621.1,289.1 560.6,300.1 			"></polygon>
			                <path className="st3" d="M608.2,268.1l12.4,20.7l-60,10.9l-15.8-20.2L608.2,268.1 M608.5,267.6L544,279.2l16.5,21.1l61-11.1
				                L608.5,267.6L608.5,267.6z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 578.5874 287.1766)" className="st4 st5 st6 tst2">15</text>
	                </g>
	                <g id="_x31_6" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="516.2,240.5 533.9,265.3 533.9,283.5 529.8,278.2 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="516.7,240.9 532.4,238.2 550.4,261.5 534,265 			"></polygon>
			                <path className="st3" d="M532.3,238.5l17.7,22.9l-15.9,3.3l-17-23.6L532.3,238.5 M532.5,237.9l-16.3,2.8l17.7,24.5l17-3.6
				                L532.5,237.9L532.5,237.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 528.0897 255.6739)" className="st4 st5 st6 tst2">16</text>
	                </g>
	                <g id="_x31_7" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="493.4,225.9 504.3,242.9 504.3,260.9 493.4,244 		"></polygon>
		                <line className="st10 tst2" x1="493.4" y1="225.9" x2="493.4" y2="244" ></line>
		                <g className="tst2">
			                <polygon className="st2" points="493.8,226.1 518.5,220 532.1,237.7 504.4,242.6 			"></polygon>
			                <path className="st3" d="M518.4,220.3l13.3,17.3l-27.1,4.8l-10.3-16.1L518.4,220.3 M518.6,219.7l-25.2,6.2l10.8,16.9l28.3-5
				                L518.6,219.7L518.6,219.7z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 505.6068 234.1985)" className="st4 st5 st6 tst2">17</text>
	                </g>
	                <g id="_x31_8" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="488.2,217.6 493.4,225.9 493.4,244 488.2,235.7 		" ></polygon>
		                <line className="st10 tst2" x1="488.2" y1="217.6" x2="488.2" y2="235.7"></line>
		                <g className="tst2">
			                <polygon className="st2" points="488.4,217.5 494,203.2 506.1,204 518.1,219.6 493.5,225.7 			"></polygon>
			                <path className="st3" d="M494.1,203.4l11.8,0.9l11.7,15.1l-24.1,5.9l-4.9-7.8L494.1,203.4 M493.8,202.9l-5.6,14.7l5.2,8.4l25.2-6.2
				                l-12.3-15.9L493.8,202.9L493.8,202.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 495.5056 218.2365)" className="st4 st5 st6 tst2">18</text>
	                </g>
	                <g id="_x31_9">
		                <polygon className="st0" points="462.8,216.1 488.2,217.6 488.2,235.7 462.8,234.2 		"></polygon>
		                <line className="st10" x1="462.8" y1="216.1" x2="462.8" y2="234.2"></line>
		                <g>
			                <polygon className="st2" points="462.9,215.9 454.9,200.3 493.5,203.1 488,217.3 			"></polygon>
			                <path className="st3" d="M455.4,200.6l37.8,2.8l-5.3,13.7l-24.7-1.4L455.4,200.6 M454.5,200l8.3,16.1l25.4,1.5l5.6-14.7L454.5,200
				                L454.5,200z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 469.6612 213.1503)" className="st4 st5 st6">19</text>
	                </g>
	                <g id="_x32_0">
		                <polygon className="st1" points="357.4,226.2 430.9,214.7 430.9,232.8 361.5,243.6 359.1,240 357.4,239.8 		"></polygon>
		                <polygon className="st0" points="357.4,226.2 313.7,221.6 348.6,239.3 357.4,239.8 		"></polygon>
		                <g>
			                <polygon className="st2" points="293.5,235.3 284.7,215.7 343.3,206.8 396,203.1 421.3,201.8 454.4,200.3 462.4,215.9 430.9,214.5 
				                357.4,225.9 313.5,221.3 306.8,236.2 			"></polygon>
			                <path className="st3" d="M454.2,200.5l7.7,15.1l-31-1.4l0,0l0,0l-73.4,11.4l-43.7-4.5l-0.4,0l-0.1,0.3l-6.6,14.6l-13-0.9l-8.6-19.2
				                l58.2-8.9l52.7-3.7l25.3-1.3L454.2,200.5 M454.5,200l-33.2,1.6l-25.3,1.3l-52.8,3.7l-58.9,9l9,20.1l13.7,0.9l6.7-14.9l43.8,4.6
				                l73.5-11.4l31.9,1.4L454.5,200L454.5,200z"></path>
		                </g>
		                <polygon className="st0" points="430.9,214.7 462.8,216.1 462.8,234.2 430.9,232.8 		"></polygon>
		                <text transform="matrix(1 0 0 1 363.3916 218.2006)" className="st4 st5 st6">20</text>
		                <polygon className="st1" points="284.3,215.5 293.3,235.6 293.3,253.6 284.3,233.6 		"></polygon>
		                <polygon className="st1" points="276.3,196.4 284.3,215.5 284.3,233.6 276.3,214.5 		"></polygon>
	                </g>
	                <g id="_x32_1">
		                <polygon className="st1" points="533.2,220.7 550.9,243.6 550.9,261.7 533.2,238.8 		"></polygon>
		                <polygon className="st0" points="550.9,243.6 596.7,233.7 596.7,251.7 550.9,261.7 		"></polygon>
		                <g>
			                <polygon className="st2" points="533.7,220.9 584.3,213.4 596.3,233.5 551,243.3 			"></polygon>
			                <path className="st3" d="M584.1,213.7l11.8,19.6l-44.8,9.7l-16.9-22L584.1,213.7 M584.4,213.2l-51.2,7.6l17.7,22.9l45.8-10
				                L584.4,213.2L584.4,213.2z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 559.1548 230.7996)" className="st4 st5 st6">21</text>
	                </g>
	                <g id="_x32_2">
		                <polygon className="st1" points="523.5,208.2 533.2,220.7 533.2,238.8 523.5,226.2 		"></polygon>
		                <g>
			                <polygon className="st2" points="523.9,208.3 570.2,200.1 583.8,213 533.3,220.5 			"></polygon>
			                <path className="st3" d="M570.1,200.3l13.2,12.5l-49.9,7.4l-9.1-11.7L570.1,200.3 M570.2,199.8l-46.8,8.3l9.8,12.6l51.2-7.6
				                L570.2,199.8L570.2,199.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 548.1275 214.4195)" className="st4 st5 st6">22</text>
	                </g>
	                <g id="_x32_3">
		                <polygon className="st1" points="523.5,208.2 514.2,195.8 514.2,214.4 523.5,226.2 		"></polygon>
		                <g>
			                <polygon className="st2" points="514.6,196 557.9,189.1 569.7,199.6 523.6,207.9 			"></polygon>
			                <path className="st3" d="M557.8,189.3l11.3,10.1l-45.5,8.1l-8.6-11.4L557.8,189.3 M558,188.8l-43.8,7l9.3,12.3l46.8-8.3L558,188.8
				                L558,188.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 538.1917 200.8084)" className="st4 st5 st6">23</text>
	                </g>
	                <g id="_x32_4">
		                <path className="st1" d="M506.2,203.8v-18.1l7.9,10.1v18.6C514.2,214.4,506.2,203.6,506.2,203.8z"></path>
		                <polygon className="st0" points="486.8,202.4 506.2,203.8 506.2,185.7 486.8,184.3 		"></polygon>
		                <g>
			                <polygon className="st2" points="506.4,185.5 487,184.1 482.7,160.7 483.4,151.7 528,161.5 557.5,188.6 514.3,195.5 			"></polygon>
			                <path className="st3" d="M483.6,152l44.3,9.7l29,26.8l-42.5,6.8l-7.7-9.8l-0.1-0.2l-0.2,0l-19.1-1.4l-4.2-23.1L483.6,152
				                 M483.1,151.4l-0.6,9.3l4.3,23.6l19.4,1.4l7.9,10.1l43.8-7l-29.9-27.6L483.1,151.4L483.1,151.4z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 510.2283 179.0728)" className="st4 st5 st6">24</text>
	                </g>
	                <g id="_x32_5" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="464.3,182.7 486.8,184.3 486.8,202.4 464.3,200.8 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="464.5,182.5 462.7,160.2 482.3,161 486.5,184 			"></polygon>
			                <path className="st3" d="M463,160.5l19.1,0.8l4.1,22.6l-21.4-1.5L463,160.5 M462.4,159.9l1.9,22.8l22.5,1.6l-4.3-23.6L462.4,159.9
				                L462.4,159.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 468.3579 175.5352)" className="st4 st5 st6 tst2">25</text>
	                </g>
	                <g id="_x32_6" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="454.5,200 464.3,200.8 464.3,182.7 454.5,181.9 		"></polygon>
		                <polygon className="st1 tst2" points="421.3,183.5 454.5,181.9 454.5,200 421.3,201.6 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="424.1,158.7 462.2,160.2 464,182.5 454.5,181.7 421.5,183.2 			"></polygon>
			                <path className="st3" d="M424.4,158.9l37.6,1.5l1.8,21.8l-9.2-0.8l0,0l0,0l-32.7,1.5L424.4,158.9 M423.9,158.4l-2.6,25.1l33.2-1.6
				                l9.8,0.8l-1.9-22.8L423.9,158.4L423.9,158.4z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 436.9756 175.5352)" className="st4 st5 st6 tst2">26</text>
	                </g>
	                <g id="_x32_7">
		                <polygon className="st1" points="343.2,206.5 343.2,188.4 396,184.8 396,202.8 		"></polygon>
		                <g>
			                <polygon className="st2" points="328.2,154.9 391.2,157.4 395.7,184.5 343.4,188.2 			"></polygon>
			                <path className="st3" d="M328.6,155.1l62.3,2.5l4.4,26.7l-51.9,3.6L328.6,155.1 M327.8,154.6l15.4,33.8l52.8-3.7l-4.6-27.6
				                L327.8,154.6L327.8,154.6z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 357.9315 173.615)" className="st4 st5 st6">27</text>
	                </g>
	                <g id="_x32_8">
		                <polygon className="st0" points="320.3,210.1 320.3,192 343.2,188.4 343.2,206.5 		"></polygon>
		                <g>
			                <polygon className="st2" points="308.7,163.9 327.7,154.9 342.8,188.2 320.4,191.7 			"></polygon>
			                <path className="st3" d="M327.6,155.3l14.9,32.8l-21.9,3.4l-11.5-27.4L327.6,155.3 M327.8,154.6l-19.4,9.2l11.8,28.2l23-3.6
				                L327.8,154.6L327.8,154.6z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 318.9592 179.0728)" className="st4 st5 st6">28</text>
	                </g>
	                <g id="_x32_9">
		                <polygon className="st0" points="284.3,215.5 284.3,197.4 320.3,192 320.3,210.1 		"></polygon>
		                <polygon className="st1" points="284.3,197.4 276.3,179.1 276.3,196.1 284.3,215.5 		"></polygon>
		                <g>
			                <polygon className="st2" points="276.6,179.2 308.3,164.2 319.9,191.8 284.5,197.1 			"></polygon>
			                <path className="st3" d="M308.2,164.5l11.4,27.1l-34.9,5.3l-7.7-17.5L308.2,164.5 M308.4,163.8l-32.1,15.3l8,18.3l35.9-5.4
				                L308.4,163.8L308.4,163.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 292.0325 186.9002)" className="st4 st5 st6">29</text>
	                </g>
	                <g id="_x33_0" className="mapplic-clickable tst2">
		                <polyline className="st1 tst2" points="293.3,235.6 306.1,263.9 305.7,281.7 293.3,253.6 293.3,235.6 		"></polyline>
		                <g className="tst2">
			                <polygon className="st2" points="293.7,235.8 359,240.2 367.2,252.7 306.3,263.6 			"></polygon>
			                <path className="st3" d="M294.1,236.1l64.8,4.3l8,12.1l-60.4,10.8L294.1,236.1 M293.3,235.6l12.8,28.3l61.5-11l-8.5-13L293.3,235.6
				                L293.3,235.6z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 321.9912 253.6451)" className="st4 st5 st6 tst2">30</text>
	                </g>
	                <g id="_x33_1" className="mapplic-clickable tst2">
		                <polyline className="st1 tst2" points="306.1,263.9 313.7,280.3 313.7,299.6 305.7,281.7 306.1,263.9 		" ></polyline>
		                <g className="tst2">
			                <polygon className="st2" points="306.5,264.1 367.5,253.2 376.2,266.4 313.8,280 			"></polygon>
			                <path className="st3" d="M367.4,253.5l8.4,12.8L314,279.7l-7.1-15.4L367.4,253.5 M367.7,252.9l-61.5,11l7.6,16.4l63-13.7L367.7,252.9
				                L367.7,252.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 331.2645 270.6268)" className="st4 st5 st6 tst2">31</text>
	                </g>
	                <g id="_x33_2" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="313.7,280.3 320.9,297.6 320.9,315.7 313.7,299.6 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="314,280.4 376.5,266.8 386.7,282.2 321.1,297.3 			"></polygon>
			                <path className="st3" d="M376.4,267.1l9.8,14.9l-65,15l-6.8-16.4L376.4,267.1 M376.7,266.5l-63,13.7l7.2,17.3l66.1-15.3L376.7,266.5
				                L376.7,266.5z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 342.8037 287.1765)" className="st4 st5 st6 tst2">32</text>
	                </g>
	                <g id="_x33_3">
		                <polygon className="st1" points="320.9,297.6 328.6,314.7 328.6,332.8 320.9,315.7 		"></polygon>
		                <g>
			                <polygon className="st2" points="321.3,297.8 386.9,282.6 395.6,295.7 328.7,314.4 			"></polygon>
			                <path className="st3" d="M386.8,282.9l8.3,12.7l-66.3,18.6l-7.2-16.2L386.8,282.9 M387.1,282.3l-66.1,15.3l7.7,17.1l67.4-18.9
				                L387.1,282.3L387.1,282.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 348.6174 302.5138)" className="st4 st5 st6">33</text>
	                </g>
	                <g id="_x33_4">
		                <polygon className="st1" points="328.6,314.7 338.2,336.8 338.2,354.9 328.6,332.8 		"></polygon>
		                <polygon className="st0" points="409.1,314.7 409.1,332.8 395.4,337.1 385.2,322.2 		"></polygon>
		                <g>
			                <polygon className="st2" points="328.9,314.9 395.9,296.1 408.7,314.6 338.3,336.5 			"></polygon>
			                <path className="st3" d="M395.8,296.4l12.6,18l-69.8,21.8l-9.2-21.2L395.8,296.4 M396,295.8l-67.4,18.9l9.6,22.1l70.9-22.1L396,295.8
				                L396,295.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 354.4308 320.8474)" className="st4 st5 st6">34</text>
	                </g>
	                <g id="_x33_5">
		                <g>
			                <polygon className="st2" points="439.5,287.2 462.4,272.7 453.9,304.4 			"></polygon>
			                <path className="st3" d="M462,273.3l-8.2,30.6l-13.9-16.6L462,273.3 M462.8,272.2l-23.6,15l14.9,17.7L462.8,272.2L462.8,272.2z"></path>
		                </g>
		                <g>
			                <polygon className="st2" points="464.5,273.1 479,278.4 494.7,294.2 			"></polygon>
			                <path className="st3" d="M466.2,274l12.6,4.6l13.7,13.8L466.2,274 M462.8,272.2l34.1,23.8l-17.7-17.8L462.8,272.2L462.8,272.2z"></path>
		                </g>
		                <polygon className="st0" points="462.8,272.2 496.9,296 454,304.9 		"></polygon>
		                <polygon className="st1" points="439.2,287.2 454,304.9 454,323.5 439.2,305.8 		"></polygon>
		                <text transform="matrix(1 0 0 1 464.5165 294.4728)" className="st4 st5 st6">35</text>
	                </g>
	                <g id="_x33_6" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="454,304.9 466.4,320.8 466.4,339.4 454,323.5 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="454.5,305.1 496.8,296.3 512.1,310.3 466.5,320.6 			"></polygon>
			                <path className="st3" d="M496.7,296.5l14.8,13.7l-45,10.1l-11.6-15.1L496.7,296.5 M496.9,296l-42.8,8.9l12.3,15.9l46.2-10.4
				                L496.9,296L496.9,296z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 477.5215 310.5637)" className="st4 st5 st6 tst2">36</text>
	                </g>
	                <g id="_x33_7" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="466.4,320.8 478.1,335.3 478.1,353.9 466.4,339.4 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="466.8,321 512.5,310.8 527.7,324.7 478.2,335 			"></polygon>
			                <path className="st3" d="M512.4,311l14.7,13.6l-48.9,10.2l-11-13.6L512.4,311 M512.6,310.5l-46.2,10.4l11.7,14.4l50.1-10.4
				                L512.6,310.5L512.6,310.5z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 491.493 324.965)" className="st4 st5 st6 tst2">37</text>
	                </g>
	                <g id="_x33_8">
		                <polygon className="st0" points="478.1,335.3 501.3,348.1 501.3,366.7 478.1,353.9 		"></polygon>
		                <g>
			                <polygon className="st2" points="478.8,335.4 528.1,325.1 544.2,339.9 501.3,347.8 			"></polygon>
			                <path className="st3" d="M528.1,325.4l15.6,14.4l-42.3,7.8l-21.9-12L528.1,325.4 M528.2,324.9l-50.1,10.4l23.2,12.8l43.4-8
				                L528.2,324.9L528.2,324.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 507.1714 340.1047)" className="st4 st5 st6">38</text>
	                </g>
	                <g id="_x33_9_1_">
		                <g>
			                <polygon className="st2" points="524.2,344.1 544.7,340.4 571.5,355.5 556.6,362.1 			"></polygon>
			                <path className="st3" d="M544.6,340.6l26.3,14.9l-14.3,6.4l-31.7-17.6L544.6,340.6 M544.7,340.1l-21.3,3.9l33.1,18.4l15.4-6.9
				                L544.7,340.1L544.7,340.1z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 541.9412 352.7835)" className="st4 st5 st6">39</text>
	                </g>
	                <g id="_x34_0" className="mapplic-clickable tst2">
		                <g className="tst2">
			                <polygon className="st2" points="578.5,378.6 557.1,362.5 572,355.8 597.2,370.4 611.3,378.5 590.4,388.7 			"></polygon>
			                <path className="st3" d="M572,356.1l25,14.5l13.7,7.8l-20.3,9.9l-11.8-10l-21.1-15.9L572,356.1 M572,355.5l-15.4,6.9l21.8,16.4
				                l12,10.1l21.4-10.5l-14.5-8.3L572,355.5L572,355.5z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 579.3764 374.7458)" className="st4 st5 st6 tst2">40</text>
	                </g>
	                <g id="_x34_1">
		                <g>
			                <polygon className="st2" points="590.8,389 611.8,378.8 634.4,391.6 608.5,403.8 			"></polygon>
			                <path className="st3" d="M611.8,379.1l22,12.5l-25.2,11.9l-17.3-14.4L611.8,379.1 M611.8,378.5L590.4,389l18.1,15.1l26.4-12.5
				                L611.8,378.5L611.8,378.5z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 605.9955 394.0013)" className="st4 st5 st6">41</text>
	                </g>
	                <g id="_x34_2" className="mapplic-clickable tst2">
		                <g className="tst2">
			                <polygon className="st2" points="609,404.2 634.9,391.9 653.5,406.7 626.4,419.4 			"></polygon>
			                <path className="st3" d="M634.8,392.2l18.2,14.4l-26.6,12.4l-17.1-14.8L634.8,392.2 M634.9,391.6l-26.4,12.5l17.9,15.6l27.6-12.9
				                L634.9,391.6L634.9,391.6z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 625.4498 409.4141)" className="st4 st5 st6 tst2">42</text>
	                </g>
	                <g id="_x34_3">
		                <g>
			                <polygon className="st2" points="626.9,419.7 654,407.1 678.1,426.5 650.1,439.5 			"></polygon>
			                <path className="st3" d="M653.9,407.4l23.7,19.1l-27.5,12.7l-22.8-19.4L653.9,407.4 M654,406.8l-27.6,12.9l23.6,20.1l28.5-13.2
				                L654,406.8L654,406.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 646.6603 425.861)" className="st4 st5 st6">43</text>
	                </g>
	                <g id="_x34_4">
		                <g>
			                <polygon className="st2" points="650.5,439.8 678.5,426.9 698.6,443.8 670.8,457 			"></polygon>
			                <path className="st3" d="M678.4,427.2l19.7,16.6l-27.3,12.9L651,439.9L678.4,427.2 M678.5,426.6L650,439.8l20.7,17.5l28.3-13.4
				                L678.5,426.6L678.5,426.6z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 668.1526 444.9683)" className="st4 st5 st6">44</text>
	                </g>
	                <g id="_x34_5">
		                <g>
			                <polygon className="st2" points="671.3,457.3 699,444.2 719.2,459.1 705.5,475.2 			"></polygon>
			                <path className="st3" d="M699,444.4l19.9,14.7l-13.4,15.7l-33.6-17.6L699,444.4 M699,443.9l-28.3,13.4l34.8,18.2l14-16.4L699,443.9
				                L699,443.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 691.5546 461.5069)" className="st4 st5 st6">45</text>
	                </g>
	                <g id="_x34_6" className="mapplic-clickable tst2">
		                <g className="tst2">
			                <polygon className="st2" points="706,475.4 719.7,459.4 774.5,484.7 760.7,500.6 			"></polygon>
			                <path className="st3" d="M719.7,459.7l54.3,25.1l-13.5,15.5l-54.2-25L719.7,459.7 M719.6,459.1l-14,16.4l55.1,25.4l14.1-16.3
				                L719.6,459.1L719.6,459.1z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 734.3643 484.5898)" className="st4 st5 st6 tst2">46</text>
	                </g>
	                <g id="_x34_7" className="mapplic-clickable tst2">
		                <g className="tst2">
			                <polygon className="st2" points="778.1,565 813.5,520.9 823.5,525.5 818.1,533.7 801.3,558.4 801.3,576.4 			"></polygon>
			                <path className="st3" d="M813.5,521.2l9.6,4.4l-5.2,8l-16.8,24.6l-0.1,0.1v0.2V576l-22.6-11.1L813.5,521.2 M813.4,520.6l-35.7,44.5
				                l23.8,11.8v-18.4l16.8-24.6l5.5-8.5L813.4,520.6L813.4,520.6z"></path>
		                </g>
		                <g className="tst2">
			                <polygon className="st2" points="818.7,533.7 833.1,511.7 883.1,534.8 890.4,564.6 			"></polygon>
			                <path className="st3" d="M833.2,512.1l49.7,23l7.2,29.2l-71-30.6L833.2,512.1 M833,511.4l-14.7,22.4l72.5,31.2l-7.5-30.4L833,511.4
				                L833,511.4z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 844.9976 537.9924)" className="st4 st5 st6 tst2">47</text>
	                </g>
	                <g id="_x34_8" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="737.3,528 719.6,519.5 719.7,537.9 737.3,545.4 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="720,519.4 742.3,492.7 760.3,501 737.2,527.7 			"></polygon>
			                <path className="st3" d="M742.4,493l17.5,8.1l-22.8,26.3l-16.8-8L742.4,493 M742.2,492.4l-22.6,27.1l17.7,8.5l23.4-27.1L742.2,492.4
				                L742.2,492.4z"></path>
		                </g>
		                <polygon className="st0 tst2" points="746.2,535.1 737.3,545.4 737.3,530.9 		" ></polygon>
		                <text transform="matrix(1 0 0 1 734.4337 511.4168)" className="st4 st5 st6 tst2">48</text>
	                </g>
	                <g id="_x34_9">
		                <polygon className="st1" points="683.9,502.1 719.6,519.5 719.7,537.9 683.9,520.7 		"></polygon>
		                <g>
			                <polygon className="st2" points="684.3,502 705.7,475.8 741.8,492.5 719.5,519.2 			"></polygon>
			                <path className="st3" d="M705.7,476.1l35.7,16.5l-22,26.3l-34.8-17L705.7,476.1 M705.6,475.5l-21.7,26.6l35.7,17.4l22.6-27.1
				                L705.6,475.5L705.6,475.5z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 706.1202 500.9189)" className="st4 st5 st6">49</text>
	                </g>
	                <g id="_x35_0">
		                <polygon className="st0" points="638.6,472.3 657.6,490.1 657.6,508.7 638.7,491.4 		"></polygon>
		                <polygon className="st1" points="657.6,490.1 683.9,502.1 683.9,520.7 657.6,508.7 		"></polygon>
		                <g>
			                <polygon className="st2" points="657.7,489.9 639.1,472.4 670.8,457.5 705.2,475.6 683.8,501.8 			"></polygon>
			                <path className="st3" d="M670.8,457.8l34.1,17.8l-21.1,25.9l-25.8-11.8l-18.4-17.3L670.8,457.8 M670.8,457.3l-32.1,15.1l19,17.8
				                l26.3,12l21.7-26.6L670.8,457.3L670.8,457.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 666.9866 481.2145)" className="st4 st5 st6">50</text>
	                </g>
	                <g id="_x35_1">
		                <polygon className="st0" points="618.7,454.4 638.6,472.3 638.7,491.4 618.7,473 		"></polygon>
		                <g>
			                <polygon className="st2" points="619.2,454.5 650,440 670.3,457.2 638.7,472 			"></polygon>
			                <path className="st3" d="M650,440.3l19.9,16.8l-31.1,14.6l-19.1-17.2L650,440.3 M650,439.8l-31.3,14.7l19.9,17.9l32.1-15.1L650,439.8
				                L650,439.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 639.629 459.6685)" className="st4 st5 st6">51</text>
	                </g>
	                <g id="_x35_2" className="mapplic-clickable tst2">
		                <path className="st0 tst2" d="M618.7,454.4l-22-20.2v18.6l22,20.2C618.7,473,618.7,453.9,618.7,454.4z" ></path>
		                <g className="tst2">
			                <polygon className="st2" points="597.2,434.3 626.4,420 649.6,439.7 618.8,454.1 			"></polygon>
			                <path className="st3" d="M626.3,420.3l22.8,19.4l-30.3,14.2l-21.2-19.5L626.3,420.3 M626.4,419.7l-29.6,14.6l22,20.2l31.3-14.7
				                L626.4,419.7L626.4,419.7z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 617.6038 439.7565)" className="st4 st5 st6 tst2">52</text>
	                </g>
	                <g id="_x35_3" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="579.3,418.1 596.8,434.2 596.8,452.8 579.3,436.7 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="579.7,418.2 608.5,404.4 626,419.6 596.8,433.9 			"></polygon>
			                <path className="st3" d="M608.4,404.7l17.1,14.9l-28.6,14.1l-16.7-15.4L608.4,404.7 M608.5,404.1l-29.2,14l17.5,16.1l29.6-14.6
				                L608.5,404.1L608.5,404.1z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 597.0458 422.1257)" className="st4 st5 st6 tst2">53</text>
	                </g>
	                <g id="_x35_4" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="561.9,402.2 579.3,418.1 579.3,436.7 561.9,420.8 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="562.3,402.2 590.3,389.2 608,404 579.3,417.8 			"></polygon>
			                <path className="st3" d="M590.3,389.5l17.3,14.4l-28.2,13.5l-16.6-15.2L590.3,389.5 M590.4,389l-28.5,13.2l17.4,15.9l29.2-14
				                L590.4,389L590.4,389z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 579.3764 406.7648)" className="st4 st5 st6 tst2">54</text>
	                </g>
	                <g id="_x35_5">
		                <path className="st1" d="M561.9,402.1v18.6L540.6,388c0,0,0.3-19,0.3-18.6C540.8,369.7,561.9,402.1,561.9,402.1z"></path>
		                <g>
			                <polygon className="st2" points="541.2,369.4 556.5,362.7 578.2,379 589.9,388.9 561.9,401.8 			"></polygon>
			                <path className="st3" d="M556.5,363l21.5,16.2l11.4,9.6L562,401.5l-20.5-32L556.5,363 M556.6,362.4l-15.7,6.9l21,32.8l28.5-13.2
				                l-12-10.1L556.6,362.4L556.6,362.4z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 557.8265 386.7562)" className="st4 st5 st6">55</text>
	                </g>
	                <g id="_x35_6_1_">
		                <polygon className="st0" points="501.3,348.1 540.8,369.3 540.6,388 501.3,366.7 		"></polygon>
		                <g>
			                <polygon className="st2" points="502.1,348.2 523.4,344.3 556,362.4 540.9,369 			"></polygon>
			                <path className="st3" d="M523.4,344.5l32.1,17.8l-14.6,6.4l-38.1-20.5L523.4,344.5 M523.5,344l-22.2,4.1l39.5,21.3l15.7-6.9
				                L523.5,344L523.5,344z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 524.0125 359.4061)" className="st4 st5 st6">56</text>
	                </g>
	                <g id="_x35_7_1_" className="mapplic-clickable tst2">
		                <g className="tst2">
			                <polygon className="st2" points="366,361 402.8,348.3 423.6,361.9 400.4,380.5 			"></polygon>
			                <path className="st3" d="M402.7,348.6l20.4,13.3l-22.8,18.3l-33.8-19.2L402.7,348.6 M402.8,348.1l-37.4,12.9l35,19.9l23.6-18.9
				                L402.8,348.1L402.8,348.1z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 391.5308 368.4824)" className="st4 st5 st6 tst2">57</text>
	                </g>
	                <g id="_x35_8_1_">
		                <polygon className="st0" points="342.5,368.8 409.1,408.3 409.1,426.4 342.5,386.9 		"></polygon>
		                <g>
			                <polygon className="st2" points="343.1,368.9 365.4,361.2 400.4,381.1 424,362.2 445.1,376 409.1,408 			"></polygon>
			                <path className="st3" d="M365.3,361.5l34.8,19.8l0.3,0.2l0.3-0.2l23.3-18.7l20.7,13.5l-35.6,31.7l-65.3-38.8L365.3,361.5
				                 M365.4,360.9l-22.9,7.9l66.6,39.5l36.4-32.4l-21.5-14l-23.6,18.9L365.4,360.9L365.4,360.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 406.9249 389.8892)" className="st4 st5 st6">58</text>
	                </g>
	                <g id="_x35_9_1_" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="409.1,408.3 431.5,421.8 431.5,439.9 409.1,426.4 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="409.5,408.3 445.5,376.2 466.5,389.9 431.5,421.5 			"></polygon>
			                <path className="st3" d="M445.6,376.5L466,390l-34.6,31.3l-21.5-13L445.6,376.5 M445.5,375.9l-36.4,32.4l22.4,13.5l35.3-31.9
				                L445.5,375.9L445.5,375.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 432.1906 404.0796)" className="st4 st5 st6 tst2">59</text>
	                </g>
	                <g id="_x36_0_1_">
		                <polygon className="st0" points="431.5,421.8 453,434.6 453,452.7 431.5,439.9 		"></polygon>
		                <g>
			                <polygon className="st2" points="432,421.8 466.9,390.2 487,403.3 453,434.3 			"></polygon>
			                <path className="st3" d="M466.9,390.5l19.7,12.8L453,434l-20.6-12.3L466.9,390.5 M466.9,389.9l-35.3,31.9l21.5,12.8l34.4-31.3
				                L466.9,389.9L466.9,389.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 451.8678 419.4898)" className="st4 st5 st6">60</text>
	                </g>
	                <g id="_x36_1_1_" className="mapplic-clickable tst2">
		                <polygon className="st0 tst2" points="453,434.6 473.3,446.7 473.3,464.8 453,452.7 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="453.4,434.6 487.4,403.6 506.8,416.2 473.2,446.4 			"></polygon>
			                <path className="st3" d="M487.4,403.9l18.9,12.3l-33.1,29.8l-19.4-11.5L487.4,403.9 M487.4,403.3L453,434.6l20.3,12.1l33.9-30.5
				                L487.4,403.3L487.4,403.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 473.0995 429.5093)" className="st4 st5 st6 tst2">61</text>
	                </g>
	                <g id="_x36_2_1_">
		                <polygon className="st1" points="473.3,446.7 513.2,473.7 513.2,491.3 473.3,464.8 		"></polygon>
		                <g>
			                <polygon className="st2" points="473.7,446.7 507.2,416.5 541.5,444.2 513.1,473.4 			"></polygon>
			                <path className="st3" d="M507.2,416.9l33.9,27.3l-28,28.9l-39-26.4L507.2,416.9 M507.2,416.2l-33.9,30.5l39.9,27l28.7-29.6
				                L507.2,416.2L507.2,416.2z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 501.7775 446.696)" className="st4 st5 st6">62</text>
	                </g>
	                <g id="_x36_3_1_" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="513.2,473.7 535.2,489 535.2,507.1 513.2,491.3 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="513.5,473.7 541.9,444.5 562.8,461.5 535.2,488.7 			"></polygon>
			                <path className="st3" d="M541.9,444.8l20.5,16.7l-27.3,26.8l-21.3-14.7L541.9,444.8 M541.9,444.1l-28.7,29.6l22.1,15.3l28-27.5
				                L541.9,444.1L541.9,444.1z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 530.8599 471.1868)" className="st4 st5 st6 tst2">63</text>
	                </g>
	                <g id="_x36_4_1_" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="535.2,489 563.2,508 563.2,516.5 557.8,522.4 535.2,507.1 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="535.9,489.1 582,482.5 563.1,507.7 			"></polygon>
			                <path className="st3" d="M581.4,482.8l-18.3,24.5l-26.5-18.1L581.4,482.8 M582.5,482.2l-47.3,6.8l28,19L582.5,482.2L582.5,482.2z"></path>
		                </g>
		                <g className="tst2">
			                <polygon className="st2" points="582.6,482 564.8,462.9 599.8,490.5 			"></polygon>
			                <path className="st3" d="M567.3,465.1l30.5,24.1l-14.9-7.4L567.3,465.1 M562.4,460.6l20.1,21.5l19.3,9.6L562.4,460.6L562.4,460.6z"></path>
		                </g>
		                <g className="tst2">
			                <polygon className="st2" points="563.2,461.9 582,482 536,488.6 			"></polygon>
			                <path className="st3" d="M563.2,462.2l18.3,19.6l-44.8,6.4L563.2,462.2 M563.2,461.5l-28,27.5l47.3-6.8L563.2,461.5L563.2,461.5z"></path>
		                </g>
		                <polygon className="st11 tst2" points="582.5,482.2 563.2,508 563.2,526.1 579.3,519.3 579.9,520 585.5,517.6 586.1,518.2 591.1,515.9 
			                591.7,516.6 597,514.2 597.6,514.9 601.9,512.8 601.9,491.8 		"></polygon>
		                <text transform="matrix(1 0 0 1 557.4166 480.5339)" className="st4 st5 st6 tst2">64</text>
	                </g>
	                <g id="_x36_5_1_">
		                <polygon className="st1" points="498.3,580.6 522.1,594.9 522.1,613 498.3,598.7 		"></polygon>
		                <g>
			                <polygon className="st2" points="498.7,580.6 536.9,541.7 559.8,556.1 522.1,594.6 			"></polygon>
			                <path className="st3" d="M536.9,542l22.5,14.2l-37.4,38.1l-23-13.8L536.9,542 M536.8,541.3l-38.6,39.3l23.9,14.3l38.1-38.9
				                L536.8,541.3L536.8,541.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 524.9419 571.0432)" className="st4 st5 st6">65</text>
	                </g>
	                <g id="_x36_6_1_">
		                <polygon className="st1" points="522.1,594.9 545.1,608.7 545.1,626.7 522.1,613 		"></polygon>
		                <g>
			                <polygon className="st2" points="522.5,594.9 560.3,556.4 583.4,571 545,608.3 			"></polygon>
			                <path className="st3" d="M560.3,556.7L583,571l-38,37l-22-13.2L560.3,556.7 M560.2,556.1l-38.1,38.9l22.9,13.7l38.8-37.7L560.2,556.1
				                L560.2,556.1z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 546.1458 585.0237)" className="st4 st5 st6">66</text>
	                </g>
	                <g id="_x36_7_1_">
		                <polygon className="st1" points="545.1,608.7 567.8,622.5 567.8,640.5 545.1,626.7 		"></polygon>
		                <g>
			                <polygon className="st2" points="545.5,608.6 583.9,571.3 604,584 567.8,622.1 			"></polygon>
			                <path className="st3" d="M583.9,571.6l19.7,12.4l-35.9,37.8l-21.9-13.3L583.9,571.6 M583.8,571l-38.8,37.7l22.8,13.8l36.6-38.5
				                L583.8,571L583.8,571z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 568.9415 598.4085)" className="st4 st5 st6">67</text>
	                </g>
	                <g id="_x36_8_1_">
		                <polygon className="st1" points="567.8,622.5 591.9,636.9 591.9,655 567.8,640.5 		"></polygon>
		                <g>
			                <polygon className="st2" points="568.2,622.4 604.5,584.3 627,598.5 591.9,636.6 			"></polygon>
			                <path className="st3" d="M604.5,584.6l22.1,13.9l-34.8,37.8l-23.2-13.9L604.5,584.6 M604.4,583.9l-36.6,38.5l24.1,14.4l35.5-38.5
				                L604.4,583.9L604.4,583.9z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 591.8146 613.0233)" className="st4 st5 st6">68</text>
	                </g>
	                <g id="_x36_9_1_" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="591.9,636.9 615.2,651 615.2,669.1 591.9,655 		" ></polygon>
		                <g className="tst2">
			                <path className="st2" d="M592.3,636.9l35.1-38.1c9.3,4.1,23.1,10.4,24.4,11.6c-2.3,2.5-34.4,37.8-36.8,40.4L592.3,636.9z"></path>
			                <path className="st3" d="M627.5,599c8.6,3.8,21.8,9.9,24,11.3c-3.4,3.7-33.2,36.5-36.4,40l-22.3-13.6L627.5,599 M627.4,598.4
				                l-35.5,38.5l23.2,14.1c0,0,36.4-40,37-40.7S627.4,598.4,627.4,598.4L627.4,598.4z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 616.2696 627.0583)" className="st4 st5 st6 tst2">69</text>
	                </g>
	                <g id="_x37_0_1_">
		                <polygon className="st1" points="615.2,651 639.1,665.4 639.1,683.5 615.2,669.1 		"></polygon>
		                <g>
			                <polygon className="st2" points="615.5,650.9 652.2,610.6 677.6,622.8 639.1,665.1 			"></polygon>
			                <path className="st3" d="M652.3,610.9l24.9,12L639,664.8l-23.1-13.9L652.3,610.9 M652.2,610.3l-37,40.7l24,14.4l38.9-42.6
				                L652.2,610.3L652.2,610.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 639.6293 640.5395)" className="st4 st5 st6">70</text>
	                </g>
	                <g id="_x37_1_1_">
		                <polygon className="st1" points="639.1,665.4 663.3,680 663.3,698 639.1,683.5 		"></polygon>
		                <g>
			                <polygon className="st2" points="639.5,665.3 678.1,623.1 699.4,639.2 663.3,679.6 			"></polygon>
			                <path className="st3" d="M678.1,623.4l20.9,15.8l-35.8,40.1l-23.3-14L678.1,623.4 M678.1,622.8l-38.9,42.6l24.2,14.6l36.4-40.8
				                L678.1,622.8L678.1,622.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 662.8384 655.6805)" className="st4 st5 st6">71</text>
	                </g>
	                <g id="_x37_2_1_" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="663.3,680 688.1,694.9 688.1,713 663.3,698 	"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="663.7,679.9 699.8,639.5 721.3,655.7 688.1,694.6 			"></polygon>
			                <path className="st3" d="M699.8,639.8l21.1,15.9L688,694.3l-23.9-14.4L699.8,639.8 M699.8,639.1L663.3,680l24.8,14.9l33.5-39.2
				                L699.8,639.1L699.8,639.1z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 685.5835 670.9595)" className="st4 st5 st6 tst2">72</text>
	                </g>
	                <g id="_x37_3_1_" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="688.1,694.9 732.5,721.7 732.5,739.8 688.1,713 		"></polygon>
		                <polygon className="st0 tst2" points="732.5,721.7 741.8,716.9 741.8,735 732.5,739.8 		" ></polygon>
		                <polygon className="st0 tst2" points="745.5,673 745.5,691.1 741.8,735 741.8,716.9 		" ></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="688.5,694.8 721.7,656 745.2,673.1 741.6,716.7 732.5,721.4 			"></polygon>
			                <path className="st3" d="M721.8,656.4l23.2,16.9l-3.6,43.3l-8.8,4.6l-43.6-26.3L721.8,656.4 M721.7,655.7l-33.5,39.2l44.4,26.8
				                l9.3-4.8l3.6-43.9L721.7,655.7L721.7,655.7z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 717.0397 691.4644)" className="st4 st5 st6 tst2">73</text>
	                </g>
	                <g id="_x37_4_1_">
		                <g>
			                <polygon className="st2" points="764.7,663.3 763,627.4 802.2,638.5 870.5,672.1 844.1,706.1 			"></polygon>
			                <path className="st3" d="M763.2,627.7l38.9,11l68,33.5L844,705.8l-79.1-42.7L763.2,627.7 M762.7,627.1l1.7,36.3l79.7,43l26.7-34.4
				                l-68.6-33.8L762.7,627.1L762.7,627.1z"></path>
		                </g>
		                <polygon className="st0" points="762.7,627.1 762.7,645.3 764.5,681.7 764.5,663.4 		"></polygon>
		                <polygon className="st1" points="764.5,663.4 844.1,706.4 844.1,725.2 764.5,681.7 		"></polygon>
		                <text transform="matrix(1 0 0 1 809.9161 670.9595)" className="st4 st5 st6">74</text>
		                <polygon className="st0" points="870.9,690.8 870.9,672 844.1,706.4 844.1,725.2 		"></polygon>
	                </g>
	                <g id="_x37_5_1_">
		                <g>
			                <polygon className="st2" points="802.7,638.2 818.4,621.2 885.3,653.1 870.8,671.7 			"></polygon>
			                <path className="st3" d="M818.5,621.5l66.4,31.7l-14.2,18.2l-67.6-33.3L818.5,621.5 M818.3,620.9l-16.1,17.4l68.6,33.8l14.8-19
				                L818.3,620.9L818.3,620.9z"></path>
		                </g>
		                <polygon className="st0" points="885.7,653 885.7,671.3 870.9,690.8 870.9,672 		"></polygon>
		                <text transform="matrix(1 0 0 1 835.7202 649.6276)" className="st4 st5 st6">75</text>
	                </g>
	                <g id="_x37_6_1_">
		                <g>
			                <polygon className="st2" points="818.8,620.8 834.2,604.1 898.6,635.9 885.6,652.7 			"></polygon>
			                <path className="st3" d="M834.2,604.4l64,31.6l-12.7,16.3l-66.4-31.6L834.2,604.4 M834.1,603.8l-15.8,17.1l67.3,32.1l13.3-17.1
				                L834.1,603.8L834.1,603.8z"></path>
		                </g>
		                <polygon className="st0" points="899,635.8 899,654.1 885.7,671.3 885.7,653 		"></polygon>
		                <text transform="matrix(1 0 0 1 851.702 631.4966)" className="st4 st5 st6">76</text>
	                </g>
	                <g id="_x37_7_1_">
		                <g>
			                <polygon className="st2" points="834.5,603.7 855.3,581.3 919.6,609 898.9,635.5 			"></polygon>
			                <path className="st3" d="M855.4,581.6l63.8,27.5l-20.3,26.1L835,603.7L855.4,581.6 M855.2,581l-21.1,22.8l64.9,32l21-27L855.2,581
				                L855.2,581z"></path>
		                </g>
		                <polygon className="st0" points="920,608.9 920,627.3 899,654.1 899,635.8 		"></polygon>
		                <text transform="matrix(1 0 0 1 870.1097 610.3269)" className="st4 st5 st6">77</text>
	                </g>
	                <g id="_x37_8_1_">
		                <polygon className="st0" points="822.2,567.2 801.6,558.5 801.6,576.8 822.2,585.6 842.8,594.4 855.2,581 		"></polygon>
		                <g>
			                <polygon className="st2" points="802,558.3 818.4,534.1 890.6,565.3 899.1,599.5 			"></polygon>
			                <path className="st3" d="M818.5,534.5l71.8,31l8.3,33.7l-96.4-40.9L818.5,534.5 M818.3,533.8l-16.8,24.6l97.8,41.5l-8.6-34.9
				                L818.3,533.8L818.3,533.8z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 834.7032 559.9352)" className="st4 st5 st6">78</text>
	                </g>
	                <g id="_x37_9_1_">
		                <polygon className="st1" points="921.1,549.2 950.2,594 950.2,630.1 921.1,585.3 		"></polygon>
		                <polygon className="st0" points="1031.2,574.1 1031.2,610.2 950.2,630.1 950.2,594 		"></polygon>
		                <g>
			                <polygon className="st2" points="921.5,549.4 951.8,541.6 960,557.8 1030.1,574.1 950.4,593.8 			"></polygon>
			                <path className="st3" d="M951.7,541.9l8.1,15.9l0.1,0.2l0.2,0.1l69,16.1l-78.6,19.3l-28.6-43.9L951.7,541.9 M951.9,541.3l-30.9,7.9
				                l29.2,44.8l81-19.9l-71-16.6L951.9,541.3L951.9,541.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 952.7618 576.8475)" className="st4 st5 st6">79</text>
	                </g>
	                <g id="_x38_0_1_">
		                <polygon className="st1" points="1038.7,543.9 1038.7,579.9 1031.2,610.2 1031.2,574.1 		"></polygon>
		                <g>
			                <polygon className="st2" points="960.4,557.3 952.2,541.3 958.7,524.6 1038.4,544 1031,573.8 			"></polygon>
			                <path className="st3" d="M958.9,524.9l79.3,19.4l-7.3,29.3l-70.3-16.4l-8.1-15.8L958.9,524.9 M958.6,524.3l-6.6,17l8.3,16.2l71,16.6
				                l7.5-30.3L958.6,524.3L958.6,524.3z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 984.9318 551.8782)" className="st4 st5 st6">80</text>
	                </g>
	                <g id="_x38_1" className="mapplic-clickable tst2">
		                <polygon className="st1 tst2" points="1043.8,523.8 1043.8,559.9 1038.7,579.9 1038.7,543.9 		"></polygon>
		                <g className="tst2">
			                <polygon className="st2" points="958.9,524.1 969.3,495.5 1013.3,506.6 1011,516.9 1043.5,524 1038.6,543.6 			"></polygon>
			                <path className="st3" d="M969.5,495.8l43.6,11l-2.2,9.8l-0.1,0.5l0.5,0.1l32,7l-4.8,19.1l-79.2-19.3L969.5,495.8 M969.2,495.2
				                l-10.6,29.1l80.2,19.6l5.1-20l-32.5-7.1l2.3-10.3L969.2,495.2L969.2,495.2z"></path>
		                </g>
		                <text transform="matrix(1 0 0 1 989.5402 523.8193)" className="st4 st5 st6 tst2">81</text>
	                </g>
                </g>
                <g id="nopointer-elements">
	                <g id="strade">
		                <g className="st12">
			                <g>
				                <path className="st13" d="M1342.4,404.5c-4.8,5.7-17.1,20.4-33.1,39.7c-16.3,19.7-36.6,44.5-56.9,69.6c-20.8,25.8-41.6,52-57.9,73.3
					                c-16.6,21.7-28.3,38.1-30.2,43.4c-1.7,4.7-3.7,10.8-5.8,17.6c-2.2,6.9-4.5,14.7-6.9,22.6c-2.4,8-4.7,16.2-6.9,23.9
					                c-2.2,7.8-4.3,15.1-6,21.2c-0.3,1.1-0.5,2.2-0.6,3.4c-0.1,1.1-0.2,2.3-0.1,3.5c0,1.2,0.2,2.4,0.4,3.6c0.2,1.2,0.5,2.4,0.9,3.6
					                c0.5,1.7,0.9,3.4,1.3,5.1c0.3,1.7,0.5,3.4,0.7,5.2c0.1,1.7,0.1,3.5,0,5.2c-0.1,1.7-0.3,3.5-0.6,5.2c-0.9,4.9-2.7,9.4-5.2,13.5
					                c-2.5,4.1-5.8,7.7-9.6,10.8c-3.9,3.1-8.3,5.7-13.2,7.8c-4.9,2-10.3,3.5-16.1,4.3c0,0-1.7,0.6-4.5,1.7c-2.8,1.1-6.6,2.8-10.8,4.9
					                c-4.2,2.2-8.8,4.8-13.2,7.9c-4.4,3.1-8.5,6.7-11.6,10.7c-3.2,4-10.6,15.8-20.1,31.6c-9.7,16-21.5,36.1-33.3,56.2
					                c-12,20.5-23.8,41-33.1,57.1c-9.4,16.3-16,27.8-17.2,29.9L914,961.1l98.8-155.4c0.1-0.2,0.8-1.3,1.7-3.1
					                c0.9-1.8,2.1-4.2,3.1-7.2c1.1-3,2-6.5,2.6-10.3c0.6-3.8,0.8-7.9,0.3-12.2c-0.3-2.5-0.7-5-1.2-7.3c-0.5-2.3-1.1-4.5-1.7-6.6
					                c-0.6-2-1.3-3.9-2-5.6c-0.7-1.7-1.3-3.2-1.9-4.5c-0.3-0.5-0.5-0.9-0.8-1.4c-0.2-0.5-0.5-0.9-0.7-1.4c-0.2-0.5-0.5-0.9-0.7-1.4
					                c-0.2-0.5-0.4-0.9-0.6-1.4c-1-2.3-1.9-4.7-2.5-7.1c-0.7-2.4-1.1-4.8-1.4-7.2c-0.3-2.4-0.3-4.8-0.2-7.2c0.2-2.4,0.5-4.8,1.1-7.1
					                c1.1-4.4,2.9-8.4,5.2-12.1c2.4-3.6,5.3-6.9,8.8-9.8c3.4-2.8,7.3-5.2,11.6-7.2c4.3-2,9-3.4,13.9-4.4c1.1-0.2,2.1-0.5,3.1-0.8
					                c1-0.3,2-0.7,2.9-1.1c0.9-0.4,1.8-0.9,2.6-1.4c0.8-0.5,1.6-1.1,2.3-1.7c4.3-3.9,9.2-8.3,14.3-13.2c5-4.9,10.1-10.1,15-15.4
					                c4.8-5.3,9.3-10.8,13-16.1c3.7-5.3,6.7-10.4,8.6-15.2l1.4-3.6c1.4-3.5,2.7-7.1,4.1-10.7c1.3-3.6,2.6-7.2,3.8-11
					                c1.2-3.7,2.2-7.5,3.1-11.3c0.9-3.8,1.6-7.7,2-11.7c0.5-4.6,0.7-9.3,0.5-14c-0.2-4.7-0.7-9.6-1.8-14.5c-1-4.9-2.5-9.9-4.6-15.1
					                c-2-5.1-4.6-10.4-7.8-15.8c-4.6-7.7-9.7-16-15.1-24.3c-5.3-8.2-10.9-16.5-16.3-24.4c-5.4-7.8-10.6-15.3-15.4-22
					                c-4.7-6.7-9-12.6-12.5-17.5c-2.5-3.4-5.4-6.8-8.6-9.9c-3.2-3.1-6.7-6.1-10.6-8.8c-3.8-2.8-7.9-5.3-12.3-7.6
					                c-4.3-2.3-8.9-4.4-13.7-6.2c-6.5-2.5-12-4.6-16.8-6.5c-4.7-1.9-8.6-3.5-11.9-4.8c-3.2-1.3-5.8-2.4-7.7-3.3c-2-0.9-3.4-1.5-4.3-2
					                c-2.3-0.8-4.4-1.7-6.5-2.7c-2.1-1-4.1-2-5.9-3.2c-1.9-1.1-3.7-2.3-5.3-3.6c-1.7-1.2-3.2-2.5-4.7-3.9c-0.7-0.6-1.4-1.3-2.2-1.9
					                c-0.8-0.6-1.5-1.2-2.3-1.7c-0.8-0.6-1.7-1.1-2.5-1.6c-0.9-0.5-1.8-1-2.7-1.5c-1.7-0.8-3.7-1.8-5.9-2.8c-2.2-1-4.6-2-7.1-3
					                c-2.5-1-5.1-1.9-7.8-2.8c-2.7-0.9-5.4-1.6-8.1-2.3c-1.9-0.4-4.3-0.8-7.2-1.1c-2.9-0.3-6.2-0.6-9.8-0.9
					                c-3.6-0.3-7.6-0.5-11.7-0.7c-4.1-0.2-8.5-0.4-12.9-0.6c-6.4-0.3-13.1-0.6-19.8-1c-6.7-0.4-13.5-0.8-20.3-1.4
					                c-6.8-0.6-13.5-1.2-20.1-2c-6.6-0.8-13-1.8-19.2-3c-1.5-0.3-3.1-0.6-4.7-0.9c-1.6-0.3-3.2-0.6-4.8-1c-1.6-0.3-3.3-0.7-4.9-1
					                c-1.7-0.4-3.3-0.7-5-1.1c-3.8-0.9-7.7-1.8-11.6-2.9c-3.9-1-7.9-2.2-11.9-3.4c-4-1.2-8.1-2.6-12.1-4c-4.1-1.5-8.1-3-12.2-4.8
					                c-6.4-2.7-12.5-5.6-18.4-8.8c-5.9-3.2-11.5-6.6-17-10.2c-5.4-3.7-10.7-7.6-15.7-11.7c-5-4.1-9.8-8.5-14.5-13.2
					                c-7.2-7.2-13.7-14.7-19.8-22.3c-6-7.5-11.4-15-16.2-22.3c-4.8-7.2-9.1-14.1-12.9-20.4c-3.7-6.3-7-12-9.7-16.9
					                c-0.9-1.5-1.7-3-2.5-4.4c-0.8-1.4-1.6-2.7-2.3-3.9c-0.7-1.2-1.3-2.2-1.9-3.1c-0.6-0.9-1-1.5-1.4-2c-0.3-0.3-0.5-0.6-0.8-1
					                c-0.3-0.3-0.5-0.6-0.8-1c-0.3-0.3-0.5-0.6-0.7-0.9c-0.2-0.3-0.5-0.6-0.7-0.9c-1.2-1.5-2.4-3-3.6-4.4c-1.2-1.4-2.5-2.7-4.1-4
					                c-1.5-1.3-3.3-2.5-5.3-3.6c-2.1-1.2-4.5-2.3-7.5-3.3c-0.9-0.3-1.9-0.7-2.7-1c-0.9-0.3-1.8-0.7-2.6-1c-0.9-0.3-1.7-0.7-2.5-1
					                c-0.8-0.3-1.6-0.7-2.4-1c-0.7-0.3-1.5-0.6-2.3-1c-0.8-0.3-1.5-0.6-2.3-0.9c-0.8-0.3-1.6-0.6-2.3-0.9c-0.8-0.3-1.6-0.6-2.4-0.8
					                c-1.3-0.5-2.5-0.9-3.7-1.4c-1.2-0.5-2.3-1.1-3.4-1.6c-1.1-0.6-2.1-1.2-3.1-1.8c-1-0.6-1.9-1.3-2.8-1.9c-0.6-0.4-1.2-0.9-1.9-1.3
					                c-0.7-0.4-1.4-0.8-2.1-1.1c-0.7-0.3-1.5-0.7-2.3-1c-0.8-0.3-1.6-0.6-2.5-0.8c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.8-0.2-1.2-0.3
					                c-0.4-0.1-0.8-0.2-1.3-0.3c-0.4-0.1-0.9-0.2-1.4-0.3c-0.5-0.1-1-0.2-1.5-0.3c-0.5-0.1-1-0.2-1.6-0.3c-0.5-0.1-1.1-0.2-1.7-0.3
					                c-0.6-0.1-1.2-0.2-1.8-0.3c-4.3-0.5-30.9-2.4-70.4-4.9c-39.4-2.6-91.7-5.8-147.9-8.9c-56-3.1-115.9-6-170.9-8
					                c-54.9-2-104.9-3.2-141.7-2.6l2.6-8.4l2.9-13.6c34.2-0.5,82.2,0.5,135.4,2.2c53.3,1.7,111.9,4.2,167,6.7
					                c55.3,2.5,107.1,4.9,146.3,6.6c39.4,1.7,66.1,2.5,71.1,1.8c2.1-0.6,4.4-1.4,6.7-2.2c2.3-0.9,4.5-1.8,6.7-3
					                c2.1-1.1,4.2-2.4,5.9-3.8c1.8-1.4,3.3-2.9,4.4-4.6c0-0.5-0.1-1.6-0.8-3.6c-0.7-2-2-5-4.2-9.4c-2.2-4.4-5.4-10.1-10-17.6
					                c-4.5-7.4-10.4-16.6-18-27.8c-4.2-6.2-8.4-12.3-12.3-17.9c-3.9-5.6-7.5-10.7-10.7-15c-3.1-4.3-5.7-7.9-7.5-10.4
					                c-1.8-2.5-2.9-3.9-2.9-4l3.9-1c-0.2-0.3-0.4-0.6-0.6-0.9c-0.2-0.3-0.4-0.5-0.5-0.7c-0.1-0.2-0.3-0.4-0.4-0.5
					                c-0.1-0.1-0.2-0.2-0.2-0.3L481-25c0.2,0.2,4,5.6,9.9,13.7C496.7-3,504.7,8.1,513,19.6c8.4,11.6,17,23.7,24,33.4
					                c7.1,9.8,12.4,17.2,14,19.3c0.8,1.1,1.5,2,2.1,2.8c0.6,0.8,1.2,1.4,1.7,2c0.5,0.5,1,1,1.5,1.3c0.5,0.4,1,0.7,1.6,0.9
					                c0.6,0.3,1.2,0.5,2,0.8c0.8,0.3,1.7,0.5,2.7,0.8c1,0.3,2.2,0.6,3.5,0.9c1.3,0.3,2.8,0.6,4.5,1c0.6,0.1,1.3,0.3,1.9,0.4
					                c0.7,0.1,1.4,0.3,2.1,0.4c0.7,0.1,1.5,0.3,2.2,0.4c0.8,0.2,1.6,0.3,2.4,0.5c0.9,0.2,2.3,0.4,4,0.5c1.7,0.1,3.7,0,5.9-0.5
					                c2.2-0.5,4.5-1.5,6.9-3.1c2.4-1.6,4.7-4,7-7.2c0.3-0.4,0.5-0.7,0.7-1.1c0.2-0.4,0.5-0.7,0.7-1c0.2-0.3,0.4-0.7,0.7-1
					                c0.2-0.3,0.4-0.6,0.6-0.9l-19.7,1.5l-28.2-8.9L481.6-57.3L624.2-30l92.5,73.1l-85.5,25c-0.3,0.6-0.7,1.3-1.2,2.1
					                c-0.4,0.8-0.9,1.6-1.5,2.5c-0.6,0.9-1.2,1.9-1.9,3c-0.7,1.1-1.4,2.2-2.3,3.5c-1.5,2.3-3.3,4.4-5.2,6.4c-1.9,1.9-4.1,3.7-6.4,5.2
					                c-2.3,1.5-4.8,2.8-7.4,3.9c-2.6,1.1-5.3,2-8.2,2.6c3.2,1.7,6,3.5,8.3,5.6c2.4,2.1,4.3,4.3,5.7,6.7c1.4,2.4,2.3,4.8,2.7,7.4
					                c0.4,2.5,0.2,5.2-0.6,7.8c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.4-0.1,0.8-0.1,1.1c0,0.4,0,0.8,0,1.1c0,0.4,0,0.8,0.1,1.2
					                c0.4,3.8,0.9,8.1,1.6,12.5c0.7,4.5,1.4,9.1,2.3,13.6c0.9,4.5,2,8.9,3.1,12.8c1.2,3.9,2.5,7.4,4,10c2.6,4.6,5.5,10,8.9,15.8
					                c3.4,5.9,7.2,12.2,11.4,18.7c4.2,6.6,8.9,13.3,13.9,19.9c5.1,6.7,10.5,13.3,16.3,19.4c2.4,2.6,5,5.1,7.8,7.5
					                c2.7,2.4,5.6,4.8,8.7,7.1c3,2.3,6.2,4.5,9.5,6.7c3.3,2.2,6.8,4.2,10.3,6.2l48.2-6.3l16.5-3l-13.3-12c1.3-0.5,8.1-3.4,17.9-7.5
					                c9.7-4,22.3-9.2,35-14.2c12.6-5,25.4-10,35.7-13.7c10.2-3.7,18.1-6.2,20.9-6.3c0.6,0,1.2,0,1.7,0c0.6,0,1.2,0.1,1.8,0.1
					                c0.6,0.1,1.2,0.1,1.8,0.2c0.6,0.1,1.2,0.2,1.8,0.3c2.4,0.5,4.8,1.3,7.1,2.1c2.2,0.8,4.3,1.7,6.1,2.6c1.8,0.9,3.3,1.7,4.4,2.3
					                c1.1,0.6,1.8,1,1.9,1.1l55.8,25.5c0.2,0.1,1.1,0.9,2.5,2.1c1.4,1.2,3.2,2.8,5,4.6c1.8,1.8,3.6,3.8,5.1,5.8c1.4,2,2.5,4,2.8,5.9
					                c0.3,1.8-0.1,3.6-0.7,5.2c-0.7,1.6-1.7,3.2-2.8,4.5c-1.1,1.3-2.2,2.4-3.1,3.2c-0.9,0.8-1.5,1.3-1.7,1.3l-56.6,26.9
					                c1.3,0,2.5,0,3.7,0c1.2,0,2.3,0,3.4,0c1.1,0,2.1,0,3.1,0s1.9,0,2.8,0c0.5,0,0.9,0,1.4,0c0.5,0,0.9,0,1.3,0c0.4,0,0.8,0,1.2,0
					                c0.4,0,0.8,0,1.2,0c2.4,0,4.7-0.1,6.9-0.1c2.2-0.1,4.2-0.2,6-0.3c1.9-0.1,3.6-0.2,5.2-0.4c1.6-0.1,3-0.3,4.3-0.4
					                c0.6-0.1,1.2-0.2,1.8-0.3c0.6-0.1,1.2-0.2,1.8-0.4c0.6-0.2,1.1-0.3,1.7-0.5c0.5-0.2,1.1-0.4,1.6-0.6c3.1-1.3,6.4-2.3,9.8-3.1
					                c3.5-0.8,7.1-1.3,10.9-1.6c3.8-0.3,7.7-0.3,11.7,0c4,0.3,8.1,0.9,12.2,1.8c7.4,1.6,14.2,4.2,20.2,7.3c6,3.2,11.1,7,15.1,11.2
					                c4,4.2,7,8.9,8.6,13.7c1.6,4.8,1.9,9.8,0.5,14.7c-0.1,0.4,1.1,3.1,3.5,7.4c2.4,4.3,6,10.2,10.4,17.1c4.5,7,9.9,15,15.9,23.5
					                c6.1,8.5,12.8,17.5,19.9,26.2c6.4,7.9,12.5,15.3,18.3,22.1c5.8,6.9,11.3,13.1,16.4,18.7c5.1,5.6,9.9,10.5,14.4,14.6
					                c4.4,4.1,8.5,7.4,12.1,9.7c1.5,1,3.1,1.9,4.7,2.8c1.6,0.9,3.2,1.8,4.9,2.6c1.6,0.8,3.3,1.6,4.9,2.3c1.6,0.7,3.2,1.4,4.8,2
					                c3.1,0.3,7,0.5,11.3,0.6c4.3,0.1,9.1,0,14.1-0.2c5-0.2,10.2-0.5,15.3-0.9c5.1-0.4,10.2-0.9,14.9-1.4c5.6-6,14.3-15.3,24.2-25.8
					                c9.8-10.4,20.7-22.1,30.8-32.9c10-10.7,19.3-20.7,26.2-28c6.8-7.3,11.2-11.9,11.4-12.2L1342.4,404.5z M1148.1,596.6
					                c3.3-4.9,7.1-10.1,11.1-15.6c4-5.4,8.2-11,12.6-16.6c4.3-5.6,8.7-11.2,13.1-16.6c4.3-5.4,8.6-10.7,12.7-15.7
					                c-0.6,0.1-1.2,0.3-1.8,0.4s-1.2,0.3-1.8,0.4c-0.6,0.2-1.2,0.3-1.8,0.5c-0.6,0.2-1.2,0.3-1.8,0.5c-3.5,1-7.2,3.2-10.9,6.3
					                c-3.7,3.1-7.3,7-10.8,11.3c-3.5,4.3-6.7,9.1-9.6,13.8c-2.9,4.7-5.4,9.4-7.2,13.5c-0.3,1.5-0.5,3-0.8,4.5c-0.3,1.5-0.6,3-0.9,4.4
					                c-0.3,1.5-0.7,2.9-1.1,4.4C1148.9,593.7,1148.5,595.2,1148.1,596.6 M1069.5,752.3c3.5,1,7,1.3,10.3,1.2c3.3-0.2,6.4-0.9,9.1-2
					                c2.7-1.2,5-2.8,6.8-4.8c1.8-2,3-4.4,3.6-7.1c0.6-2.7,0.4-5.5-0.4-8.2c-0.8-2.7-2.2-5.3-4.2-7.6c-1.9-2.4-4.4-4.5-7.2-6.3
					                c-2.8-1.8-6-3.2-9.5-4.1c-3.5-0.9-6.9-1.3-10.2-1.1c-3.2,0.2-6.3,0.8-8.9,2c-2.7,1.1-5,2.7-6.8,4.7c-1.8,2-3.1,4.3-3.7,7
					                c-0.6,2.7-0.5,5.4,0.3,8.1c0.8,2.7,2.1,5.3,4,7.7c1.9,2.4,4.3,4.6,7.2,6.4C1062.7,749.9,1065.9,751.4,1069.5,752.3 M558.9,129.7
					                c2.5,0.5,5,0.7,7.4,0.6c2.4-0.1,4.8-0.5,7-1.1c2.2-0.6,4.1-1.5,5.8-2.6c1.6-1.1,2.9-2.4,3.8-3.9c0.8-1.5,1.2-3,1-4.4
					                c-0.2-1.5-0.8-2.9-1.8-4.2c-1-1.3-2.5-2.5-4.3-3.4c-1.8-1-3.9-1.8-6.4-2.3c-2.4-0.5-4.9-0.7-7.3-0.6c-2.4,0.1-4.8,0.5-6.9,1.1
					                c-2.2,0.6-4.1,1.5-5.8,2.5c-1.6,1.1-3,2.4-3.8,3.8c-0.9,1.5-1.2,3-1.1,4.4c0.1,1.5,0.7,2.9,1.8,4.2c1,1.3,2.5,2.5,4.3,3.5
					                C554.3,128.3,556.5,129.1,558.9,129.7 M981.7,352.3c2.9,0.7,5.8,0.9,8.5,0.8c2.7-0.1,5.3-0.6,7.6-1.4c2.3-0.8,4.3-1.9,5.8-3.3
					                c1.5-1.4,2.7-3,3.2-4.9c0.6-1.9,0.5-3.8-0.1-5.6c-0.6-1.8-1.7-3.6-3.2-5.3c-1.5-1.6-3.5-3.1-5.8-4.4c-2.3-1.2-4.9-2.2-7.8-2.9
					                c-2.9-0.6-5.7-0.9-8.4-0.8c-2.7,0.1-5.2,0.6-7.5,1.4c-2.3,0.8-4.2,1.9-5.8,3.2c-1.6,1.4-2.7,3-3.3,4.8c-0.6,1.9-0.6,3.8,0,5.6
					                c0.5,1.9,1.6,3.7,3.1,5.3c1.5,1.7,3.5,3.2,5.8,4.4C976.1,350.6,978.8,351.6,981.7,352.3 M1110.8,694.5c1-3.5,2.1-7.1,3.3-10.9
					                c1.1-3.7,2.3-7.6,3.5-11.5c1.2-3.9,2.4-7.8,3.6-11.6c1.2-3.8,2.4-7.6,3.6-11.3c-2.4,3.2-4.9,6.4-7.5,9.5
					                c-2.6,3.2-5.4,6.3-8.2,9.3c-2.8,3.1-5.7,6-8.5,8.9c-2.8,2.9-5.7,5.6-8.4,8.2c1.7,0.6,3.4,1.3,5,2c1.6,0.7,3.2,1.4,4.8,2.2
					                c1.6,0.8,3.1,1.6,4.6,2.5C1107.9,692.7,1109.4,693.6,1110.8,694.5 M1148.1,531.3c1.2-1.2,2.4-2.4,3.5-3.5
					                c1.2-1.1,2.4-2.2,3.6-3.2c1.2-1,2.4-1.9,3.6-2.8c1.2-0.9,2.4-1.7,3.6-2.4c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1
					                c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.2
					                c-0.3-0.1-0.6-0.2-0.9-0.3c-1.4-0.4-2.8-0.9-4.3-1.5c-1.5-0.5-3-1.1-4.6-1.7c-1.6-0.6-3.2-1.2-4.8-1.9c-1.6-0.7-3.2-1.3-4.9-2
					                c0.9,1.7,1.8,3.5,2.6,5.2c0.8,1.7,1.6,3.4,2.3,5.1c0.7,1.7,1.4,3.4,2,5C1147,528,1147.6,529.7,1148.1,531.3 M1039.1,797.8
					                l21.4-16.8c0,0-0.1,0-0.3-0.1c-0.2,0-0.5-0.1-0.8-0.2s-0.7-0.2-1.2-0.3c-0.5-0.1-1-0.3-1.5-0.4c-0.5-0.1-1-0.3-1.6-0.5
					                c-0.6-0.2-1.1-0.3-1.7-0.5c-0.6-0.2-1.2-0.4-1.8-0.6c-0.6-0.2-1.2-0.4-1.8-0.7c-1.4-0.6-2.7-1.2-3.8-1.8c-1.1-0.6-2-1.2-2.8-1.8
					                c-0.8-0.6-1.3-1-1.7-1.4c-0.4-0.4-0.6-0.6-0.7-0.6L1039.1,797.8 M513,117.7c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.6,0.1,0.9,0.1
					                c0.3,0,0.6,0.1,0.8,0.1c0.3,0,0.6,0.1,0.8,0.1c0-0.5,0.1-0.9,0.2-1.4c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.5,0.2-0.9,0.4-1.4
					                c0.1-0.5,0.3-0.9,0.5-1.4c-0.6,0.2-1.2,0.4-1.7,0.6c-0.6,0.2-1.2,0.4-1.8,0.6c-0.6,0.2-1.2,0.4-1.8,0.6
					                c-0.6,0.2-1.2,0.4-1.9,0.6c-0.4,0.1-0.9,0.3-1.5,0.4c-0.6,0.1-1.5,0.3-2.7,0.4c-1.2,0.2-2.8,0.3-4.9,0.5c-2.1,0.2-4.7,0.4-8,0.6
					                c3.9,0.1,7,0.1,9.4,0.2c2.4,0.1,4.3,0.1,5.6,0.2c1.4,0.1,2.3,0.2,3,0.2C512.1,117.5,512.6,117.6,513,117.7 M545.6,92.5
					                c0.1,0,0.5-0.1,0.9-0.2c0.5-0.1,1-0.3,1.6-0.4c0.6-0.2,1.2-0.3,1.6-0.4c0.5-0.1,0.8-0.2,0.9-0.2c-0.1,0-0.6-0.4-1.3-1
					                c-0.7-0.6-1.7-1.3-2.6-2c-1-0.7-1.9-1.5-2.6-2c-0.7-0.6-1.2-0.9-1.3-1c0,0.1,0.2,0.5,0.4,1.2c0.3,0.7,0.6,1.6,1,2.5
					                c0.4,0.9,0.7,1.8,1,2.5C545.4,91.9,545.6,92.4,545.6,92.5 M1016.7,373.1c1,0.4,2.1,0.8,3.1,1.2c1,0.4,2,0.9,3,1.3
					                c1,0.4,2,0.9,3,1.3c1,0.5,2,0.9,2.9,1.4c-0.5-0.7-0.9-1.5-1.4-2.2c-0.4-0.7-0.9-1.4-1.3-2.1c-0.4-0.7-0.8-1.4-1.2-2
					                c-0.4-0.7-0.8-1.3-1.1-1.9c-0.6,0.3-1.1,0.6-1.7,0.8c-0.6,0.3-1.2,0.5-1.7,0.8c-0.6,0.2-1.2,0.5-1.8,0.7
					                C1018,372.7,1017.3,372.9,1016.7,373.1 M585.4,146.2c0.6,0.4,1.2,0.8,1.8,1.1c0.6,0.4,1.1,0.8,1.7,1.1c0.5,0.4,1.1,0.8,1.6,1.2
					                c0.5,0.4,1,0.8,1.5,1.2c-0.1-0.5-0.2-1.1-0.3-1.6c-0.1-0.5-0.2-1.1-0.2-1.6c-0.1-0.5-0.2-1.1-0.2-1.6c-0.1-0.5-0.1-1.1-0.2-1.6
					                c-0.4,0.2-0.9,0.3-1.4,0.5c-0.5,0.2-0.9,0.3-1.4,0.5c-0.5,0.1-0.9,0.3-1.4,0.4C586.4,145.9,585.9,146,585.4,146.2 M922.4,327.6
					                c0.7,0.2,1.4,0.5,2.1,0.8c0.7,0.3,1.4,0.5,2.1,0.8c0.7,0.3,1.4,0.5,2,0.8c0.7,0.3,1.3,0.5,2,0.8c0-0.3,0.1-0.5,0.1-0.8
					                c0-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.1-0.5,0.2-0.8c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0s-0.9,0-1.3,0
					                s-0.9,0-1.4,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6,0-0.9,0s-0.6,0-0.9,0C923,327.6,922.7,327.6,922.4,327.6"></path>
			                </g>
		                </g>
		                <g>
			                <polygon className="st0" points="745.7,681.7 764.2,672.4 764.2,681.5 745.7,690.7 			"></polygon>
			                <path className="st3" d="M764,672.8v8.5l-18,8.9v-8.4L764,672.8 M764.5,672l-19,9.5v9.5l19-9.4V672L764.5,672z"></path>
		                </g>
		                <g>
			                <polygon className="st0" points="894.5,580.3 920.8,576 920.8,585.1 896.9,589.4 			"></polygon>
			                <path className="st3" d="M920.6,576.3v8.6l-23.5,4.2l-2.3-8.6L920.6,576.3 M921.1,575.7l-26.9,4.4l2.6,9.6l24.3-4.4V575.7
				                L921.1,575.7z"></path>
		                </g>
		                <g>
			                <polygon className="st0" points="539.3,543 563,517.1 563,526 544.4,546.3 			"></polygon>
			                <path className="st3" d="M562.7,517.8v8.1l-18.4,20l-4.6-2.9L562.7,517.8 M563.2,516.5l-24.3,26.6l5.5,3.5l18.8-20.5V516.5
				                L563.2,516.5z"></path>
		                </g>
		                <g>
			                <polygon className="st14" points="593.3,546.2 573.5,521.8 579.3,519.3 599.5,543.8 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st14" points="605,550.6 579.9,520 585.5,517.6 611.3,547.8 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st14" points="615.7,553.1 615.1,552.6 585.5,518.4 591.1,515.9 621.3,550.6 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st14" points="625.7,556 625,555.4 591.1,516.9 597,514.2 631.7,553.2 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st14" points="637.2,559.9 636.4,559.2 597,515.2 601.9,512.8 642.1,557.5 			"></polygon>
		                </g>
		                <line className="st15" x1="579.3" y1="519.3" x2="605.2" y2="550.5"></line>
		                <line className="st16" x1="585.5" y1="517.6" x2="615.7" y2="553.1"></line>
		                <line className="st16" x1="591.1" y1="515.9" x2="625.7" y2="556"></line>
		                <line className="st16" x1="597" y1="514.2" x2="637.2" y2="559.9"></line>
	                </g>
	                <g id="edifici_x5F_di_x5F_servizio">
		                <g>
			                <polygon className="st17" points="391.6,156.9 351.9,155.3 353.5,136.8 435.4,139 482.9,151.6 482.3,160.5 423.7,158.1 421,183.2 
				                396.2,184.5 			"></polygon>
			                <path className="st3" d="M353.7,137.1l81.6,2.2l47.3,12.6l-0.6,8.4l-58.1-2.3l-0.5,0l0,0.5l-2.6,24.7l-24.4,1.2l-4.5-27.2l-0.1-0.4
				                l-0.4,0l-39.2-1.6L353.7,137.1 M353.3,136.5l-1.6,19l39.8,1.6l4.6,27.6l25.3-1.3l2.6-25.1l58.6,2.3l0.6-9.3l-47.8-12.7
				                L353.3,136.5L353.3,136.5z"></path>
		                </g>
		                <g>
			                <polygon className="st17" points="307.4,236.3 313.8,221.9 347.4,238.9 			"></polygon>
			                <path className="st3" d="M313.9,222.3l32.3,16.3l-38.5-2.6L313.9,222.3 M313.7,221.6l-6.7,14.9l41.6,2.8L313.7,221.6L313.7,221.6z"></path>
		                </g>
		                <g>
			                <polygon className="st17" points="504.6,243.1 516.1,241 529.5,278 517.5,280.4 			"></polygon>
			                <path className="st3" d="M515.9,241.3l13.2,36.5l-11.4,2.3l-12.8-36.8L515.9,241.3 M516.2,240.7l-12,2.1l13.1,37.8l12.4-2.5
				                L516.2,240.7L516.2,240.7z"></path>
		                </g>
		                <g>
			                <polygon className="st18" points="504.3,242.9 517.4,280.7 504.3,260.9 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st18" points="533.9,265.3 544,279.2 544,297.3 533.9,283.5 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st17" points="534.3,265.4 596.6,252 608.1,267.4 544.1,279 			"></polygon>
			                <path className="st3" d="M596.5,252.3l11.1,14.9l-63.4,11.5l-9.5-13.1L596.5,252.3 M596.7,251.7l-62.8,13.5l10.1,14l64.5-11.7
				                L596.7,251.7L596.7,251.7z"></path>
		                </g>
		                <g>
			                <polygon className="st17" points="338.6,337 385.1,322.5 402.4,347.9 354,364.6 			"></polygon>
			                <path className="st3" d="M385,322.8l17,25l-47.9,16.5l-15.2-27.2L385,322.8 M385.2,322.2l-47,14.7l15.7,28.1l48.9-16.8L385.2,322.2
				                L385.2,322.2z"></path>
		                </g>
		                <g>
			                <polygon className="st18" points="338.2,354.9 338.2,336.8 353.9,364.9 345.4,367.8 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st17" points="758.8,555.5 799.5,525.3 807,528.9 777.7,564.8 			"></polygon>
			                <path className="st3" d="M799.5,525.6l7.1,3.4l-29.1,35.4l-18.4-9L799.5,525.6 M799.5,525.1l-41.2,30.5l19.5,9.6l29.7-36.3
				                L799.5,525.1L799.5,525.1z"></path>
		                </g>
		                <g>
			                <polygon className="st17" points="774.9,502.1 795.9,511.4 758.3,555.5 737.3,545.5 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st3" points="795.9,511.4 795.9,514.5 765.2,550.4 758.3,555.5 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st17" points="774.9,484.6 795.9,493.9 758.3,538 737.3,528 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st18" points="737.3,528 758.3,538 758.3,540.9 737.3,530.9 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st3" points="795.9,493.9 795.9,497 758.3,540.9 758.3,538 			"></polygon>
		                </g>
		                <g>
			                <polygon className="st18" points="1044.3,523.9 1044.7,522.6 1044.7,554.9 1044.3,556.1 			"></polygon>
			                <polygon className="st19" points="1045.2,518.7 1043.8,523.8 1043.8,559.9 1045.2,554.9 1045.2,518.7 			"></polygon>
		                </g>
		                <g>
			                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="969.8135" y1="440.263" x2="1024.3215" y2="534.6738">
				                <stop offset="1.405423e-03" className="sc1"></stop>
				                <stop offset="0.1083" className="sc2"></stop>
				                <stop offset="0.2521" className="sc2"></stop>
				                <stop offset="0.3637" className="sc3"></stop>
				                <stop offset="0.4638" className="sc2"></stop>
				                <stop offset="0.6129" className="sc2"></stop>
				                <stop offset="0.7204" className="sc3"></stop>
				                <stop offset="0.8387" className="sc2"></stop>
				                <stop offset="1" className="sc2"></stop>
			                </linearGradient>
			                <polygon className="st20" points="1011.6,516.5 1013.9,506.3 969.5,495 993.8,426.4 1044.9,518.8 1043.6,523.5 			"></polygon>
			                <path className="st19" d="M993.8,427l50.8,91.8l-1.2,4.4l-31.5-6.9l2.2-9.8l0.1-0.5l-0.5-0.1l-43.9-11.1L993.8,427 M993.7,425.8
				                l-24.6,69.4l44.5,11.3l-2.3,10.3l32.5,7.1l1.3-5.1L993.7,425.8L993.7,425.8z"></path>
		                </g>
		                <g className="st21">
			                <line className="st22" x1="997.9" y1="433.4" x2="975.9" y2="496.5"></line>
			                <line className="st22" x1="1003" y1="442.7" x2="985" y2="499.1"></line>
			                <line className="st22" x1="1008.5" y1="452.6" x2="993.8" y2="501.4"></line>
			                <line className="st22" x1="1014.6" y1="463.7" x2="1002.5" y2="503.6"></line>
			                <line className="st22" x1="1020.6" y1="474.4" x2="1011.2" y2="505.5"></line>
			                <line className="st22" x1="1026.3" y1="484.8" x2="1017" y2="518"></line>
			                <line className="st22" x1="1032.1" y1="495.2" x2="1024.7" y2="519.7"></line>
			                <line className="st22" x1="1038.4" y1="506.6" x2="1033.9" y2="521.6"></line>
			                <path className="st23" d="M995.2,441.3c0,0-6.4-1.6-6.4-1.6"></path>
			                <line className="st23" x1="1005.8" y1="461.8" x2="997.6" y2="459.7"></line>
			                <path className="st23" d="M991.5,452.3"></path>
			                <path className="st23" d="M999.8,452.9"></path>
			                <path className="st23" d="M1027.2,511.4"></path>
			                <path className="st23" d="M1036.6,512.7"></path>
			                <line className="st22" x1="986.5" y1="447.4" x2="992.5" y2="449"></line>
			                <line className="st23" x1="992.5" y1="449" x2="1000.4" y2="451"></line>
			                <line className="st22" x1="995.2" y1="441.3" x2="1002.9" y2="443.3"></line>
			                <line className="st22" x1="980.4" y1="464.7" x2="986.5" y2="466.3"></line>
			                <line className="st23" x1="983.4" y1="456" x2="989.5" y2="457.6"></line>
			                <line className="st22" x1="989.5" y1="457.6" x2="997.6" y2="459.7"></line>
			                <line className="st22" x1="983.6" y1="474.5" x2="992.1" y2="476.7"></line>
			                <line className="st22" x1="1000.6" y1="478.9" x2="1009.3" y2="481.1"></line>
			                <line className="st23" x1="986.5" y1="466.3" x2="994.8" y2="468.4"></line>
			                <line className="st23" x1="1003.1" y1="470.6" x2="1011.8" y2="472.8"></line>
			                <line className="st22" x1="980.6" y1="483.2" x2="989.3" y2="485.5"></line>
			                <line className="st23" x1="989.3" y1="485.5" x2="997.9" y2="487.8"></line>
			                <line className="st23" x1="977.1" y1="493.3" x2="986.1" y2="495.6"></line>
			                <line className="st23" x1="1006.6" y1="490" x2="1015.2" y2="492.2"></line>
			                <line className="st23" x1="994.9" y1="497.9" x2="1003.6" y2="500.2"></line>
			                <line className="st23" x1="1012.2" y1="502.4" x2="1020.6" y2="504.6"></line>
			                <line className="st23" x1="1028.7" y1="506.7" x2="1037.7" y2="509"></line>
			                <line className="st23" x1="974.2" y1="481.6" x2="980.6" y2="483.2"></line>
			                <line className="st22" x1="994.8" y1="468.4" x2="1003.1" y2="470.6"></line>
			                <line className="st22" x1="1000.4" y1="451" x2="1008.4" y2="453.1"></line>
			                <line className="st22" x1="1005.8" y1="461.8" x2="1014.4" y2="464.1"></line>
			                <line className="st22" x1="997.9" y1="487.8" x2="1006.6" y2="490"></line>
			                <line className="st22" x1="1015.2" y1="492.2" x2="1023.6" y2="494.3"></line>
			                <line className="st22" x1="1018.5" y1="512.4" x2="1026.3" y2="514.4"></line>
			                <line className="st22" x1="1035.3" y1="516.8" x2="1044.8" y2="519.2"></line>
			                <line className="st22" x1="1011.8" y1="472.8" x2="1020.4" y2="475.1"></line>
			                <line className="st22" x1="1017.9" y1="483.4" x2="1026.1" y2="485.5"></line>
			                <line className="st23" x1="1023.6" y1="494.3" x2="1031.8" y2="496.4"></line>
		                </g>
		                <g>
			                <polygon className="st18" points="421.3,183.5 396,184.8 396,202.8 421.3,201.6 			"></polygon>
		                </g>
	                </g>
	                <g id="indicazioni">
		                <text transform="matrix(1 0 0 1 402.5156 258.6358)" className="st24 st5 st25">EVENT PLAZA</text>
		                <g>
			                <g>
				                <g>
					                <polygon points="400.6,338.7 409.3,341 405.3,346.5 					"></polygon>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <g className="st9">
					                <g>
						                <path d="M387.8,335.7l5.3,3.3l-0.9,0.3c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
							                c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0l-1-0.7l-2.7,0.7l0.1,1c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0s0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0l-1,0.3l-0.1-4.7L387.8,335.7z M387.8,338.6l2.1-0.6l-1.7-1.1c0,0,0,0-0.1,0
							                c0,0,0,0-0.1,0s0,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1-0.1c0,0,0,0.1,0,0.1
							                s0,0,0,0.1c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0,0,0.1L387.8,338.6"></path>
					                </g>
					                <g>
						                <polygon points="383.9,336.8 384.3,337.4 382.3,337.9 384.6,341.3 383.3,341.7 381.1,338.3 379.1,338.8 378.7,338.2 						"></polygon>
					                </g>
					                <g>
						                <path d="M376,338.9l5.2,3.3l-0.9,0.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
							                c0,0,0,0-0.1,0c0,0,0,0-0.1,0l-1-0.7l-2.7,0.7l0.1,1c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                s0,0-0.1,0s0,0-0.1,0l-0.9,0.2l0,0l0,0l-0.1-4.7L376,338.9z M376,341.9l2.1-0.6l-1.7-1.1c0,0,0,0-0.1,0s0,0-0.1,0
							                c0,0,0,0-0.1,0c0,0,0,0-0.1-0.1s0,0-0.1-0.1s0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0.1,0,0.1
							                c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1L376,341.9"></path>
					                </g>
					                <g>
						                <polygon points="366.3,341.6 366.7,342.2 364.7,342.7 366.9,346.2 365.7,346.5 363.5,343.1 361.5,343.6 361.1,343 						"></polygon>
					                </g>
					                <g>
						                <polygon points="389.5,342.1 389.7,342.4 387.6,343 388.2,343.9 389.9,343.5 390.1,343.8 388.4,344.2 389,345.2 391.2,344.6 
							                391.3,344.9 388.6,345.6 386.8,342.9 						"></polygon>
					                </g>
					                <g>
						                <path d="M386.1,343.4C386.1,343.4,386.1,343.4,386.1,343.4C386.1,343.4,386.1,343.4,386.1,343.4
							                C386.2,343.4,386.2,343.4,386.1,343.4C386.2,343.4,386.2,343.4,386.1,343.4l0,0.2l0,0l-0.1,0l-0.2,0c0,0,0,0-0.1,0
							                c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1s-0.1,0-0.2,0.1s-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1
							                c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
							                c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0.1,0.1s0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
							                c0,0,0.1,0.1,0.1,0.1s0.1,0.1,0.1,0.1s0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1
							                c0,0,0.1,0,0.1,0c0,0,0,0,0,0s0,0,0,0s0,0,0,0s0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
							                c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0s0.1,0,0.2,0s0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0
							                c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0s0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.1l0.1,0l0.3,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
							                s-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1s-0.1,0.1-0.2,0.1s-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0.1
							                c-0.1,0-0.2,0-0.2,0s-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
							                c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1
							                s-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
							                c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0-0.1,0-0.1
							                s0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.1s0.1-0.1,0.2-0.1
							                c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.2,0s0.1,0,0.2,0
							                c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0C386,343.4,386,343.4,386.1,343.4z"></path>
					                </g>
					                <g>
						                <path d="M360.2,343.3l2.6,4l-0.6,0.2l-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0,0,0l-5.2-1.7c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1s0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1l1.6,2.5l-1.1,0.3
							                l-2.6-4l0.7-0.2l0.1,0l0.1,0l0.1,0l0.1,0l5.2,1.7l-0.1-0.2l-0.1-0.2l-1.6-2.5L360.2,343.3z"></path>
					                </g>
					                <g>
						                <polygon points="381.4,344.4 383.3,347.1 383,347.2 382.8,347.2 382.7,347.2 378.7,345.8 378.8,345.9 378.9,346 380.2,348 
							                379.6,348.1 377.8,345.4 378.1,345.3 378.2,345.3 378.3,345.3 378.3,345.3 378.4,345.3 382.3,346.6 382.3,346.5 382.2,346.5 
							                380.9,344.5 						"></polygon>
					                </g>
					                <g>
						                <path d="M375.4,346l3.6,2.3l-0.5,0.1l-0.1,0l-0.1,0l-0.9-0.6l-2,0.6l0,0.8l0,0.1l-0.1,0.1l-0.5,0.1l0-3.2L375.4,346z
							                 M375.4,348.1l1.7-0.5l-1.4-0.9l-0.1-0.1l-0.2-0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1L375.4,348.1"></path>
					                </g>
					                <g>
						                <path d="M372.7,348.7l2,0.7l-0.5,0.2c0,0,0,0-0.1,0s0,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0l-1.8-0.7l-0.2,0l-0.2,0l-0.5,0.1l0.8,1.2l-0.6,0.2l-1.8-2.8l1.3-0.3c0.1,0,0.1,0,0.2-0.1s0.1,0,0.2,0
							                c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
							                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1
							                c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0,0,0.1
							                c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1
							                c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                C372.6,348.7,372.6,348.7,372.7,348.7C372.7,348.7,372.7,348.7,372.7,348.7C372.7,348.7,372.7,348.7,372.7,348.7z
							                 M372.5,348.2C372.5,348.2,372.5,348.2,372.5,348.2c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
							                c0,0,0,0,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.2,0
							                c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0.1l-0.7,0.2
							                l0.7,1l0.6-0.2c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0,0,0.1,0
							                C372.4,348.3,372.4,348.3,372.5,348.2C372.4,348.3,372.5,348.2,372.5,348.2"></path>
					                </g>
					                <g>
						                <polygon points="369,347.8 369.2,348.1 367.7,348.5 369.3,350.9 368.7,351.1 367.1,348.7 365.7,349.1 365.5,348.8 						"></polygon>
					                </g>
					                <g>
						                <polygon points="364.8,348.9 366.6,351.7 366.3,351.8 366.2,351.8 366,351.8 362.1,350.4 362.1,350.5 362.2,350.6 
							                363.5,352.6 362.9,352.7 361.1,349.9 361.5,349.9 361.5,349.8 361.6,349.8 361.6,349.8 361.7,349.9 365.7,351.2 365.6,351.1 
							                365.5,351 364.3,349.1 						"></polygon>
					                </g>
					                <g>
						                <polygon points="360.3,350.2 360.5,350.5 358.3,351.1 358.9,352 360.7,351.5 360.9,351.8 359.1,352.3 359.7,353.2 
							                361.9,352.6 362.1,352.9 359.3,353.7 357.5,350.9 						"></polygon>
					                </g>
				                </g>
				                <g>
					                <path d="M374.9,344L374.9,344l-1,0.3c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0,0,0,0,0s0,0,0,0
						                c0,0,0,0,0,0c0,0,0,0,0,0l-2.2-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
						                c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.5,0.1l1,1.6l-1.2,0.3l-2.7-4l2-0.5
						                c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1s0.2,0,0.3-0.1s0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0
						                c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1
						                c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1s0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1
						                c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1
						                c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1
						                c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
						                c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0L374.9,344L374.9,344z M371.3,342.2
						                C371.3,342.2,371.3,342.2,371.3,342.2c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
						                c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1
						                c-0.1,0-0.2,0-0.2,0s-0.2,0-0.3,0s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1l-0.8,0.2l0.9,1.3l0.8-0.2c0.1,0,0.1,0,0.2,0
						                c0.1,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1
						                C371.2,342.4,371.2,342.3,371.3,342.2C371.2,342.3,371.3,342.3,371.3,342.2"></path>
				                </g>
				                <g>
					                <polygon points="353.6,345.1 354,345.7 351,346.5 351.8,347.6 354.1,347 354.4,347.5 352.1,348.2 352.9,349.3 355.8,348.5 
						                356.2,349.1 352,350.3 349.4,346.2 					"></polygon>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <g>
					                <polygon points="532.1,285.5 536.8,293.2 528.1,290.9 					"></polygon>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <g className="st9">
					                <g>
						                <path d="M587.5,259l4.5,3.7l-1,0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                s0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0,0,0c0,0,0,0,0,0l-0.9-0.8l-2.8,0.5l-0.1,1c0,0,0,0,0,0s0,0,0,0s0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0-0.1,0s0,0-0.1,0s0,0-0.1,0s0,0-0.1,0l-1,0.2l0.9-4.7L587.5,259z M586.8,261.9l2.2-0.4l-1.4-1.2c0,0,0,0-0.1,0
							                s0,0-0.1,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1s0,0-0.1-0.1s0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0.1,0,0.1
							                s0,0.1,0,0.1s0,0,0,0.1s0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1L586.8,261.9"></path>
					                </g>
					                <g>
						                <polygon points="583.4,259.8 583.6,260.4 581.6,260.8 583.1,264.3 581.8,264.6 580.3,261 578.2,261.4 578,260.7 						"></polygon>
					                </g>
					                <g>
						                <path d="M575.2,261.2l4.5,3.7l-1,0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.9-0.8l-2.8,0.5l-0.2,1c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l-0.9,0.2l-0.1,0l0,0l0.9-4.7L575.2,261.2z
							                 M574.5,264.2l2.2-0.4l-1.4-1.3c0,0,0,0-0.1,0s0,0-0.1,0s0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
							                c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
							                c0,0,0,0,0,0.1c0,0,0,0,0,0.1L574.5,264.2"></path>
					                </g>
					                <g>
						                <path d="M573,266.2l-1.1,0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0
							                c0,0,0,0-0.1,0c0,0,0,0,0,0l-2-1.1c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.5,0.1l0.7,1.7l-1.3,0.2l-1.7-4.2l2.1-0.4c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3,0
							                s0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
							                c0,0,0.1,0.1,0.1,0.1s0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
							                c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1
							                c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1
							                c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0s0,0,0,0s0,0,0,0
							                c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0L573,266.2L573,266.2L573,266.2z M569.8,264.2
							                C569.8,264.1,569.8,264.1,569.8,264.2c0.1-0.1,0.1-0.1,0.1-0.1s0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
							                c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1
							                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0
							                l-0.8,0.1l0.6,1.4l0.8-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
							                c0,0,0.1,0,0.1-0.1C569.7,264.3,569.7,264.2,569.8,264.2C569.7,264.2,569.8,264.2,569.8,264.2"></path>
					                </g>
					                <g>
						                <polygon points="565,263.1 565.3,263.7 563.2,264.1 564.7,267.7 563.4,267.9 561.9,264.4 559.8,264.7 559.6,264.1 						"></polygon>
					                </g>
					                <g>
						                <path d="M558.7,264.2l1.7,4.2l-0.7,0.1l-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0c0,0,0,0,0,0l-4.7-2.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1s0,0.1,0,0.1l1.1,2.6l-1.1,0.2l-1.7-4.2
							                l0.7-0.1l0.1,0l0.1,0l0.1,0l0.1,0l4.8,2.2c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0
							                s0,0,0,0l-1-2.6L558.7,264.2z"></path>
					                </g>
					                <g>
						                <polygon points="587.8,265.6 587.9,265.9 585.7,266.3 586.1,267.3 587.9,266.9 588,267.2 586.2,267.6 586.6,268.6 
							                588.9,268.1 589,268.5 586.1,269 584.9,266.1 						"></polygon>
					                </g>
					                <g>
						                <polygon points="551.7,265.5 552,266.1 548.9,266.7 549.4,267.9 551.8,267.4 552.1,268 549.7,268.5 550.1,269.7 553.2,269.1 
							                553.5,269.7 549.1,270.5 547.4,266.3 						"></polygon>
					                </g>
					                <g>
						                <path d="M583.9,266.5c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l-0.1,0.2l0,0l-0.1,0l-0.2,0
							                c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0.1
							                s-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
							                c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0,0.1
							                c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
							                c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
							                c0,0,0,0,0.1,0s0,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
							                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.3-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0s0,0,0.1,0c0,0,0,0,0,0
							                c0,0,0,0,0.1,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0l0.1-0.1l0.1,0l0.3,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
							                c0,0-0.1,0.1-0.2,0.1s-0.1,0.1-0.2,0.1s-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0.1s-0.2,0-0.3,0.1
							                c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0s-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1
							                c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1s-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
							                c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
							                c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1s0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.1
							                c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.2-0.1
							                c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0
							                C583.7,266.5,583.7,266.5,583.9,266.5C583.8,266.5,583.8,266.5,583.9,266.5z"></path>
					                </g>
					                <g>
						                <polygon points="579.4,267.1 580.6,270 580.3,270.1 580.1,270.1 580,270 576.4,268.4 576.4,268.4 576.5,268.5 577.3,270.6 
							                576.8,270.7 575.6,267.8 575.9,267.8 576,267.7 576,267.7 576.1,267.8 576.1,267.8 579.8,269.5 579.7,269.4 579.7,269.3 
							                578.8,267.2 						"></polygon>
					                </g>
					                <g>
						                <path d="M573.1,268.3l3.1,2.5l-0.5,0.1l-0.1,0l-0.1,0l-0.7-0.6l-2.1,0.4l-0.1,0.8l0,0.1l-0.1,0.1l-0.5,0.1l0.7-3.2
							                L573.1,268.3z M572.6,270.3l1.7-0.3l-1.2-1l-0.1-0.1l-0.1-0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
							                L572.6,270.3"></path>
					                </g>
					                <g>
						                <path d="M569.8,270.7l1.8,0.9l-0.6,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0l-1.7-0.8l-0.1,0l-0.2,0l-0.5,0.1l0.5,1.2l-0.6,0.1l-1.2-2.9l1.3-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
							                c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
							                c0,0,0,0,0,0s0,0,0,0s0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1
							                c0,0,0,0,0.1,0.1s0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
							                c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0-0.1,0.1s0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0.1
							                c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0,0,0,0.1,0s0,0,0.1,0S569.8,270.7,569.8,270.7
							                C569.8,270.7,569.8,270.7,569.8,270.7z M569.7,270.2C569.7,270.2,569.7,270.2,569.7,270.2c0.1-0.1,0.1-0.1,0.1-0.1
							                c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
							                c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.2,0-0.2,0l-0.7,0.1l0.5,1.1l0.7-0.1c0,0,0.1,0,0.1,0
							                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0C569.6,270.3,569.6,270.3,569.7,270.2
							                C569.6,270.3,569.7,270.2,569.7,270.2"></path>
					                </g>
					                <g>
						                <polygon points="566.3,269.5 566.5,269.8 564.9,270.1 566,272.7 565.4,272.8 564.3,270.2 562.8,270.5 562.7,270.2 						"></polygon>
					                </g>
					                <g>
						                <polygon points="562,270.3 563.1,273.2 562.8,273.3 562.7,273.3 562.6,273.2 559,271.5 559,271.6 559.1,271.7 559.9,273.8 
							                559.3,273.9 558.2,271 558.5,270.9 558.6,270.9 558.6,270.9 558.7,270.9 558.7,271 562.3,272.6 562.3,272.5 562.2,272.4 
							                561.4,270.4 						"></polygon>
					                </g>
					                <g>
						                <polygon points="557.3,271.1 557.4,271.5 555.1,271.9 555.5,272.8 557.4,272.5 557.5,272.8 555.6,273.2 556.1,274.1 
							                558.3,273.7 558.4,274 555.5,274.6 554.4,271.7 						"></polygon>
					                </g>
				                </g>
			                </g>
		                </g>
		                <text transform="matrix(1 0 0 1 853.5056 514.619)" className="st24 st5 st25">WELCOME PLAZA</text>
		                <text transform="matrix(1 0 0 1 687.2344 588.9345)" className="st24 st5 st25">GARDEN PLAZA</text>
		                <g>
			                <g>
				                <g>
					                <polygon points="947.2,416.7 973.8,424.2 953.9,428.9 					"></polygon>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <g>
					                <polygon points="941.5,389.1 948.2,390.7 947.5,391.7 942.8,390.6 941.6,392.4 945.3,393.3 944.6,394.2 940.9,393.4 
						                939.6,395.2 944.3,396.3 943.6,397.3 937,395.8 					"></polygon>
				                </g>
				                <g className="st9">
					                <g>
						                <polygon points="960.2,393.5 968.6,395.5 967.9,396.5 964.7,395.7 960.9,401.4 958.9,401 962.7,395.3 959.5,394.5 						"></polygon>
					                </g>
					                <g>
						                <path d="M972.8,401.4l1,3l-0.1,0.1l-1.7-0.4c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1
							                c0,0,0-0.1-0.1-0.1l-0.8-2.7c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.8-0.2l-1.7,2.6l-2-0.5l4.5-6.7l3.2,0.8c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.3,0.1,0.5,0.1
							                s0.3,0.1,0.4,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.2,0.1,0.2,0.2
							                c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2
							                c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.1-0.1,0.2
							                c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
							                c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3,0
							                c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0.1,0.1s0,0,0.1,0.1s0,0,0.1,0.1
							                c0,0,0,0,0.1,0.1s0,0,0,0.1s0,0,0,0.1S972.8,401.3,972.8,401.4C972.8,401.3,972.8,401.3,972.8,401.4z M971.4,399.8
							                c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
							                c0.1,0,0.1,0,0.2,0s0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1s0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
							                c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
							                c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2s-0.3-0.1-0.4-0.2s-0.3-0.1-0.5-0.1l-1.3-0.3
							                l-1.5,2.2l1.2,0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0C971.3,399.8,971.3,399.8,971.4,399.8C971.3,399.8,971.4,399.8,971.4,399.8
							                "></path>
					                </g>
					                <g>
						                <polygon points="941.7,400.5 946.2,401.5 945.9,402 942.4,401.2 941.3,402.7 944.2,403.4 943.8,403.9 941,403.2 939.9,404.8 
							                943.4,405.6 943.1,406.1 938.6,405 						"></polygon>
					                </g>
					                <g>
						                <path d="M947.6,401.8l0.5,0.1l0.1,0l0.1,0l0,0l0,0.1l1.9,4.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.1
							                l2.2-3.3l0.9,0.2l-3.1,4.6l-0.5-0.1l-0.2-0.1l-0.1-0.1l-1.9-4.5c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0l-0.1,0.1
							                l-2.2,3.3l-0.9-0.2L947.6,401.8z"></path>
					                </g>
					                <g>
						                <path d="M984.4,399.2l-0.2,7.7l-1.5-0.4c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
							                c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0,0,0-0.1l0.1-1.6l-4.4-1l-1.7,1.2c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0l-1.5-0.4l0.1-0.1l8.5-5.7L984.4,399.2z M978.9,402.7l3.4,0.8l0.2-2.5c0,0,0-0.1,0-0.1s0-0.1,0-0.1
							                c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0.1-0.1,0.1
							                c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1
							                s-0.1,0-0.1,0.1L978.9,402.7"></path>
					                </g>
					                <g>
						                <polygon points="954.5,403.5 960.2,404.8 959.8,405.4 957.5,404.8 954.8,408.9 953.8,408.6 956.5,404.6 954.2,404 						"></polygon>
					                </g>
					                <g>
						                <polygon points="988.7,400.2 997.1,402.2 996.5,403.2 993.3,402.5 989.5,408.2 987.5,407.7 991.3,402 988,401.2 						"></polygon>
					                </g>
					                <g>
						                <path d="M962.7,408.6l0.9,2.3l-0.9-0.2c0,0-0.1,0-0.1,0s-0.1,0-0.1-0.1s0,0-0.1-0.1s0,0,0-0.1l-0.8-2.1c0,0,0,0,0,0
							                c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.7-0.2l-1.3,1.9l-1-0.2l3.1-4.6
							                l2.1,0.5c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
							                c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1
							                c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
							                c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0.1
							                s-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1s-0.1,0-0.2,0s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
							                c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1C962.6,408.5,962.6,408.5,962.7,408.6
							                C962.6,408.6,962.6,408.6,962.7,408.6z M962,407.8c0,0,0.1,0,0.1,0s0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0
							                c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1-0.1
							                s0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
							                c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1s-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1
							                c-0.1,0-0.2-0.1-0.4-0.1l-1.1-0.3l-1.2,1.7l1,0.2c0,0,0,0,0.1,0s0,0,0.1,0C961.9,407.8,962,407.8,962,407.8
							                C962,407.8,962,407.8,962,407.8"></path>
					                </g>
					                <g>
						                <path d="M970.6,407.3l-0.1,5.3l-0.8-0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1l0.1-1.3l-3.3-0.8
							                l-1.4,1l-0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                l-0.8-0.2l6-3.9L970.6,407.3z M966.8,409.8l2.7,0.6l0.1-2.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1s0,0,0-0.1c0,0,0,0,0-0.1
							                s0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
							                L966.8,409.8"></path>
					                </g>
					                <g>
						                <path d="M1003.5,403.7l-0.1,7.8l-1.5-0.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
							                c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1l0.1-1.6l-4.4-1l-1.7,1.2c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							                c0,0,0,0,0,0l-1.6-0.4l8.6-5.8L1003.5,403.7z M998.1,407.3l3.4,0.8l0.2-2.6c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
							                c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
							                c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1s-0.1,0-0.1,0.1
							                L998.1,407.3"></path>
					                </g>
					                <g>
						                <path d="M974.5,408.2l0.5,0.1l0.1,0l0.1,0l0.1,0l0,0.1l1.9,4.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0l0.1-0.1l2.1-3.3
							                l0.9,0.2l-3,4.6l-0.5-0.1l-0.2-0.1l-0.1-0.1l-1.9-4.5c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0.1l-2.2,3.3
							                l-0.9-0.2L974.5,408.2z"></path>
					                </g>
					                <g>
						                <path d="M985.1,410.7C985.1,410.7,985.1,410.7,985.1,410.7c0.1,0,0.2,0,0.2,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0
							                c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2
							                c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2l-0.5,0.2l-0.1,0l-0.1,0l-0.2-0.1c0,0,0,0-0.1-0.1
							                s0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1
							                s-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0
							                c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
							                c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
							                c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2s-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.1,0.2
							                c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
							                c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1
							                c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1s0.2,0.1,0.2,0.1s0.2,0,0.3,0.1c0,0,0.1,0,0.1,0
							                s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0s0,0,0.1,0c0,0,0.1,0,0.1,0
							                s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0
							                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.2,0l0.1,0.1l0.2,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.4,0.1
							                c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
							                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1
							                s-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
							                s-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3
							                c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3s0.1-0.2,0.2-0.2
							                c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1
							                c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0c0.1,0,0.2,0,0.3,0
							                c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0C984.9,410.6,985,410.7,985.1,410.7z"></path>
					                </g>
					                <g>
						                <polygon points="989.1,411.7 993.6,412.7 993.3,413.3 989.7,412.4 988.7,414 991.6,414.6 991.3,415.1 988.4,414.5 987.4,416 
							                990.9,416.9 990.6,417.4 986.1,416.3 						"></polygon>
					                </g>
				                </g>
				                <g>
					                <path d="M949.9,391.1l1,0.2l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0.1l2.5,5.8c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0-0.1,0-0.1s0-0.1,0-0.1
						                c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1l2.7-4.1l1.7,0.4l-4.5,6.7l-1-0.2c0,0-0.1,0-0.1,0
						                c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1s0,0,0-0.1l-2.5-5.8
						                c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-2.8,4.1l-1.7-0.4L949.9,391.1z"></path>
				                </g>
			                </g>
		                </g>
	                </g>
	                <g id="icone">
		                <line className="st26" x1="595.5" y1="198.1" x2="627.1" y2="198.1"></line>
		                <g>
			                <g>
				                <g>
					                <polygon className="st4" points="784.8,537.3 795.6,542.2 786,543.2 					"></polygon>
				                </g>
			                </g>
		                </g>
		                <g>
			                
				                <ellipse transform="matrix(0.9982 -6.033323e-02 6.033323e-02 0.9982 -29.4284 47.2129)" className="st4" cx="767.1" cy="510.9" rx="1.4" ry="1.6"></ellipse>
		                </g>
		                <line className="st26" x1="766.5" y1="312.4" x2="767.1" y2="510.9"></line>
		                <g>
			                
				                <ellipse transform="matrix(0.9982 -6.033323e-02 6.033323e-02 0.9982 -28.4079 62.7405)" className="st4" cx="1024.7" cy="501.8" rx="1.4" ry="1.6"></ellipse>
		                </g>
		                <line className="st26" x1="1024.1" y1="396.1" x2="1024.7" y2="501.8"></line>
		                <line className="st26" x1="765.3" y1="474.4" x2="797" y2="474.4"></line>
		                <g>
			                <g>
				                <g>
					                <g>
						                <rect x="934.8" y="484.7" className="st27" width="23.7" height="23.7"></rect>
					                </g>
				                </g>
			                </g>
			                <path className="st28" d="M951.7,501.1v-13.4h-10.2v13.4h2.4v3.2h-1l-0.8,0.6v1h8.8v-1l-0.8-0.6h-0.9v-3.2H951.7z M942.4,488.5h8.6
				                v11.8h-8.6V488.5z"></path>
		                </g>
		                <g>
			                <g>
				                <g>
					                <g>
						                <rect x="730.5" y="604.5" className="st27" width="23.7" height="23.7"></rect>
					                </g>
				                </g>
			                </g>
			                <path className="st28" d="M747.4,620.9v-13.4h-10.2v13.4h2.4v3.2h-1l-0.8,0.6v1h8.8v-1l-0.8-0.6H745v-3.2H747.4z M738,608.3h8.6v11.8
				                H738V608.3z"></path>
		                </g>
		                <polyline className="st26" points="596.7,258.1 596.7,109.7 596.7,142.7 		"></polyline>
		                <circle className="st4" cx="596.7" cy="259.2" r="1.4"></circle>
		                <g>
			                <g>
				                <rect x="584.8" y="185.2" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <g>
					                <g>
						                <path className="st28" d="M591.6,191.4c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2
							                C590.4,190.8,590.9,191.4,591.6,191.4z"></path>
						                <circle className="st28" cx="601.7" cy="190.2" r="1.2"></circle>
						                <path className="st28" d="M605.5,197.8l-1-4.2c-0.3-1-1.2-1.6-2.8-1.6c-1.6,0-2.5,0.5-2.8,1.6l-1,4.2c-0.2,0.7,0.9,1,1,0.3l1.1-4
							                c0.1-0.2,0.4-0.3,0.4,0.1c0,0.4-1,4.4-1.2,5.4l-0.3,1.5c0,0.2,0.3,0.4,1,0.4c0.1,1,0.2,1.9,0.2,2.7c0.1,0.6,1.1,0.5,1.1,0
							                l0.1-2.6h0.6l0.1,2.6c0,0.5,1,0.6,1.1,0c0.1-0.7,0.2-1.7,0.2-2.7c0.6-0.1,1-0.2,1-0.4l-0.3-1.5c-0.2-1-1.2-5-1.2-5.4
							                c0-0.4,0.3-0.3,0.4-0.1l1.1,4C604.7,198.8,605.7,198.5,605.5,197.8z"></path>
						                <path className="st28" d="M594.7,194.5c-0.2-1.8-0.7-2.6-3.1-2.6s-3,0.8-3.2,2.6l-0.4,3.2c-0.1,0.9,0.9,0.9,1,0.3l0.6-3.7
							                c0.1-0.3,0.4-0.3,0.4,0c-0.2,2.9-0.3,6.2-0.3,9.5c0,0.9,1.1,1,1.2,0.1l0.5-5.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.2,0.2
							                l0.5,5.3c0.1,0.8,1.2,0.8,1.2-0.1c0-3.3-0.2-6.6-0.3-9.5c0-0.3,0.4-0.3,0.4,0l0.6,3.7c0.1,0.7,1.1,0.6,1-0.3L594.7,194.5z"></path>
						                <path className="st28" d="M596.5,189c-0.6,0-0.6,0-0.6,0.6v14.2c0,0.6,0,0.6,0.6,0.6c0.6,0,0.6,0,0.6-0.6v-14.2
							                C597.1,189,597.1,189,596.5,189z"></path>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <rect x="402.5" y="186.7" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <path className="st28" d="M411.6,191.5c-0.1-0.7-1-0.7-1,0v3.3c0,0.5-0.9,0.5-0.9,0v-3.3c0-0.7-1.1-0.7-1.1,0v3.3
							                c0,0.5-0.9,0.5-0.9,0v-3.3c0-0.7-0.9-0.7-1,0l-0.4,2.6c-0.4,3.4,2.1,3.1,2,4c-0.7,6.8-0.9,9.4,0.7,9.4s1.4-2.6,0.7-9.4
							                c-0.1-0.9,2.4-0.7,2-4L411.6,191.5z"></path>
						                <path className="st28" d="M421.4,195h-6.8c-0.7,0-0.8,0-0.7,0.7l1.2,11.2c0.1,0.6,0.1,0.6,0.7,0.6h4.3c0.6,0,0.6,0,0.7-0.6
							                l1.4-11.2C422.2,195,422.1,195,421.4,195z"></path>
						                <path className="st28" d="M417.1,200.1l1.6-0.3l3.2-9.3c0.2-0.6,0.2-0.6-0.4-0.9c-0.7-0.3-0.7-0.2-0.9,0.4L417.1,200.1z"></path>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <rect x="396.4" y="132.6" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <path className="st28" d="M414,143.4h-8.6c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2l4.3-4.9c0.1-0.1,0.3-0.1,0.5,0c0,0,0,0,0,0
							                l4.3,4.9c0.1,0.1,0.1,0.3,0,0.4C414.1,143.4,414,143.4,414,143.4z"></path>
						                <path className="st28" d="M406.3,153.6c0,0.2,0.1,0.3,0.3,0.3h1.8c0.2,0,0.3-0.1,0.3-0.3V152h1.8v1.5c0,0.2,0.1,0.3,0.3,0.3h1.8
							                c0.2,0,0.3-0.1,0.3-0.3v-2.8h-6.8V153.6z"></path>
						                <path className="st28" d="M406.3,144v6.2h6.8V144H406.3z M409.7,148.3c-0.9,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.5,1.5-1.5
							                c0.9,0,1.5,0.7,1.5,1.5C411.2,147.6,410.5,148.3,409.7,148.3z"></path>
						                <path className="st28" d="M410,139.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3v-4c0-0.2,0.1-0.3,0.3-0.3
							                c0.2,0,0.3,0.1,0.3,0.3V139.3z"></path>
						                <path className="st28" d="M412.9,137.1c0,0.2-0.1,0.3-0.3,0.3h-2.9c-0.2,0-0.3-0.1-0.3-0.3v-1.9c0-0.2,0.1-0.3,0.3-0.3h2.9
							                c0.2,0,0.3,0.1,0.3,0.3V137.1z"></path>
						                <g>
							                <path className="st28" d="M417.4,153.6c0,0.2-0.1,0.3-0.3,0.3h-1.2c-0.2,0-0.3-0.1-0.3-0.3v-2.4c0-0.9-0.7-1.6-1.6-1.6H414
								                c-0.2,0-0.3-0.1-0.3-0.3V148c0-0.2,0.1-0.3,0.3-0.3h0.1c1.9,0,3.4,1.5,3.4,3.4V153.6z"></path>
						                </g>
						                <path className="st28" d="M405.4,147.8l-3.5,2.5h-1c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h1.5c0.4,0,0.7-0.1,1-0.3
							                c0,0,1.9-1.4,1.9-1.4c0.1-0.1,0.3-0.3,0.3-0.5V148C405.6,147.8,405.5,147.7,405.4,147.8z"></path>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <g>
					                <g>
						                <rect x="408.3" y="282.6" className="st27" width="23.7" height="23.7"></rect>
					                </g>
				                </g>
			                </g>
			                <path className="st28" d="M425.2,299.1v-13.4H415v13.4h2.4v3.2h-1l-0.8,0.6v1h8.8v-1l-0.8-0.6h-0.9v-3.2H425.2z M415.8,286.4h8.6
				                v11.8h-8.6V286.4z"></path>
		                </g>
		                <g>
			                <rect x="614.4" y="88.3" className="st29" width="23.8" height="23.8"></rect>
			                <g>
				                <path className="st28" d="M626.7,93.3c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.3,0.5,1.7,0.9c0.5,0.4,0.8,0.9,1,1.5c0.2,0.6,0.3,1.2,0.3,1.8
					                c0,0.7-0.1,1.3-0.3,1.9c-0.2,0.6-0.6,1.1-1,1.5c-0.5,0.4-1,0.7-1.7,1c-0.7,0.2-1.5,0.3-2.5,0.3h-2v5.1H622V93.3H626.7z
					                 M626.7,100.5c0.5,0,0.9-0.1,1.3-0.2c0.4-0.1,0.7-0.3,0.9-0.5c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.7,0.2-1.1
					                c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.9-0.5c-0.4-0.1-0.8-0.2-1.3-0.2h-2v5.2H626.7z"></path>
			                </g>
		                </g>
		                <g>
			                <rect x="584.9" y="88.3" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <g>
							                <path className="st28" d="M594,91.9l1.2,1.3c0.4,0.5,1.2-0.2,0.8-0.8l-1.6-1.9c-0.3-0.2-0.6-0.2-0.8,0l-1.6,1.9
								                c-0.5,0.6,0.3,1.3,0.7,0.8L594,91.9L594,91.9z"></path>
							                <path className="st28" d="M599.5,91.9l-1.2-1.3c-0.4-0.5-1.2,0.2-0.8,0.8l1.6,1.9c0.3,0.2,0.6,0.2,0.8,0l1.6-1.9
								                c0.5-0.6-0.3-1.3-0.7-0.8L599.5,91.9L599.5,91.9z"></path>
						                </g>
						                <g>
							                <path className="st28" d="M602,94.1h-10.4c-1.3,0-2.4,1.1-2.4,2.4V107c0,1.3,1.1,2.4,2.4,2.4H602c1.3,0,2.4-1.1,2.4-2.4V96.5
								                C604.4,95.2,603.3,94.1,602,94.1z M602.5,107.3c0,0.2-0.1,0.3-0.3,0.3h-10.9c-0.2,0-0.3-0.1-0.3-0.3V96.5
								                c0-0.2,0.1-0.3,0.3-0.3h10.9c0.2,0,0.3,0.1,0.3,0.3V107.3z"></path>
							                <g>
								                <path className="st28" d="M596.3,100.8c-0.1-1.1-0.4-1.6-2-1.6s-1.9,0.5-2,1.6l-0.2,2c-0.1,0.6,0.6,0.6,0.6,0.2l0.4-2.3
									                c0-0.2,0.3-0.2,0.3,0c-0.1,1.8-0.2,3.9-0.2,6c0,0.6,0.7,0.6,0.8,0.1l0.3-3.4c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l0.3,3.4
									                c0,0.5,0.8,0.5,0.8-0.1c0-2.1-0.1-4.2-0.2-6c0-0.2,0.2-0.2,0.3,0l0.4,2.3c0.1,0.4,0.7,0.4,0.6-0.2L596.3,100.8z"></path>
								                <path className="st28" d="M594.3,98.8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7
									                C593.5,98.5,593.9,98.8,594.3,98.8z"></path>
								                <path className="st28" d="M601.2,100.8c-0.1-1.1-0.4-1.6-2-1.6s-1.9,0.5-2,1.6l-0.2,2c-0.1,0.6,0.6,0.6,0.6,0.2l0.4-2.3
									                c0-0.2,0.3-0.2,0.3,0c-0.1,1.8-0.2,3.9-0.2,6c0,0.6,0.7,0.6,0.8,0.1l0.3-3.4c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l0.3,3.4
									                c0,0.5,0.8,0.5,0.8-0.1c0-2.1-0.1-4.2-0.2-6c0-0.2,0.2-0.2,0.3,0l0.4,2.3c0.1,0.4,0.7,0.4,0.6-0.2L601.2,100.8z"></path>
								                <path className="st28" d="M599.2,98.8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7
									                C598.5,98.5,598.8,98.8,599.2,98.8z"></path>
							                </g>
						                </g>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                
					                <ellipse transform="matrix(0.9982 -6.033323e-02 6.033323e-02 0.9982 -19.7849 21.6575)" className="st4" cx="348.7" cy="338.5" rx="1.4" ry="1.6"></ellipse>
			                </g>
			                <line className="st26" x1="349" y1="338.5" x2="242.3" y2="338.5"></line>
		                </g>
		                <g>
			                
				                <ellipse transform="matrix(0.9982 -6.033323e-02 6.033323e-02 0.9982 -13.4251 19.522)" className="st4" cx="316.6" cy="232.1" rx="1.4" ry="1.6"></ellipse>
		                </g>
		                <polyline className="st26" points="292,135.3 317.9,135.3 316.6,232.1 		"></polyline>
		                <g>
			                
				                <rect x="305.1" y="123.5" transform="matrix(-1 -4.490642e-11 4.490642e-11 -1 633.8627 270.5758)" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <path className="st28" d="M324.2,129.8h-2.4c-0.7,0-1.5,0.3-2,0.8l-1.6,1.6l0-1.2c-0.1-1.3-3.1-1.9-4.9-0.1l-2.3,2.5
							                c-0.6,0.6,0.3,1.6,0.9,1l2.6-2.4l-0.2,4l-2.9,2.9c-0.2,0.2-0.3,0.3-0.8,0.3c0,0-0.8,0-0.8,0c-1.6,0-2.6,1.4-2.6,2.7
							                c0,1.2,1,2.8,2.7,2.8c0,0,1.3,0,2,0c0.7,0,1.5-0.3,2.2-0.9l8.3-8.3c0.2-0.2,0.3-0.2,0.6-0.2c0,0,0.3,0,1.2,0
							                c1,0,2.4-1.1,2.4-2.7C326.7,130.9,325.3,129.8,324.2,129.8z M324.1,133.8h-0.9c-0.7,0-1.1,0.1-1.5,0.5l-8.4,8.3
							                c-0.3,0.3-0.8,0.5-1.3,0.5h-1.9c-0.7,0-1.3-0.6-1.3-1.4c0-0.8,0.6-1.2,1.2-1.2h1.1c0.6,0,0.9-0.2,1.2-0.5l8.5-8.5
							                c0.4-0.3,0.7-0.4,1.3-0.4h2.1c0.7,0,1.2,0.8,1.2,1.3C325.3,132.9,325,133.8,324.1,133.8z"></path>
						                <path className="st28" d="M317.1,128.8c0.6-0.5,0.7-1.3,0.2-1.9c-0.5-0.6-1.3-0.7-1.9-0.2c-0.6,0.5-0.7,1.3-0.2,1.9
							                C315.7,129.1,316.5,129.2,317.1,128.8z"></path>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <rect x="1012.6" y="458.2" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <path className="st28" d="M1024.4,461c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9C1033.4,465,1029.4,461,1024.4,461z M1024.4,464.1
					                c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1s-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4
					                c-0.3-0.3-0.4-0.6-0.4-1S1024.1,464.4,1024.4,464.1z M1023.4,476.2c-1.1,0-1.9-0.9-1.6-2.1l1.3-4.4c0.1-0.4,0.2-0.5,0.2-0.7
					                c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0l0.1-0.5c1.2-0.2,2.4-0.3,3.7-0.4l-1.9,6.5
					                c0,0.1-0.3,1,0.2,1c0.8,0,1.6-0.8,2-1.4l0.8,0.4C1026.2,475.7,1024.5,476.2,1023.4,476.2z"></path>
			                </g>
		                </g>
		                <g>
			                
				                <rect x="499" y="267.7" transform="matrix(-1 -4.490642e-11 4.490642e-11 -1 1021.629 559.1528)" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <path className="st28" d="M518.1,274.1h-2.4c-0.7,0-1.5,0.3-2,0.8l-1.6,1.6l0-1.2c-0.1-1.3-3.1-1.9-4.9-0.1l-2.3,2.5
							                c-0.6,0.6,0.3,1.6,0.9,1l2.6-2.4l-0.2,4l-2.9,2.9c-0.2,0.2-0.3,0.3-0.8,0.3c0,0-0.8,0-0.8,0c-1.6,0-2.6,1.4-2.6,2.7
							                c0,1.2,1,2.8,2.7,2.8c0,0,1.3,0,2,0c0.7,0,1.5-0.3,2.2-0.9l8.3-8.3c0.2-0.2,0.3-0.2,0.6-0.2c0,0,0.3,0,1.2,0
							                c1,0,2.4-1.1,2.4-2.7C520.6,275.1,519.2,274.1,518.1,274.1z M518,278.1h-0.9c-0.7,0-1.1,0.1-1.5,0.5l-8.4,8.3
							                c-0.3,0.3-0.8,0.5-1.3,0.5H504c-0.7,0-1.3-0.6-1.3-1.4c0-0.8,0.6-1.2,1.2-1.2h1.1c0.6,0,0.9-0.2,1.2-0.5l8.5-8.5
							                c0.4-0.3,0.7-0.4,1.3-0.4h2.1c0.7,0,1.2,0.8,1.2,1.3C519.2,277.2,518.8,278.1,518,278.1z"></path>
						                <path className="st28" d="M511,273.1c0.6-0.5,0.7-1.3,0.2-1.9c-0.5-0.6-1.3-0.7-1.9-0.2c-0.6,0.5-0.7,1.3-0.2,1.9
							                C509.5,273.4,510.4,273.5,511,273.1z"></path>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                
				                <rect x="798" y="499.9" transform="matrix(-1 -4.490642e-11 4.490642e-11 -1 1619.6683 1023.4017)" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <path className="st28" d="M817.1,506.2h-2.4c-0.7,0-1.5,0.3-2,0.8l-1.6,1.6l0-1.2c-0.1-1.3-3.1-1.9-4.9-0.1l-2.3,2.5
							                c-0.6,0.6,0.3,1.6,0.9,1l2.6-2.4l-0.2,4l-2.9,2.9c-0.2,0.2-0.3,0.3-0.8,0.3c0,0-0.8,0-0.8,0c-1.6,0-2.6,1.4-2.6,2.7
							                c0,1.2,1,2.8,2.7,2.8c0,0,1.3,0,2,0c0.7,0,1.5-0.3,2.2-0.9l8.3-8.3c0.2-0.2,0.3-0.2,0.6-0.2c0,0,0.3,0,1.2,0
							                c1,0,2.4-1.1,2.4-2.7C819.6,507.3,818.2,506.2,817.1,506.2z M817,510.2h-0.9c-0.7,0-1.1,0.1-1.5,0.5l-8.4,8.3
							                c-0.3,0.3-0.8,0.5-1.3,0.5H803c-0.7,0-1.3-0.6-1.3-1.4c0-0.8,0.6-1.2,1.2-1.2h1.1c0.6,0,0.9-0.2,1.2-0.5l8.5-8.5
							                c0.4-0.3,0.7-0.4,1.3-0.4h2.1c0.7,0,1.2,0.8,1.2,1.3C818.2,509.3,817.9,510.2,817,510.2z"></path>
						                <path className="st28" d="M810,505.2c0.6-0.5,0.7-1.3,0.2-1.9c-0.5-0.6-1.3-0.7-1.9-0.2c-0.6,0.5-0.7,1.3-0.2,1.9
							                C808.6,505.6,809.4,505.7,810,505.2z"></path>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <rect x="783.5" y="307.7" className="st29" width="23.8" height="23.8"></rect>
			                <g>
				                <path className="st28" d="M795.8,312.6c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.3,0.5,1.7,0.9c0.5,0.4,0.8,0.9,1,1.5c0.2,0.6,0.3,1.2,0.3,1.8
					                c0,0.7-0.1,1.3-0.3,1.9c-0.2,0.6-0.6,1.1-1,1.5c-0.5,0.4-1,0.7-1.7,1c-0.7,0.2-1.5,0.3-2.5,0.3h-2v5.1h-2.7v-14.4H795.8z
					                 M795.8,319.9c0.5,0,0.9-0.1,1.3-0.2c0.4-0.1,0.7-0.3,0.9-0.5c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.7,0.2-1.1
					                c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.9-0.5c-0.4-0.1-0.8-0.2-1.3-0.2h-2v5.2H795.8z"></path>
			                </g>
		                </g>
		                <g>
			                
				                <rect x="754" y="339.2" transform="matrix(-1 -4.482959e-11 4.482959e-11 -1 1531.7008 702.1057)" className="st27" width="23.7" height="23.7"></rect>
			                <g id="stairs_3_">
				                <path className="st28" d="M761.3,353.9l0-2.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0c0.4,0,0.6-0.3,0.7-0.6l0-2.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0
					                c0.4,0,0.6-0.3,0.7-0.6l0-2.7c0-0.4,0.3-0.6,0.7-0.6l3.7,0.1c0.4,0,0.6,0.3,0.6,0.7l0,0.6c0,0.4-0.3,0.6-0.7,0.6l-1.7,0
					                c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0
					                c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0c-0.4,0-0.6,0.3-0.7,0.6l0,1.7c0,0.4-0.3,0.6-0.7,0.6l-0.6,0
					                c-0.4,0-0.6-0.3-0.6-0.7l0.1-3.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0C761.1,354.5,761.3,354.2,761.3,353.9z"></path>
			                </g>
		                </g>
		                <g>
			                <rect x="754.1" y="307.7" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <g>
							                <path className="st28" d="M763.2,311.3l1.2,1.3c0.4,0.5,1.2-0.2,0.8-0.8l-1.6-1.9c-0.3-0.2-0.6-0.2-0.8,0l-1.6,1.9
								                c-0.5,0.6,0.3,1.3,0.7,0.8L763.2,311.3L763.2,311.3z"></path>
							                <path className="st28" d="M768.6,311.3l-1.2-1.3c-0.4-0.5-1.2,0.2-0.8,0.8l1.6,1.9c0.3,0.2,0.6,0.2,0.8,0l1.6-1.9
								                c0.5-0.6-0.3-1.3-0.7-0.8L768.6,311.3L768.6,311.3z"></path>
						                </g>
						                <g>
							                <path className="st28" d="M771.1,313.5h-10.4c-1.3,0-2.4,1.1-2.4,2.4v10.4c0,1.3,1.1,2.4,2.4,2.4h10.4c1.3,0,2.4-1.1,2.4-2.4
								                v-10.4C773.5,314.6,772.5,313.5,771.1,313.5z M771.7,326.7c0,0.2-0.1,0.3-0.3,0.3h-10.9c-0.2,0-0.3-0.1-0.3-0.3v-10.8
								                c0-0.2,0.1-0.3,0.3-0.3h10.9c0.2,0,0.3,0.1,0.3,0.3V326.7z"></path>
							                <g>
								                <path className="st28" d="M765.5,320.2c-0.1-1.1-0.4-1.6-2-1.6s-1.9,0.5-2,1.6l-0.2,2c-0.1,0.6,0.6,0.6,0.6,0.2l0.4-2.3
									                c0-0.2,0.3-0.2,0.3,0c-0.1,1.8-0.2,3.9-0.2,6c0,0.6,0.7,0.6,0.8,0.1l0.3-3.4c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l0.3,3.4
									                c0,0.5,0.8,0.5,0.8-0.1c0-2.1-0.1-4.2-0.2-6c0-0.2,0.2-0.2,0.3,0l0.4,2.3c0.1,0.4,0.7,0.4,0.6-0.2L765.5,320.2z"></path>
								                <path className="st28" d="M763.5,318.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7
									                C762.7,317.8,763.1,318.2,763.5,318.2z"></path>
								                <path className="st28" d="M770.4,320.2c-0.1-1.1-0.4-1.6-2-1.6s-1.9,0.5-2,1.6l-0.2,2c-0.1,0.6,0.6,0.6,0.6,0.2l0.4-2.3
									                c0-0.2,0.3-0.2,0.3,0c-0.1,1.8-0.2,3.9-0.2,6c0,0.6,0.7,0.6,0.8,0.1l0.3-3.4c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l0.3,3.4
									                c0,0.5,0.8,0.5,0.8-0.1c0-2.1-0.1-4.2-0.2-6c0-0.2,0.2-0.2,0.3,0l0.4,2.3c0.1,0.4,0.7,0.4,0.6-0.2L770.4,320.2z"></path>
								                <path className="st28" d="M768.4,318.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7
									                C767.6,317.8,768,318.2,768.4,318.2z"></path>
							                </g>
						                </g>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="755" y="461.8" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <g>
					                <g>
						                <path className="st28" d="M761.8,468c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2
							                C760.6,467.4,761.1,468,761.8,468z"></path>
						                <circle className="st28" cx="771.9" cy="466.8" r="1.2"></circle>
						                <path className="st28" d="M775.7,474.4l-1-4.2c-0.3-1-1.2-1.6-2.8-1.6c-1.6,0-2.5,0.5-2.8,1.6l-1,4.2c-0.2,0.7,0.9,1,1,0.3l1.1-4
							                c0.1-0.2,0.4-0.3,0.4,0.1c0,0.4-1,4.4-1.2,5.4l-0.3,1.5c0,0.2,0.3,0.4,1,0.4c0.1,1,0.2,1.9,0.2,2.7c0.1,0.6,1.1,0.5,1.1,0
							                l0.1-2.6h0.6l0.1,2.6c0,0.5,1,0.6,1.1,0c0.1-0.7,0.2-1.7,0.2-2.7c0.6-0.1,1-0.2,1-0.4l-0.3-1.5c-0.2-1-1.2-5-1.2-5.4
							                c0-0.4,0.3-0.3,0.4-0.1l1.1,4C774.9,475.4,775.9,475.1,775.7,474.4z"></path>
						                <path className="st28" d="M764.9,471.1c-0.2-1.8-0.7-2.6-3.1-2.6s-3,0.8-3.2,2.6l-0.4,3.2c-0.1,0.9,0.9,0.9,1,0.3l0.6-3.7
							                c0.1-0.3,0.4-0.3,0.4,0c-0.2,2.9-0.3,6.2-0.3,9.5c0,0.9,1.1,1,1.2,0.1l0.5-5.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.2,0.2
							                l0.5,5.3c0.1,0.8,1.2,0.8,1.2-0.1c0-3.3-0.2-6.6-0.3-9.5c0-0.3,0.4-0.3,0.4,0l0.6,3.7c0.1,0.7,1.1,0.6,1-0.3L764.9,471.1z"></path>
						                <path className="st28" d="M766.7,465.6c-0.6,0-0.6,0-0.6,0.6v14.2c0,0.6,0,0.6,0.6,0.6c0.6,0,0.6,0,0.6-0.6v-14.2
							                C767.3,465.6,767.3,465.6,766.7,465.6z"></path>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <rect x="231" y="326.7" className="st27" width="23.7" height="23.7"></rect>
			                <g>
				                <g>
					                <g>
						                <g>
							                <path className="st28" d="M240.1,330.2l1.2,1.3c0.4,0.5,1.2-0.2,0.8-0.8l-1.6-1.9c-0.3-0.2-0.6-0.2-0.8,0l-1.6,1.9
								                c-0.5,0.6,0.3,1.3,0.7,0.8L240.1,330.2L240.1,330.2z"></path>
							                <path className="st28" d="M245.5,330.2l-1.2-1.3c-0.4-0.5-1.2,0.2-0.8,0.8l1.6,1.9c0.3,0.2,0.6,0.2,0.8,0l1.6-1.9
								                c0.5-0.6-0.3-1.3-0.7-0.8L245.5,330.2L245.5,330.2z"></path>
						                </g>
						                <g>
							                <path className="st28" d="M248,332.5h-10.4c-1.3,0-2.4,1.1-2.4,2.4v10.4c0,1.3,1.1,2.4,2.4,2.4H248c1.3,0,2.4-1.1,2.4-2.4v-10.4
								                C250.4,333.5,249.3,332.5,248,332.5z M248.5,345.6c0,0.2-0.1,0.3-0.3,0.3h-10.9c-0.2,0-0.3-0.1-0.3-0.3v-10.8
								                c0-0.2,0.1-0.3,0.3-0.3h10.9c0.2,0,0.3,0.1,0.3,0.3V345.6z"></path>
							                <g>
								                <path className="st28" d="M242.3,339.1c-0.1-1.1-0.4-1.6-2-1.6s-1.9,0.5-2,1.6l-0.2,2c-0.1,0.6,0.6,0.6,0.6,0.2l0.4-2.3
									                c0-0.2,0.3-0.2,0.3,0c-0.1,1.8-0.2,3.9-0.2,6c0,0.6,0.7,0.6,0.8,0.1l0.3-3.4c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l0.3,3.4
									                c0,0.5,0.8,0.5,0.8-0.1c0-2.1-0.1-4.2-0.2-6c0-0.2,0.2-0.2,0.3,0l0.4,2.3c0.1,0.4,0.7,0.4,0.6-0.2L242.3,339.1z"></path>
								                <path className="st28" d="M240.3,337.1c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7
									                C239.6,336.8,239.9,337.1,240.3,337.1z"></path>
								                <path className="st28" d="M247.2,339.1c-0.1-1.1-0.4-1.6-2-1.6s-1.9,0.5-2,1.6l-0.2,2c-0.1,0.6,0.6,0.6,0.6,0.2l0.4-2.3
									                c0-0.2,0.3-0.2,0.3,0c-0.1,1.8-0.2,3.9-0.2,6c0,0.6,0.7,0.6,0.8,0.1l0.3-3.4c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l0.3,3.4
									                c0,0.5,0.8,0.5,0.8-0.1c0-2.1-0.1-4.2-0.2-6c0-0.2,0.2-0.2,0.3,0l0.4,2.3c0.1,0.4,0.7,0.4,0.6-0.2L247.2,339.1z"></path>
								                <path className="st28" d="M245.2,337.1c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7
									                C244.5,336.8,244.8,337.1,245.2,337.1z"></path>
							                </g>
						                </g>
					                </g>
				                </g>
			                </g>
		                </g>
		                <g>
			                <rect x="178.2" y="326.6" className="st29" width="23.8" height="23.8"></rect>
			                <g>
				                <path className="st28" d="M190.5,331.5c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.3,0.5,1.7,0.9c0.5,0.4,0.8,0.9,1,1.5c0.2,0.6,0.3,1.2,0.3,1.8
					                c0,0.7-0.1,1.3-0.3,1.9c-0.2,0.6-0.6,1.1-1,1.5c-0.5,0.4-1,0.7-1.7,1c-0.7,0.2-1.5,0.3-2.5,0.3h-2v5.1h-2.7v-14.4H190.5z
					                 M190.5,338.7c0.5,0,0.9-0.1,1.3-0.2c0.4-0.1,0.7-0.3,0.9-0.5c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.7,0.2-1.1
					                c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.9-0.5c-0.4-0.1-0.8-0.2-1.3-0.2h-2v5.2H190.5z"></path>
			                </g>
		                </g>
		                <g>
			                <rect x="638.1" y="88.3" className="st30" width="23.8" height="23.8"></rect>
			                <g>
				                <path className="st28" d="M650.2,93.8c0.8,0,1.5,0.1,2,0.2c0.6,0.2,1,0.4,1.4,0.6c0.4,0.3,0.6,0.6,0.8,1c0.2,0.4,0.3,0.8,0.3,1.3
					                c0,0.3,0,0.6-0.1,0.8c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.5c1.7,0.4,2.5,1.3,2.5,2.7
					                c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.8-0.9,1.1c-0.4,0.3-0.8,0.6-1.4,0.7c-0.6,0.2-1.2,0.3-1.9,0.3H646V93.8H650.2z M648.3,95.6
					                v3.5h1.8c0.8,0,1.4-0.1,1.8-0.4c0.4-0.3,0.6-0.7,0.6-1.3c0-0.6-0.2-1.1-0.5-1.4c-0.4-0.3-0.9-0.4-1.7-0.4H648.3z M650.6,104.2
					                c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.3-0.4,0.4-0.6s0.1-0.5,0.1-0.7c0-0.3,0-0.5-0.1-0.7
					                c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.3-0.7-0.3c-0.3-0.1-0.6-0.1-1-0.1h-2.3v3.6H650.6z"></path>
			                </g>
		                </g>
		                <g>
			                <rect x="807.3" y="307.7" className="st31" width="23.8" height="23.8"></rect>
			                <g>
				                <path className="st28" d="M825.3,325.3h-1.8c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.4l-0.9-2.5h-5l-0.9,2.5
					                c0,0.1-0.1,0.2-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2h-1.8l4.8-12.2h2.3L825.3,325.3z M821.3,320.7l-1.5-4.1
					                c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.2,0.5-0.2,0.7l-1.5,4H821.3z"></path>
			                </g>
		                </g>
		                <g>
			                <rect x="202" y="326.6" className="st32" width="23.7" height="23.7"></rect>
			                <g>
				                <path className="st28" d="M218.1,341.3c0.1,0,0.2,0,0.3,0.1l0.9,1c-0.5,0.6-1.1,1.1-1.8,1.4c-0.7,0.3-1.6,0.5-2.6,0.5
					                c-0.9,0-1.7-0.2-2.4-0.5c-0.7-0.3-1.3-0.7-1.8-1.3c-0.5-0.5-0.9-1.2-1.2-2c-0.3-0.8-0.4-1.6-0.4-2.5c0-0.9,0.1-1.7,0.4-2.5
					                c0.3-0.8,0.7-1.4,1.2-2c0.5-0.5,1.2-1,1.9-1.3c0.7-0.3,1.5-0.5,2.4-0.5c0.9,0,1.7,0.1,2.4,0.4c0.7,0.3,1.3,0.7,1.8,1.1l-0.8,1.1
					                c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2
					                c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2,0-0.5-0.1-0.8-0.1c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.8,0.5-1.2,0.8
					                c-0.3,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8c0.2,0.5,0.5,1,0.8,1.3c0.3,0.4,0.7,0.6,1.2,0.8
					                c0.4,0.2,0.9,0.3,1.4,0.3c0.3,0,0.6,0,0.8-0.1c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.3,0.6-0.4
					                c0.1-0.1,0.1-0.1,0.2-0.1C218,341.3,218,341.3,218.1,341.3z"></path>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="898.3" y="348.7" className="st27" width="23.8" height="23.8"></rect>
			                </g>
			                <path className="st28" d="M917.7,358.9H917l-0.5-4c-0.2-0.9-0.8-1.3-1.7-1.7c-0.9-0.4-3-0.8-4.6-0.8c-1.6,0-3.7,0.5-4.6,0.8
				                c-0.9,0.4-1.5,0.8-1.7,1.7l-0.5,4h-0.7c-0.9,0-0.8,2.6-0.2,2.8l0.7,0.3v5.3v1.4c0,0.4,0.3,0.8,0.8,0.8h0.8c0.4,0,0.8-0.3,0.8-0.8
				                v-1.1c2.5,0.1,6.9,0.1,9.3,0v1.1c0,0.4,0.3,0.8,0.8,0.8h0.7c0.4,0,0.8-0.3,0.8-0.8v-1.4V362l0.7-0.3
				                C918.5,361.5,918.6,358.9,917.7,358.9z M907.3,353.7h2.8h2.9c0.6,0,0.6,0.8,0,0.8h-3h-2.8C906.7,354.6,906.7,353.7,907.3,353.7z
				                 M905.7,366.5c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2C906.9,366,906.4,366.5,905.7,366.5z
				                 M914.6,366.5c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2C915.8,366,915.3,366.5,914.6,366.5z
				                 M914.9,363.1h-4.8h-4.7c-0.5,0-0.6-0.4-0.6-0.7l0.1-6.2c0.1-0.5,0.2-0.8,0.8-0.8h4.3h4.5c0.6,0,0.8,0.3,0.8,0.8l0.1,6.2
				                C915.5,362.7,915.4,363.1,914.9,363.1z"></path>
		                </g>
		                <g>
			                
				                <rect x="585.2" y="120.2" transform="matrix(-1 -4.479140e-11 4.479140e-11 -1 1194.0204 264.0755)" className="st27" width="23.7" height="23.7"></rect>
			                <g id="stairs_4_">
				                <path className="st28" d="M592.5,134.8l0-2.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0c0.4,0,0.6-0.3,0.7-0.6l0-2.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0
					                c0.4,0,0.6-0.3,0.7-0.6l0-2.7c0-0.4,0.3-0.6,0.7-0.6l3.7,0.1c0.4,0,0.6,0.3,0.6,0.7l0,0.6c0,0.4-0.3,0.6-0.7,0.6l-1.7,0
					                c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0
					                c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0c-0.4,0-0.6,0.3-0.7,0.6l0,1.7c0,0.4-0.3,0.6-0.7,0.6l-0.6,0
					                c-0.4,0-0.6-0.3-0.6-0.7l0.1-3.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0C592.2,135.5,592.5,135.2,592.5,134.8z"></path>
			                </g>
		                </g>
		                <g>
			                
				                <rect x="272.9" y="123.5" transform="matrix(-1 -4.486823e-11 4.486823e-11 -1 569.397 270.5758)" className="st27" width="23.7" height="23.7"></rect>
			                <g id="stairs_1_">
				                <path className="st28" d="M280.2,138.1l0-2.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0c0.4,0,0.6-0.3,0.7-0.6l0-2.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0
					                c0.4,0,0.6-0.3,0.7-0.6l0-2.7c0-0.4,0.3-0.6,0.7-0.6l3.7,0.1c0.4,0,0.6,0.3,0.6,0.7l0,0.6c0,0.4-0.3,0.6-0.7,0.6l-1.7,0
					                c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0
					                c-0.4,0-0.6,0.3-0.7,0.6l0,2.7c0,0.4-0.3,0.6-0.7,0.6l-2.7,0c-0.4,0-0.6,0.3-0.7,0.6l0,1.7c0,0.4-0.3,0.6-0.7,0.6l-0.6,0
					                c-0.4,0-0.6-0.3-0.6-0.7l0.1-3.7c0-0.4,0.3-0.6,0.7-0.6l2.7,0C279.9,138.7,280.2,138.5,280.2,138.1z"></path>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="585.2" y="152.7" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <path className="st28" d="M604.1,158.2v0.9c0,0,0,0,0,0.1c-0.3,0.7-0.8,1.2-1.6,1.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.3,0,0.4
				                c0,1.8,0,3.5,0,5.3c0,0.8-0.5,1.5-1.2,1.5c-1,0-2,0-3,0c-0.2,0-0.3,0-0.4-0.2c-0.2-0.2-0.2-0.4-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3
				                c0.5,0,0.5,0,0.5-0.5c0-2.4,0-4.7,0-7.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.8,0-1.7,0-2.5,0c-0.2,0-0.5-0.1-0.6,0.1
				                c-0.1,0.2-0.1,0.4-0.1,0.7c0,2.1,0,4.1,0,6.2c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.6-0.3-0.6-0.8c0-0.2,0-0.5,0-0.7
				                c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0,0.7,0,1c0,0.2,0.1,0.3,0.2,0.4c0.3,0.1,0.6,0.3,0.9,0.4
				                c0.3,0.1,0.5,0.1,0.6-0.3c0.2-0.4,0.3-0.9,0.5-1.3c0.2-0.6,0.6-1.1,1.2-1.3c0.7-0.2,1.1,0.1,1.1,0.8c0,0.2-0.1,0.4-0.1,0.6
				                c-0.4,1.2-0.7,2.3-1,3.5c-0.2,0.8-0.4,1.7-1.1,2.3c-0.6,0.5-1.1,1.1-1.7,1.6H590c-0.1-0.1-0.1-0.2-0.1-0.3c0-2.2,0-4.5,0-6.7
				                c0-0.8,0.3-1.6,0.8-2.2c0.9-1,1.9-1.9,2.8-2.8c0.2-0.2,0.2-0.4,0.2-0.6c0-0.7,0-0.6-0.6-0.8c-0.8-0.2-1.4-1-1.4-1.8
				                c0.1-1,0.6-1.6,1.6-1.7c0.9-0.1,1.9,0,2.8,0c2,0,3.9,0,5.9,0c0.4,0,0.9,0.1,1.2,0.4C603.8,157.5,603.9,157.8,604.1,158.2
				                 M600,162.7c0,1.2,0,2.5,0,3.7c0,0.1,0,0.3,0.1,0.3c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.3
				                c0-2.3,0-4.6,0-6.9c0-0.4-0.1-0.4-0.4-0.4c-0.1,0-0.1,0-0.2,0c-0.4,0-0.4,0-0.4,0.4C600,160.3,600,161.5,600,162.7"></path>
		                </g>
		                <g>
			                <g>
				                <rect x="264.3" y="326.9" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <path className="st28" d="M283.2,332.4v0.9c0,0,0,0,0,0.1c-0.3,0.7-0.8,1.2-1.6,1.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.3,0,0.4
				                c0,1.8,0,3.5,0,5.3c0,0.8-0.5,1.5-1.2,1.5c-1,0-2,0-3,0c-0.2,0-0.3,0-0.4-0.2c-0.2-0.2-0.2-0.4-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3
				                c0.5,0,0.5,0,0.5-0.5c0-2.4,0-4.7,0-7.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.8,0-1.7,0-2.5,0c-0.2,0-0.5-0.1-0.6,0.1
				                c-0.1,0.2-0.1,0.4-0.1,0.7c0,2.1,0,4.1,0,6.2c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.6-0.3-0.6-0.8c0-0.2,0-0.5,0-0.7
				                c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0,0.7,0,1c0,0.2,0.1,0.3,0.2,0.4c0.3,0.1,0.6,0.3,0.9,0.4
				                c0.3,0.1,0.5,0.1,0.6-0.3c0.2-0.4,0.3-0.9,0.5-1.3c0.2-0.6,0.6-1.1,1.2-1.3c0.7-0.2,1.1,0.1,1.1,0.8c0,0.2-0.1,0.4-0.1,0.6
				                c-0.4,1.2-0.7,2.3-1,3.5c-0.2,0.8-0.4,1.7-1.1,2.3c-0.6,0.5-1.1,1.1-1.7,1.6h-3.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-2.2,0-4.5,0-6.7
				                c0-0.8,0.3-1.6,0.8-2.2c0.9-1,1.9-1.9,2.8-2.8c0.2-0.2,0.2-0.4,0.2-0.6c0-0.7,0-0.6-0.6-0.8c-0.8-0.2-1.4-1-1.4-1.8
				                c0.1-1,0.6-1.6,1.6-1.7c0.9-0.1,1.9,0,2.8,0c2,0,3.9,0,5.9,0c0.4,0,0.9,0.1,1.2,0.4C282.9,331.6,283.1,332,283.2,332.4
				                 M279.2,336.9c0,1.2,0,2.5,0,3.7c0,0.1,0,0.3,0.1,0.3c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.3
				                c0-2.3,0-4.6,0-6.9c0-0.4-0.1-0.4-0.4-0.4c-0.1,0-0.1,0-0.2,0c-0.4,0-0.4,0-0.4,0.4C279.2,334.4,279.2,335.7,279.2,336.9"></path>
		                </g>
		                <g>
			                <g>
				                <rect x="1012.9" y="425.7" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <path className="st28" d="M1031.8,431.2v0.9c0,0,0,0,0,0.1c-0.3,0.7-0.8,1.2-1.6,1.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.3,0,0.4
				                c0,1.8,0,3.5,0,5.3c0,0.8-0.5,1.5-1.2,1.5c-1,0-2,0-3,0c-0.2,0-0.3,0-0.4-0.2c-0.2-0.2-0.2-0.4-0.1-0.7c0.1-0.2,0.3-0.3,0.5-0.3
				                c0.5,0,0.5,0,0.5-0.5c0-2.4,0-4.7,0-7.1c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.8,0-1.7,0-2.5,0c-0.2,0-0.5-0.1-0.6,0.1
				                c-0.1,0.2-0.1,0.4-0.1,0.7c0,2.1,0,4.1,0,6.2c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.6-0.3-0.6-0.8c0-0.2,0-0.5,0-0.7
				                c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0,0.7,0,1c0,0.2,0.1,0.3,0.2,0.4c0.3,0.1,0.6,0.3,0.9,0.4
				                c0.3,0.1,0.5,0.1,0.6-0.3c0.2-0.4,0.3-0.9,0.5-1.3c0.2-0.6,0.6-1.1,1.2-1.3c0.7-0.2,1.1,0.1,1.1,0.8c0,0.2-0.1,0.4-0.1,0.6
				                c-0.4,1.2-0.7,2.3-1,3.5c-0.2,0.8-0.4,1.7-1.1,2.3c-0.6,0.5-1.1,1.1-1.7,1.6h-3.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-2.2,0-4.5,0-6.7
				                c0-0.8,0.3-1.6,0.8-2.2c0.9-1,1.9-1.9,2.8-2.8c0.2-0.2,0.2-0.4,0.2-0.6c0-0.7,0-0.6-0.6-0.8c-0.8-0.2-1.4-1-1.4-1.8
				                c0.1-1,0.6-1.6,1.6-1.7c0.9-0.1,1.9,0,2.8,0c2,0,3.9,0,5.9,0c0.5,0,0.9,0.1,1.2,0.4C1031.5,430.5,1031.7,430.9,1031.8,431.2
				                 M1027.8,435.7c0,1.2,0,2.5,0,3.7c0,0.1,0,0.3,0.1,0.3c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.3
				                c0-2.3,0-4.6,0-6.9c0-0.4-0.1-0.4-0.4-0.4c-0.1,0-0.1,0-0.2,0c-0.4,0-0.4,0-0.4,0.4C1027.8,433.3,1027.8,434.5,1027.8,435.7"></path>
		                </g>
		                <g>
			                <g>
				                <rect x="1012.6" y="392.7" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <g>
					                <path className="st28" d="M1025.2,396.1c-0.1,0.6,0.1,1.1,0.4,1.5c1,1.6,1.6,3.3,1.8,5.2c0.1,0.7,0,1.4,0.1,2.1c0,0,0,0.1,0,0.1
						                c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.2-0.8-0.2-1.2,0c-0.3,0.2-0.6,0.3-0.9,0.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1
						                c0,2.1,0,4.2,0,6.2c0,0.5,0.2,0.8,0.6,0.9c0.6,0.2,1.1-0.2,1.2-0.8c0-0.4,0-0.8,0-1.2c0-0.1,0-0.1,0-0.2c0.4,0,0.7,0,1.1,0
						                c0,0.6,0,1.2-0.1,1.8c-0.2,1-1.1,1.7-2.1,1.6c-1-0.1-1.8-0.9-1.8-2c0-2,0-4.1,0-6.1c0-0.2,0-0.3-0.2-0.3
						                c-0.2-0.1-0.4-0.2-0.6-0.3c-0.5-0.3-0.9-0.3-1.4,0c0,0,0,0-0.1,0c0,0,0-0.1,0-0.1c-0.1-1.6,0.1-3.3,0.7-4.8
						                c0.4-1.1,0.9-2.1,1.5-3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.5,0-0.7C1024.5,396.1,1024.8,396.1,1025.2,396.1z"></path>
					                <path className="st28" d="M1015.8,404.2c0.1-0.3,0.1-0.7,0.2-1c0.4-1.6,1.2-2.9,2.4-3.9c1.2-1.1,2.6-1.7,4.1-2.1
						                c0.2,0,0.4-0.1,0.7-0.1c-2,2.5-2.5,5.4-2.4,8.5c-0.5,0.1-1,0-1.5-0.3c-0.2-0.2-0.5-0.3-0.8-0.3c-0.2,0-0.5,0-0.6,0.2
						                c-0.6,0.5-1.4,0.6-2.1,0.5C1015.8,405.1,1015.8,404.6,1015.8,404.2z"></path>
					                <path className="st28" d="M1033.6,405.6c-0.6,0-1.2,0-1.7-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.3-0.9-0.3-1.4,0
						                c-0.5,0.3-1,0.5-1.5,0.5c0,0-0.1,0-0.1,0c0.2-3.1-0.4-6-2.4-8.5C1030,397.5,1033.9,400.9,1033.6,405.6z"></path>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="614.4" y="185.2" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <path className="st28" d="M626.3,193.6c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2
					                C624,192.6,625,193.6,626.3,193.6"></path>
				                <path className="st28" d="M626.3,198.3h-2.4v-1.7l-1.8,1.8c-0.8,0.8-1.9-0.2-1.1-1l3-3c0.2-0.2,0.5-0.4,0.9-0.4h1.4h1.3
					                c0.4,0,0.7,0.2,0.9,0.4l3,3c0.8,0.8-0.4,1.8-1.1,1l-1.9-1.9v1.7H626.3z"></path>
				                <path className="st28" d="M628.6,199.2l-1.8,1.8l1.3,1.3l-1.3,1.3c-0.8,0.8,0.3,1.9,1.1,1.1l2.1-2.2c0.5-0.5,0.6-1.3,0.1-1.8
					                C630.1,200.7,628.6,199.2,628.6,199.2"></path>
				                <path className="st28" d="M623.9,199.2l1.8,1.8l-1.3,1.3l1.3,1.3c0.8,0.8-0.3,1.9-1.1,1.1l-2.1-2.2c-0.5-0.5-0.6-1.3-0.1-1.8
					                C622.4,200.7,623.9,199.2,623.9,199.2"></path>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="782.8" y="461.8" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <path className="st28" d="M794.6,470.2c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2
					                C792.4,469.2,793.4,470.2,794.6,470.2"></path>
				                <path className="st28" d="M794.6,474.9h-2.4v-1.7l-1.8,1.8c-0.8,0.8-1.9-0.2-1.1-1l3-3c0.2-0.2,0.5-0.4,0.9-0.4h1.4h1.3
					                c0.4,0,0.7,0.2,0.9,0.4l3,3c0.8,0.8-0.4,1.8-1.1,1l-1.9-1.9v1.7H794.6z"></path>
				                <path className="st28" d="M797,475.8l-1.8,1.8l1.3,1.3l-1.3,1.3c-0.8,0.8,0.3,1.9,1.1,1.1l2.1-2.2c0.5-0.5,0.6-1.3,0.1-1.8
					                C798.5,477.3,797,475.8,797,475.8"></path>
				                <path className="st28" d="M792.3,475.8l1.8,1.8l-1.3,1.3l1.3,1.3c0.8,0.8-0.3,1.9-1.1,1.1l-2.1-2.2c-0.5-0.5-0.6-1.3-0.1-1.8
					                C790.8,477.3,792.3,475.8,792.3,475.8"></path>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="667.4" y="88.3" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <g>
					                <path className="st28" d="M688.3,96.4c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.4-0.7,0.6-1.2,0.6c-0.1,0-0.3,0-0.5,0c0,0.1,0,0.1,0,0.2
						                c0,2,0,4.1,0,6.1c0,0.6-0.3,1.1-0.8,1.4c-0.8,0.4-2-0.1-2.1-1.1c0-0.1,0-0.3,0-0.4c0-0.9,0-1.8,0-2.7c0-0.4-0.2-0.6-0.6-0.6
						                c-0.5,0-0.9,0-1.4,0c0,1.7,0,3.3,0,4.9c-3,0-5.9,0-8.9,0c0-2.3,0-4.6,0-6.9c3,0,5.9,0,8.9,0c0,0.3,0,0.6,0,1c0.1,0,0.1,0,0.2,0
						                c0.4,0,0.8,0,1.2,0c1,0,1.6,0.6,1.6,1.6c0,0.9,0,1.8,0,2.7c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.4,0.5,0.4c0.2,0,0.5-0.2,0.5-0.4
						                c0,0,0-0.1,0-0.1c0-2.1,0-4.1,0-6.2c0,0,0-0.1,0-0.2c-0.2,0-0.4,0-0.6,0c-0.8-0.1-1.3-0.7-1.3-1.5c0-0.7,0-1.3,0-2
						                c0-0.3,0.2-0.5,0.5-0.5c0.2,0,0.3,0,0.5,0c0-0.4,0-0.8,0-1.2c0-0.1,0-0.2,0-0.3c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
						                c0,0.5,0,0.9,0,1.4c0,0,0,0.1,0,0.1c0.3,0,0.6,0,1,0c0-0.1,0-0.2,0-0.3c0-0.4,0-0.8,0-1.1c0-0.3,0.2-0.5,0.5-0.5
						                c0.3,0,0.5,0.2,0.5,0.5c0,0.4,0,0.9,0,1.3c0,0.1,0,0.1,0,0.2c0.4,0,0.8-0.1,1,0.4C688.3,94.9,688.3,95.6,688.3,96.4z
						                 M677.1,102.6c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.3,0,0.5,0.2,0.7c0.2,0.1,0.5,0,0.7-0.2
						                c0.3-0.7,0.7-1.3,1-2c0.2-0.3-0.1-0.7-0.4-0.7c-0.3,0-0.7,0-1,0c0,0-0.1,0-0.2,0c0-0.1,0.1-0.1,0.1-0.2
						                c0.2-0.4,0.4-0.7,0.5-1.1c0.1-0.3,0-0.5-0.2-0.7c-0.2-0.1-0.5,0-0.6,0.2c-0.3,0.7-0.7,1.3-1,2c-0.2,0.3,0.1,0.7,0.4,0.7
						                c0.1,0,0.2,0,0.2,0C676.5,102.6,676.8,102.6,677.1,102.6z"></path>
					                <path className="st28" d="M671.9,108.6c-0.3-0.1-0.4-0.4-0.4-0.7c0-0.1,0-0.2,0-0.3c0-0.6,0.4-1,1-1c0,0,0,0,0.1,0
						                c2.9,0,5.8,0,8.7,0c0.4,0,0.8,0.1,1,0.5c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.4,0.7
						                C678.6,108.6,675.3,108.6,671.9,108.6z"></path>
					                <path className="st28" d="M681.4,97.7c-3,0-5.9,0-8.9,0c0-0.1,0-0.1,0-0.2c0-1.4,0-2.8,0-4.2c0-0.6,0.3-1.1,0.8-1.4
						                c0.2-0.1,0.5-0.2,0.7-0.2c1.9,0,3.9,0,5.8,0c0.8,0,1.5,0.6,1.5,1.5C681.4,94.7,681.4,96.2,681.4,97.7z M676.9,93.8
						                c-0.8,0-1.6,0-2.4,0c-0.4,0-0.6,0.2-0.6,0.6c0,0.6,0,1.2,0,1.8c0,0.4,0.2,0.6,0.6,0.6c1.6,0,3.2,0,4.8,0c0.4,0,0.6-0.2,0.6-0.6
						                c0-0.6,0-1.2,0-1.8c0-0.4-0.2-0.6-0.6-0.6C678.5,93.8,677.7,93.8,676.9,93.8z"></path>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="147.9" y="326.6" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <g>
					                <path className="st28" d="M168.8,334.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.4-0.7,0.6-1.2,0.6c-0.1,0-0.3,0-0.5,0c0,0.1,0,0.1,0,0.2
						                c0,2,0,4.1,0,6.1c0,0.6-0.3,1.1-0.8,1.4c-0.8,0.4-2-0.1-2.1-1.1c0-0.1,0-0.3,0-0.4c0-0.9,0-1.8,0-2.7c0-0.4-0.2-0.6-0.6-0.6
						                c-0.5,0-0.9,0-1.4,0c0,1.7,0,3.3,0,4.9c-3,0-5.9,0-8.9,0c0-2.3,0-4.6,0-6.9c3,0,5.9,0,8.9,0c0,0.3,0,0.6,0,1c0.1,0,0.1,0,0.2,0
						                c0.4,0,0.8,0,1.2,0c1,0,1.6,0.6,1.6,1.6c0,0.9,0,1.8,0,2.7c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.4,0.5,0.4c0.2,0,0.5-0.2,0.5-0.4
						                c0,0,0-0.1,0-0.1c0-2.1,0-4.1,0-6.2c0,0,0-0.1,0-0.2c-0.2,0-0.4,0-0.6,0c-0.8-0.1-1.3-0.7-1.3-1.5c0-0.7,0-1.3,0-2
						                c0-0.3,0.2-0.5,0.5-0.5c0.2,0,0.3,0,0.5,0c0-0.4,0-0.8,0-1.2c0-0.1,0-0.2,0-0.3c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
						                c0,0.5,0,0.9,0,1.4c0,0,0,0.1,0,0.1c0.3,0,0.6,0,1,0c0-0.1,0-0.2,0-0.3c0-0.4,0-0.8,0-1.1c0-0.3,0.2-0.5,0.5-0.5
						                c0.3,0,0.5,0.2,0.5,0.5c0,0.4,0,0.9,0,1.3c0,0.1,0,0.1,0,0.2c0.4,0,0.8-0.1,1,0.4C168.8,333.1,168.8,333.9,168.8,334.7z
						                 M157.6,340.9c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.3,0,0.5,0.2,0.7c0.2,0.1,0.5,0,0.7-0.2
						                c0.3-0.7,0.7-1.3,1-2c0.2-0.3-0.1-0.7-0.4-0.7c-0.3,0-0.7,0-1,0c0,0-0.1,0-0.2,0c0-0.1,0.1-0.1,0.1-0.2
						                c0.2-0.4,0.4-0.7,0.5-1.1c0.1-0.3,0-0.5-0.2-0.7c-0.2-0.1-0.5,0-0.6,0.2c-0.3,0.7-0.7,1.3-1,2c-0.2,0.3,0.1,0.7,0.4,0.7
						                c0.1,0,0.2,0,0.2,0C157,340.9,157.3,340.9,157.6,340.9z"></path>
					                <path className="st28" d="M152.4,346.8c-0.3-0.1-0.4-0.4-0.4-0.7c0-0.1,0-0.2,0-0.3c0-0.6,0.4-1,1-1c0,0,0,0,0.1,0
						                c2.9,0,5.8,0,8.7,0c0.4,0,0.8,0.1,1,0.5c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.4,0.7
						                C159.1,346.8,155.7,346.8,152.4,346.8z"></path>
					                <path className="st28" d="M161.9,336c-3,0-5.9,0-8.9,0c0-0.1,0-0.1,0-0.2c0-1.4,0-2.8,0-4.2c0-0.6,0.3-1.1,0.8-1.4
						                c0.2-0.1,0.5-0.2,0.7-0.2c1.9,0,3.9,0,5.8,0c0.8,0,1.5,0.6,1.5,1.5C161.9,333,161.9,334.5,161.9,336z M157.4,332
						                c-0.8,0-1.6,0-2.4,0c-0.4,0-0.6,0.2-0.6,0.6c0,0.6,0,1.2,0,1.8c0,0.4,0.2,0.6,0.6,0.6c1.6,0,3.2,0,4.8,0c0.4,0,0.6-0.2,0.6-0.6
						                c0-0.6,0-1.2,0-1.8c0-0.4-0.2-0.6-0.6-0.6C159,332,158.2,332,157.4,332z"></path>
				                </g>
			                </g>
		                </g>
		                <g>
			                <g>
				                <rect x="837.2" y="307.8" className="st27" width="23.7" height="23.7"></rect>
			                </g>
			                <g>
				                <g>
					                <path className="st28" d="M858.1,315.8c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.4-0.7,0.6-1.2,0.6c-0.1,0-0.3,0-0.5,0c0,0.1,0,0.1,0,0.2
						                c0,2,0,4.1,0,6.1c0,0.6-0.3,1.1-0.8,1.4c-0.8,0.4-2-0.1-2.1-1.1c0-0.1,0-0.3,0-0.4c0-0.9,0-1.8,0-2.7c0-0.4-0.2-0.6-0.6-0.6
						                c-0.5,0-0.9,0-1.4,0c0,1.7,0,3.3,0,4.9c-3,0-5.9,0-8.9,0c0-2.3,0-4.6,0-6.9c3,0,5.9,0,8.9,0c0,0.3,0,0.6,0,1c0.1,0,0.1,0,0.2,0
						                c0.4,0,0.8,0,1.2,0c1,0,1.6,0.6,1.6,1.6c0,0.9,0,1.8,0,2.7c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.4,0.5,0.4c0.2,0,0.5-0.2,0.5-0.4
						                c0,0,0-0.1,0-0.1c0-2.1,0-4.1,0-6.2c0,0,0-0.1,0-0.2c-0.2,0-0.4,0-0.6,0c-0.8-0.1-1.3-0.7-1.3-1.5c0-0.7,0-1.3,0-2
						                c0-0.3,0.2-0.5,0.5-0.5c0.2,0,0.3,0,0.5,0c0-0.4,0-0.8,0-1.2c0-0.1,0-0.2,0-0.3c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
						                c0,0.5,0,0.9,0,1.4c0,0,0,0.1,0,0.1c0.3,0,0.6,0,1,0c0-0.1,0-0.2,0-0.3c0-0.4,0-0.8,0-1.1c0-0.3,0.2-0.5,0.5-0.5
						                c0.3,0,0.5,0.2,0.5,0.5c0,0.4,0,0.9,0,1.3c0,0.1,0,0.1,0,0.2c0.4,0,0.8-0.1,1,0.4C858.1,314.3,858.1,315.1,858.1,315.8z
						                 M846.9,322.1c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.4-0.4,0.7-0.5,1.1c-0.1,0.3,0,0.5,0.2,0.7c0.2,0.1,0.5,0,0.7-0.2
						                c0.3-0.7,0.7-1.3,1-2c0.2-0.3-0.1-0.7-0.4-0.7c-0.3,0-0.7,0-1,0c0,0-0.1,0-0.2,0c0-0.1,0.1-0.1,0.1-0.2
						                c0.2-0.4,0.4-0.7,0.5-1.1c0.1-0.3,0-0.5-0.2-0.7c-0.2-0.1-0.5,0-0.6,0.2c-0.3,0.7-0.7,1.3-1,2c-0.2,0.3,0.1,0.7,0.4,0.7
						                c0.1,0,0.2,0,0.2,0C846.3,322.1,846.6,322.1,846.9,322.1z"></path>
					                <path className="st28" d="M841.7,328c-0.3-0.1-0.4-0.4-0.4-0.7c0-0.1,0-0.2,0-0.3c0-0.6,0.4-1,1-1c0,0,0,0,0.1,0c2.9,0,5.8,0,8.7,0
						                c0.4,0,0.8,0.1,1,0.5c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.4,0.7C848.4,328,845.1,328,841.7,328z"></path>
					                <path className="st28" d="M851.2,317.1c-3,0-5.9,0-8.9,0c0-0.1,0-0.1,0-0.2c0-1.4,0-2.8,0-4.2c0-0.6,0.3-1.1,0.8-1.4
						                c0.2-0.1,0.5-0.2,0.7-0.2c1.9,0,3.9,0,5.8,0c0.8,0,1.5,0.6,1.5,1.5C851.2,314.1,851.2,315.6,851.2,317.1z M846.8,313.2
						                c-0.8,0-1.6,0-2.4,0c-0.4,0-0.6,0.2-0.6,0.6c0,0.6,0,1.2,0,1.8c0,0.4,0.2,0.6,0.6,0.6c1.6,0,3.2,0,4.8,0c0.4,0,0.6-0.2,0.6-0.6
						                c0-0.6,0-1.2,0-1.8c0-0.4-0.2-0.6-0.6-0.6C848.4,313.2,847.6,313.2,846.8,313.2z"></path>
				                </g>
			                </g>
		                </g>
	                </g>
                </g>
                </svg>
              </TabPane>
              <TabPane tabId="2">
                  <Table>
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Absolute Value</th>
                        <th>Normalized Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.state.data && this.state.data.length > 0 && this.state.data.map((e, i) => (
                          <tr key={i}>
                            <td>{heatmapPlaces[e.circle_name]}</td>
                            <td>{e.conteggio}</td>
                            <td>{e.norm_val}</td>
                          </tr>
                      ))}
                    </tbody>
                  </Table>
              </TabPane>
            </TabContent>
          </CardBody>          
        </Card>
      </Col>
    )
  }

}

export default Heatmap;
