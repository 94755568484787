import React, { Component } from 'react';
import { Col, Card, Button, CardBody } from 'reactstrap';
import { Chart } from "react-google-charts";
import GraphService from '../services/GraphService';
import Loader from 'react-loader';
import moment from 'moment';

const getChartType = (type, filters) => {
  if(type === 'ColumnChart') {
    if(filters && filters.filterType && filters.filterType === 1 && filters.current && filters.current.initial && filters.current.end) {
      let sDate = moment(filters.current.initial);
      let eDate = moment(filters.current.end);
      if(eDate.diff(sDate, 'days') > 15)
        return 'AreaChart';
    }
  }
  return type;
}

class GraphComponent extends Component {

  constructor(props) {
    super(props);
    this.GraphService = new GraphService();
    this.state = { data: [], expanded: false, loaded: true, intervalId: null };
    this.expandChart = this.expandChart.bind(this);
    this.loadData = this.loadData.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    this.timer = this.timer.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  loadData() {
    
    this.setState({ loaded: false });
    let toSend = this.prepareData();
    this.GraphService.fetchData(toSend).then((res) => {
      if(!res.data || res.data.length === 0) {
        this.setState({ data: [], loaded: true });
      } else {
        toSend.chartType = getChartType(this.props.data.type, this.props.filters);
        this.setState({ data: this.GraphService.convertGraphData(res.data, toSend), loaded: true });
      }
      if(this.props.filters && this.props.filters.filterType === 0 && !this.state.intervalId) {
        var intervalId = setInterval(this.timer, 60 * 1000);
        this.setState({ intervalId: intervalId });
      }  
      if(this.props.filters && this.props.filters.filterType !== 0) {
        clearInterval(this.state.intervalId);
        this.setState({ intervalId: null });
      }
    })
    .catch((err) => {
      this.setState({ data: [], loaded: true });
    });
  }
  
  componentWillUnmount() {
   // use intervalId from the state to clear the interval
     clearInterval(this.state.intervalId);
  }
  
  timer() {
    let toSend = this.prepareData();
    this.GraphService.fetchData(toSend).then((res) => {
      if(!res.data || res.data.length === 0) {
        this.setState({ data: [], loaded: true });
      } else {
        toSend.chartType = getChartType(this.props.data.type, this.props.filters);
        this.setState({ data: this.GraphService.convertGraphData(res.data, toSend), loaded: true });
      }
    })
    .catch((err) => {
      this.setState({ data: [], loaded: true });
    });
  }
  
  componentDidUpdate(prevProps) {
    for(let k in this.props) {
      if(prevProps[k] !== this.props[k]) {
        this.loadData();
        this.setState({ expandChart: false });
      }
    }
  }
  
  prepareData() {
    let data = this.props.filters; //{ startDate: "2019-01-10", endDate: "2019-01-17" };
    let toSend = Object.assign({}, this.props.data.fetchPrototype);
    for(let k in toSend.filters) {
      if(data.hasOwnProperty(toSend.filters[k].filterName)) {
        if(toSend.filters[k].filterName === "shop") {
          toSend.filters[k].value = data[toSend.filters[k].filterName].value;
        } else {
          let obj = Object.assign({}, data[toSend.filters[k].filterName]);
          obj.initial = moment(obj.initial).isValid() ? moment(obj.initial).format('YYYY-MM-DD') : obj.initial;
          obj.end = moment(obj.end).isValid() ? moment(obj.end).format('YYYY-MM-DD') : obj.end;
          toSend.filters[k].value = obj;
        }
      }
    }
    toSend.chartType = this.props.data.type;
    return toSend;
  }
  
  downloadExcel() {
    this.GraphService.exportExcel(this.prepareData()).then((res) => {
      window.open("/storage/" + res.data.filename, "_blank");
    });
  }
  
  
  expandChart() {
    this.setState({ expandChart: !this.state.expandChart });
  }

  render() {
    return (
      <Col md={this.state.expandChart ? 12:6}>
        <Card className="mb-3">
          <Loader loaded={this.state.loaded} />
          { this.state.data && this.state.data.length > 0 && <CardBody>
            <Button className="btn-filters btn-charts pointer" color="info" onClick={this.downloadExcel}><i className="fa fa-download"></i></Button>
            <Button className="btn-filters btn-charts2 pointer" color="info" onClick={this.expandChart}><i className="fa fa-expand"></i></Button>
            <Chart
              height={this.props.data.height}
              chartType={ getChartType(this.props.data.type, this.props.filters) }
              loader={<div>Loading Chart</div>}
              data={ this.state.data }
              options={{
                title: this.props.data.title ,
                isStacked : this.props.data.isStacked ? true : false,
                titleTextStyle: {
                    textAlign: 'center',
                    color: '#000000',    // any HTML string color ('red', '#cc00cc')
                    fontName: 'Lato', // i.e. 'Times New Roman'
                    fontSize: 18, // 12, 18 whatever you want (don't specify px)
                    bold: true,    // true or false
                    italic: false   // true of false
                },
                chartArea: { width: '80%', bottom: 120, top: 50 },
                colors: ['#dc3912', '#3366cc', '#3A3335', '#FDF0D5', '#C6D8D3', '#4B3B47', '#E0D8DE', '#FFC759', '#607196' ],
                is3D: true,
                hAxis: {
                  titlePosition: 'none',
                  format: this.props.data.xAxisFormat ? this.props.data.xAxisFormat : 'd/M/yy',
                  slantedText: this.props.data.xAxisSlanted ? true : false, 
                  slantedTextAngle:this.props.data.xAxisSlanted ? 45 : 0,
                  maxTextLines: 2,
                },
                vAxis: {
                  titlePosition: 'none',
                  minValue: 0,
                  format: this.props.data.vAxisFormat ? this.props.data.vAxisFormat : 'decimal'
                },
                legend: this.props.data.legend ? this.props.data.legend: "none",
              }}
            />
          </CardBody>}
          { (!this.state.data || this.state.data.length === 0) && <CardBody>
          <h4>No data available with this settings.</h4>
          </CardBody>}
          
        </Card>
      </Col>
    )
  }

}

export default GraphComponent;
